import React, { FC, useContext, useEffect, useRef, useState } from "react";

import cn from "classnames";

import styles from "./Layout.module.scss";

import { observer } from "mobx-react-lite";
import Head from "next/head";
import { useRouter } from "next/router";
import { BreadCrumb } from "primereact/breadcrumb";
import { MenuItem } from "primereact/menuitem";
import { Messages } from "primereact/messages";

import { useDataContext } from "../../../data/DataContext";
import { UseAdBlockContext } from "../../../hooks/useAdBlock";
import { Locale, useI18nLocaleFormat } from "../../../hooks/useI18nLocaleFormat";
import APPLICATION_ROUTES, { PUBLICATIONS_WITH_ID_ROUTE, SCHEDULE_WITH_ID_ROUTE } from "../../../utils/routes";
import ToastHandler from "../Notifications/ToastHandler";
import Footer from "./Footer/Footer";
import TopBar from "./TopBar/TopBar";


interface LayoutProps {
    title?: string;
    metaTitle?: string;
    lightMenu?: boolean;
    breadcrumbsModel?: MenuItem[];
    size?: "large" | "medium";
}

const Layout: FC<LayoutProps> = (props) => {
    // PROPS
    const { title, metaTitle, lightMenu, breadcrumbsModel, size, children } = props;

    // DEPS
    const { themeBrightness } = useDataContext().stores.appStateStore.appState;
    const { localeAs } = useI18nLocaleFormat();
    const router = useRouter();
    const { isUseAdBlock, hide } = useContext(UseAdBlockContext);
    const adBlockMessageRef = useRef();

    // STATE
    const [showFooter, setShowFooter] = useState<boolean>(true);

    // DATA
    const home = { icon: "pi pi-home", url: APPLICATION_ROUTES.main.getHomeLink(router.locale as Locale) };

    useEffect(() => {
        setShowFooter(router.route !== SCHEDULE_WITH_ID_ROUTE && router.route !== PUBLICATIONS_WITH_ID_ROUTE);
    }, [router.route]);

    useEffect(() => {
        if (isUseAdBlock && adBlockMessageRef?.current && !lightMenu) {
            // @ts-ignore
            adBlockMessageRef.current.show({
                severity: "error",
                detail: localeAs("useAdBlock.message"),
                sticky: true,
            });
        }
        // eslint-disable-next-line
    }, [isUseAdBlock]);

    return (
        <>
            <Head>
                <title>{metaTitle ?? title ?? ""}</title>
            </Head>
            <div className={styles.layout}>
                <TopBar lightMenu={lightMenu} />
                {!lightMenu && <ToastHandler />}
                <div className={styles.layout__container}>
                    <Messages ref={adBlockMessageRef} onRemove={hide} />
                    <div
                        className={cn(styles.layout__main, {
                            [styles.layout__main_large]: size === "large",
                            [styles.layout__main_medium]: !size || size === "medium",
                        })}
                    >
                        {!lightMenu && (
                            <div
                                className={cn(styles.layout__main__header, {
                                    [styles.layout__main__header__breadcrumps_plr0]: themeBrightness === "light",
                                })}
                            >
                                {breadcrumbsModel && (
                                    <BreadCrumb
                                        model={breadcrumbsModel}
                                        home={home}
                                        className={styles.layout__main__header__breadcrumps}
                                    />
                                )}
                            </div>
                        )}
                        <div className={styles.layout__main__content}>{children}</div>
                    </div>
                </div>

                {showFooter && <Footer />}
            </div>
        </>
    );
};

export default observer(Layout);
