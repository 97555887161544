import { UserInfoEntity, UserInfoRoleName, UserInfoRoleValue } from "./auth.models";

export const authActions = {
    hasAnyCommitteeRoleInAnyEvent: (userInfo?: UserInfoEntity): boolean => {
        if (!userInfo?.roles) {
            return false;
        }

        for (const roleName in userInfo.roles) {
            if (roleName.startsWith("committee:")) {
                const roleValue = userInfo.roles[roleName] as UserInfoRoleValue;

                if (roleValue?.event?.length > 0) {
                    return true;
                }
            }
        }

        return false;
    },
    isLocalRoleForEvent(userInfo: UserInfoEntity, roleName: UserInfoRoleName, eventId: number) {
        if (!userInfo?.roles) {
            return false;
        }

        const roleValue = userInfo.roles[roleName] as UserInfoRoleValue;
        return roleValue?.event?.some((id) => id === eventId);
    },
};
