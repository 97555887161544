import React, { useEffect, useState } from "react";

import { DATA_KEY } from "../data/const";
import { useLocalStorageState } from "./useLocalStorageState";

const ADS_URL = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
const ADBLOCKER_INFO_HIDE = `${DATA_KEY}.adBlock_hide`;

interface UseAdBlockLSData {
    isPanelHidden: boolean;
    isUseAdBlock?: boolean;
}

const useAdBlockInitData: UseAdBlockLSData = {
    isPanelHidden: false,
};

export interface UseAdBlockData {
    isUseAdBlock: boolean;
    hide: () => void;
}

export const useAdBlock = (): UseAdBlockData => {
    const [data, setData] = useLocalStorageState<UseAdBlockLSData>(ADBLOCKER_INFO_HIDE, useAdBlockInitData);
    const [isUseAdBlock, setIsUseAdBlock] = useState<boolean>();

    const checkAdsBlocked = () => {
        const xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function () {
            if (xhr.readyState == XMLHttpRequest.DONE) {
                setIsUseAdBlock(xhr.status === 0 || xhr.responseURL !== ADS_URL);
            }
        };
        xhr.open("HEAD", ADS_URL, true);
        xhr.send(null);
    };

    useEffect(() => {
        if (!data?.isPanelHidden && isUseAdBlock === undefined) {
            checkAdsBlocked();
        } else {
            setIsUseAdBlock(false);
        }
        // eslint-disable-next-line
    }, [data]);

    const hide = () => {
        setData({ isPanelHidden: true, isUseAdBlock });
        setIsUseAdBlock(false);
    };

    return { isUseAdBlock, hide };
};

export const UseAdBlockContext = React.createContext<UseAdBlockData>(undefined);
