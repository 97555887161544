import React, { FC } from "react";

import cn from "classnames";

import styles from "./Layout.module.scss";

interface SpinnerProps {
    text?: string;
}

const Spinner: FC<SpinnerProps> = ({ text }) => (
    <div className={cn("p-d-flex p-flex-column p-ai-center", styles.layout__global_spinner_container)}>
        <div className={styles.lds_ring}>
            <div />
            <div />
            <div />
            <div />
        </div>
        {text && <div className="p-mt-4">{text}</div>}
    </div>
);

export default Spinner;
