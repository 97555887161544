import React from "react";

import cn from "classnames";

import styles from "./TopBar.module.scss";

import Tippy from "@tippyjs/react";
import Link from "next/link";
import { useRouter } from "next/router";

import { Locale, useI18nLocaleFormat } from "../../../../hooks/useI18nLocaleFormat";
import APPLICATION_ROUTES from "../../../../utils/routes";
import { LocaleMessageAs } from "../../LocaleMessage/LocaleMessageAs";


const LanguageBar = ({ disableTips }) => {
    const router = useRouter();
    const { localeAs } = useI18nLocaleFormat();

    const locale = router.locale ?? "ru";

    const renderLInk = (locale: Locale) => (
        <Link href={APPLICATION_ROUTES.main.createRoute(router.asPath)} locale={locale} scroll={false} passHref>
            <button className={cn("p-link", styles.topBar__button, styles.topBar__displayButton)}>
                <Tippy
                    content={localeAs(`general.locale.select.beauty.${locale.toUpperCase()}` as any)}
                    disabled={disableTips}
                >
                    <div>
                        <LocaleMessageAs id={`general.locale.select.${locale.toUpperCase()}` as any} />
                    </div>
                </Tippy>
            </button>
        </Link>
    );

    return <li>{locale === "ru" ? renderLInk("en") : renderLInk("ru")}</li>;
};

export default LanguageBar;
