import { ChartDataset, ChartOptions } from "chart.js";
import moment from "moment";

import { ChartData } from "../../components/common/Components/Analytics/AnalyticChart";
import { LocaleMessagesType } from "../../hooks/useI18nLocaleFormat";
import { average, groupBy, numberWithSpaces } from "../../utils/common";
import { DATA_KEY } from "../const";
import { ProposalAnalyticsModelLocalizedIds } from "./mdm.consts";
import {
    EventAnalyticsAggregate,
    EventAnalyticsModel,
    MdmDiscussionHistModel,
    MdmTalkHistModel,
    MdmTalkRatingModel,
    MdmTalksRatingAggregate,
    MdmTalkWatchSpreadModel,
    ProposalAnalyticsModel,
    TagAnalyticsAggregate,
    TalkAnalyticsAggregate,
} from "./models/analytics.models";

const getHistDurationInHoursAndMinutes = (
    duration: number | null | undefined,
    localeAs: (id: LocaleMessagesType) => string,
): string => {
    if (duration === undefined || duration === null) {
        return "-";
    }

    if (duration < 60) {
        return `${duration} ${localeAs("common.time.min.short")}`;
    }

    const hours = parseInt((duration / 60).toString(), 10);
    return `${hours} ${localeAs("common.time.hour.short")} ${duration - hours * 60} ${localeAs(
        "common.time.min.short",
    )}`;
};

const getRatingLocalizedId = (formRating: number): string =>
    formRating < 0 || formRating > 7 ? undefined : `domain.analytics.talkRatings.form_text.${formRating}`;

const getLastUpdatesInHoursAndMinutes = (lastDate: Date, localeAs: (id: LocaleMessagesType) => string): string => {
    if (!lastDate) {
        return "-";
    }

    const dt = moment(lastDate);
    const now = moment().utc();

    const diffHours = now.diff(dt, "hours");

    if (diffHours > 0) {
        return `${diffHours} ${localeAs("common.time.hour.short")}`;
    }

    const diffMinutes = now.diff(dt, "minutes");

    return `${diffMinutes} ${localeAs("common.time.min.short")}`;
};

const barRatingOptions = {
    scales: {
        y: {
            ticks: {
                beginAtZero: true,
                callback: (label, _, __) => {
                    // when the floored value is the same as the value we have a whole number
                    if (Math.floor(label) === label) {
                        return label;
                    }
                },
            },
        },
    },
};

const baseLineChartOptions = (yAxesDataMin, yAxesDataMax, yAxesEffector?: number): ChartOptions => ({
    responsive: true,
    scales: {
        y: {
            min:
                yAxesDataMin - (yAxesEffector ? yAxesEffector / 2 : 5) < 0
                    ? 0
                    : yAxesDataMin - (yAxesEffector ? yAxesEffector / 2 : 5),
            max: yAxesDataMax + 2,
            ticks: {
                callback: (label: any, _, __) => {
                    // when the floored value is the same as the value we have a whole number
                    if (Math.floor(label) === label) {
                        return label;
                    }
                },
            },
        },
    },
    elements: {
        line: {
            borderWidth: 4,
        },
    },
});

const baseLineChartDataset = {
    fill: false,
    backgroundColor: "rgba(45, 182, 245, 0.24)",
    tension: 0.4,
    borderColor: "#FF045F",
};

const effectorLineCharDataset = {
    fill: true,
    backgroundColor: "rgba(57, 200, 106, 0.24)",
    borderColor: "#39C86A",
};

interface MdmTalkRatingMixinModel extends MdmTalkRatingModel {
    ignore?: boolean;
}

const addAllRatingSpread = (talkRatingsRaw, formVersion, localeAs): MdmTalkRatingMixinModel[] => {
    return [
        ...(formVersion === 5 ? [0, 1, 2, 3, 4, 5] : formVersion === 7 ? [0, 1, 2, 3, 4, 5, 6, 7] : []).map((r) => ({
            form_rating: r,
            ignore: true,
        })),
        ...talkRatingsRaw,
    ]
        .sort((a, b) => a.form_rating - b.form_rating)
        .map((r) =>
            r.form_text_localized
                ? r
                : {
                      ...r,
                      form_text_localized:
                          r.form_text_localized ?? localeAs(getRatingLocalizedId(r.form_rating) as any),
                  },
        );
};

export const calcAverage = (talkRatingsRaw, formVersion) => {
    const data = talkRatingsRaw.filter((r) => r.form_rating > 0);
    if (formVersion === 7) {
        const to5Data = data.map((r) =>
            r.form_rating === 7
                ? 5
                : r.form_rating === 6
                ? 4.33
                : r.form_rating === 5
                ? 3.67
                : r.form_rating === 4
                ? 3
                : r.form_rating === 3
                ? 2.33
                : r.form_rating === 2
                ? 1.66
                : r.form_rating === 1
                ? 1
                : 1,
        );
        return average(to5Data);
    } else {
        return average(data.map((r) => r.form_rating));
    }
};

export const mdmActions = {
    SAVED_PROPOSAL_STAT_DATA_KEY: (proposalId: number) => `${DATA_KEY}.proposal_${proposalId}_stat`,
    SAVED_EVENT_STAT_DATA_KEY: (eventId: number) => `${DATA_KEY}.event_${eventId}_stat`,
    getAvgRatingString: (videoAvgRating?: number, videoAvgRatingVersion?: number) => {
        if (!videoAvgRating) {
            return "-";
        }
        return `${videoAvgRating?.toString()}/${videoAvgRatingVersion?.toString()}`;
    },
    getHistDurationInHoursAndMinutes: (
        duration: number | null | undefined,
        localeAs: (id: LocaleMessagesType) => string,
    ): string => getHistDurationInHoursAndMinutes(duration, localeAs),
    getSumDurationInMinutesTo100000: (duration: number | null): string | undefined => {
        if (!duration) {
            return undefined;
        }

        if (duration < 1000) {
            return duration.toString();
        }

        let rounded;

        if (duration < 100000) {
            rounded = Math.round(((duration + Number.EPSILON) / 1000) * 10) / 10;
            return `${rounded}`;
        }

        rounded = parseInt((duration / 1000).toString(), 10);
        return `${rounded}`;
    },
    getSumDurationInMinutesTo1000: (duration?: number | null): string => {
        if (!duration) {
            return "-";
        }

        if (duration < 9999 + 1) {
            return numberWithSpaces(duration);
        }

        const rounded = parseInt((duration / 1000).toString(), 10);
        return `${rounded}K`;
    },
    getSum: (a?: number | null, b?: number | null) => {
        if (!a && !b) {
            return undefined;
        }

        return (a ?? 0) + (b ?? 0);
    },
    getTalkWatchSpreadCharData: (
        data: MdmTalkWatchSpreadModel[],
        localeAs: (id: LocaleMessagesType) => string,
        yAxesEffector?: number,
    ): ChartData => {
        if (!data || data?.length === 0) {
            return undefined;
        }
        const yAxesData = data.map((d) => d.watchers_count + (yAxesEffector ?? 0));
        // const yAxesDataMin = Math.min(...[ ...yAxesData, yAxesEffector ?? 0 ]);
        const yAxesDataMax = Math.max(...[...yAxesData, yAxesEffector ?? 0]) * 1.5;

        const labels = data.map((d) => `${d.watching_deep} ${localeAs("common.time.min.short")}`);
        const datasets: ChartDataset[] = [
            {
                label: localeAs("domain.analytics.talkWatchSpread.chartTitle"),
                data: yAxesData,
                fill: true,
                backgroundColor: "#42A5F5",
            },
        ];

        return {
            labels,
            datasets,
            options: baseLineChartOptions(0, yAxesDataMax, yAxesEffector),
        };
    },
    getTalkRatingsAggregate: (
        rawData: MdmTalkRatingModel[],
        localeAs: (id: LocaleMessagesType) => string,
    ): MdmTalksRatingAggregate[] => {
        if (!rawData || rawData?.length === 0) {
            return undefined;
        }

        const filteredData = rawData
            .filter((d) => d?.form_version !== null && d?.form_rating !== null)
            .sort((a, b) => a.form_rating - b.form_rating)
            .map((r) => ({
                ...r,
                form_text_localized: localeAs(getRatingLocalizedId(r.form_rating) as any),
            }));

        const spreadByVersion = groupBy<number, MdmTalkRatingModel>(filteredData, (i) => i.form_version);

        const result: MdmTalksRatingAggregate[] = [];

        spreadByVersion.forEach((talkRatingsRaw, formVersion) => {
            const talkRatingsWithMixin = addAllRatingSpread(talkRatingsRaw, formVersion, localeAs);

            const spreadByRatingValue =
                formVersion === 7
                    ? groupBy<string, MdmTalkRatingMixinModel>(
                          talkRatingsWithMixin.filter((r) => r?.form_text_localized),
                          (item) => item.form_text_localized,
                      )
                    : groupBy<string, MdmTalkRatingMixinModel>(talkRatingsWithMixin, (item) =>
                          item.form_rating.toString(),
                      );

            const avgRaw = calcAverage(talkRatingsRaw, formVersion);

            const count = talkRatingsRaw.length;
            const avg = Math.round((avgRaw + Number.EPSILON) * 10) / 10;

            const labels = Array.from(spreadByRatingValue.keys()).map((r) => r);
            const spreadValuesCount = Array.from(spreadByRatingValue.values())
                // filter ignore values
                .map((s) => s.filter((r) => !r.ignore).length);

            if (labels.length === 0) {
                return;
            }

            const datasets: ChartDataset[] = [
                {
                    label: localeAs("domain.analytics.talkRatings.chartTitle"),
                    data: spreadValuesCount,
                    fill: true,
                    backgroundColor: [
                        "rgba(255, 99, 132)",
                        "rgba(255, 159, 64)",
                        "rgba(255, 205, 86)",
                        "rgba(75, 192, 192)",
                        "rgba(54, 162, 235)",
                        "rgba(153, 102, 255)",
                        "rgba(201, 203, 207)",
                        "rgb(81,235,54)",
                    ],
                },
            ];

            result.push({
                chartData: {
                    labels,
                    datasets,
                    options: barRatingOptions,
                },
                formVersion,
                talkRatings: talkRatingsRaw,
                avg,
                count,
            });
        });

        return result;
    },
    getDiscussionHistogramChartData: (
        data: MdmDiscussionHistModel[],
        localeAs: (id: LocaleMessagesType) => string,
        yAxesEffector?: number,
    ): ChartData => {
        if (!data || data?.length === 0) {
            return undefined;
        }
        const raw = data.filter((d) => d.count > 1);
        const yAxesData = raw.map((d) => d.count + yAxesEffector ?? 0);
        const yAxesDataMin = Math.min(...[...yAxesData, yAxesEffector ?? 0]);
        const yAxesDataMax = Math.max(...[...yAxesData, yAxesEffector ?? 0]);

        const labels = raw.map((d) => getHistDurationInHoursAndMinutes(d.offset_minutes, localeAs));

        let datasets: ChartDataset[] = yAxesEffector
            ? [
                  {
                      label: localeAs("domain.analytics.discussionHist.offline"),
                      data: [yAxesEffector, ...data.map((_) => yAxesEffector), yAxesEffector],
                      ...effectorLineCharDataset,
                  },
              ]
            : [];

        datasets = [
            ...datasets,
            {
                label: localeAs("domain.analytics.discussionHist.sum"),
                data: [yAxesEffector ?? 0, ...yAxesData, yAxesEffector ?? 0],
                ...baseLineChartDataset,
                fill: "-1",
            },
        ];

        return {
            labels: ["", ...labels, ""],
            datasets,
            options: baseLineChartOptions(yAxesDataMin, yAxesDataMax, yAxesEffector),
        };
    },
    getTalkRecordHistogramChartData: (
        raw: MdmTalkHistModel[],
        localeAs: (id: LocaleMessagesType) => string,
        talkDuration?: number,
    ): ChartData => {
        if (!raw || raw?.length === 0) {
            return undefined;
        }
        const data = raw.filter((d) =>
            talkDuration ? d.player_offset_min > 0 && d.player_offset_min <= talkDuration : d.player_offset_min > 0,
        );
        const yAxesData = data.map((d) => d.unique_watchers);
        const yAxesDataMin = Math.min(...yAxesData);
        const yAxesDataMax = Math.max(...yAxesData);

        const labels = data.map((d) => getHistDurationInHoursAndMinutes(d.player_offset_min, localeAs));
        const datasets: ChartDataset[] = [
            {
                label: localeAs("domain.analytics.talkWatchSpread.chartTitle"),
                data: [0, ...yAxesData, 0],
                ...baseLineChartDataset,
            },
        ];

        return {
            labels: ["", ...labels, ""],
            datasets,
            options: baseLineChartOptions(yAxesDataMin, yAxesDataMax),
        };
    },
    getTalkLiveHistogramChartData: (
        data: MdmTalkHistModel[],
        localeAs: (id: LocaleMessagesType) => string,
        yAxesEffector?: number,
        dataLocaleId?: string,
    ): ChartData => {
        if (!data || data?.length === 0) {
            return undefined;
        }

        const yAxesData = data.map((d) => d.unique_watchers + yAxesEffector ?? 0);
        const yAxesDataMin = Math.min(...[...yAxesData, yAxesEffector ?? 0]);
        const yAxesDataMax = Math.max(...[...yAxesData, yAxesEffector ?? 0]);

        const labels = data.map((d) => getHistDurationInHoursAndMinutes(d.player_offset_min, localeAs));

        let datasets: ChartDataset[] = yAxesEffector
            ? [
                  {
                      label: localeAs("domain.analytics.talkLiveHist.offline"),
                      data: [yAxesEffector, ...data.map((_) => yAxesEffector), yAxesEffector],
                      ...effectorLineCharDataset,
                  },
              ]
            : [];

        datasets = [
            ...datasets,
            {
                label: localeAs((dataLocaleId as any) ?? "domain.analytics.talkWatchSpread.chartTitle"),
                data: [yAxesEffector ?? 0, ...yAxesData, yAxesEffector ?? 0],
                ...baseLineChartDataset,
                fill: "-1",
            },
        ];

        return {
            labels: ["", ...labels, ""],
            datasets,
            options: baseLineChartOptions(yAxesDataMin, yAxesDataMax, yAxesEffector),
        };
    },
    isMdmDataEmpty: (data?: ProposalAnalyticsModel | null) => {
        if (!data) {
            return true;
        }
        if (
            !data?.talkStatistics?.all_discussion_unique_users &&
            !data?.talkStatistics?.all_unique_users &&
            data.talkRatings?.length === 0
        ) {
            return true;
        }
    },
    getLastUpdatesInHoursAndMinutesText(dateExecuted: Date, localeAs: (id: LocaleMessagesType) => string) {
        if (!dateExecuted) {
            return undefined;
        }
        const text = localeAs(ProposalAnalyticsModelLocalizedIds.talkStatistics.date_executed as any);
        return text.replace("N", getLastUpdatesInHoursAndMinutes(dateExecuted, localeAs));
    },
    getEventDataView(data?: EventAnalyticsModel): EventAnalyticsAggregate | null {
        if (!data) {
            return null;
        }

        let talksAnalyticsAggregates: TalkAnalyticsAggregate[];
        let tagsAnalyticsAggregates: TagAnalyticsAggregate[];

        if (data.talksStatistics?.length === 0) {
            talksAnalyticsAggregates = [];
        } else {
            talksAnalyticsAggregates = data.talksStatistics
                .filter((s) => s.talk_id)
                .map((s) => {
                    const aggregate = data.activities.find((a) => a?.activity?.id === s.talk_id);
                    return {
                        talkId: s.talk_id,
                        talksStatistic: s,
                        activity: aggregate?.activity,
                        participations: aggregate?.participations,
                        proposal: aggregate?.proposal,
                    } as TalkAnalyticsAggregate;
                });
        }

        if (data.tagStatistics?.length === 0) {
            tagsAnalyticsAggregates = [];
        } else {
            tagsAnalyticsAggregates = data.tagStatistics.map((t) => {
                const talksStatistics =
                    !talksAnalyticsAggregates || !t.talks_ids
                        ? []
                        : talksAnalyticsAggregates.filter((talk) =>
                              t.talks_ids?.filter((id) => id).includes(talk.talkId),
                          );

                return {
                    ...t,
                    talksStatistics,
                } as TagAnalyticsAggregate;
            });
        }

        return {
            talksAnalyticsAggregates,
            tagsAnalyticsAggregates,
        };
    },
};
