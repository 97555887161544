import React, { FC, useEffect, useState } from "react";

import { observer } from "mobx-react-lite";
import { NextComponentType, NextPageContext } from "next";
import { Router } from "next/router";

import { saveAnalytic } from "../../../data/analytics/analytics.actions";
import { AnalyticsActions } from "../../../data/analytics/analytics.actions.consts";
import { publicEndpointLogin } from "../../../data/auth/auth.api.service";
import { DataContext } from "../../../data/DataContext";
import { DataContextData } from "../../../data/initializeAll";
import { useAdBlock, UseAdBlockContext } from "../../../hooks/useAdBlock";
import { useIsBrowser } from "../../../hooks/useIsBrowser";
import { Error403Main } from "../../errors/Error403Main/Error403Main";
import Layout from "../Layout/Layout";
import Spinner from "../Layout/Spinner";

export interface ApplicationProps {
    Component: NextComponentType<NextPageContext, any>;
    pageProps: any;
    router: Router;
    contextData: DataContextData;
}

const Application: FC<ApplicationProps> = (props) => {
    const { Component, pageProps, router, contextData } = props;

    const { authService, srmService, analyticServiceAsync } = contextData.services;
    const { isLogged, userId } = contextData.stores.authStore;
    const browser = useIsBrowser();
    const [globalSpinnerShow, setGlobalSpinnerShow] = useState<boolean>(true);
    const [isGlobalDeny, setIsGlobalDeny] = useState<boolean>();
    const useAdBlockData = useAdBlock();

    const turnOffGlobalSpinnerShow = () => setTimeout(() => setGlobalSpinnerShow(false), 500);

    useEffect(() => {
        if (!browser) {
            return;
        }

        if (isLogged) {
            authService.zeroOutCurrentVerificationAttemptsCount();
            srmService
                .getUserInfo()
                .then(() => setIsGlobalDeny(false))
                .catch(() => setIsGlobalDeny(true))
                .finally(turnOffGlobalSpinnerShow);
        } else {
            authService
                .refreshAuthData()
                .then((result) => {
                    if (!result) {
                        window.location.replace(publicEndpointLogin(window.location.href));
                    }
                })
                .finally(turnOffGlobalSpinnerShow);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLogged, browser]);

    useEffect(() => {
        userId && saveAnalytic(analyticServiceAsync, AnalyticsActions.ENTER_TO_PAGE, userId.toString());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.asPath, userId]);

    return (
        <DataContext.Provider value={contextData}>
            <UseAdBlockContext.Provider value={useAdBlockData}>
                {globalSpinnerShow && (
                    <Layout lightMenu>
                        <Spinner />
                    </Layout>
                )}
                {!globalSpinnerShow && isGlobalDeny === false && <Component {...pageProps} />}
                {!globalSpinnerShow && isGlobalDeny && (
                    <Layout lightMenu>
                        <Error403Main />
                    </Layout>
                )}
            </UseAdBlockContext.Provider>
        </DataContext.Provider>
    );
};

// noinspection JSUnusedGlobalSymbols
export default observer(Application);
