import React, { useEffect, useRef } from "react";

import { observer } from "mobx-react-lite";
import { Toast } from "primereact/toast";

import { useDataContext } from "../../../data/DataContext";


const LIFE_TIME = 10000;

const ToastHandler = () => {
    const toast = useRef<Toast>();

    const { notificationsStore } = useDataContext().stores;

    const showSuccess = (summary: string, detail?: string) => {
        toast.current?.show({ severity: "success", summary, detail, life: LIFE_TIME / 2 });
    };

    const showInfo = (summary: string, detail?: string) => {
        toast.current?.show({ severity: "info", summary, detail, life: LIFE_TIME / 2 });
    };

    const showWarn = (summary: string, detail?: string) => {
        toast.current?.show({ severity: "warn", summary, detail, life: LIFE_TIME });
    };

    const showError = (summary: string, detail?: string) => {
        toast.current?.show({ severity: "error", summary, detail, life: LIFE_TIME });
    };

    useEffect(() => {
        const note = notificationsStore.getLastElement;
        if (note !== null && !note.isShowed) {
            notificationsStore.markAsShowed(note.id);
            switch (note.importance) {
                case "ERROR":
                    showError(note.title, note.message);
                    return;
                case "WARNING":
                    showWarn(note.title, note.message);
                    return;
                case "INFO":
                    showInfo(note.title, note.message);
                    return;
                default:
                    showSuccess(note.title, note.message);
            }
        }
    }, [notificationsStore, notificationsStore.unreadNotifications.length]);

    return (
        // @ts-ignore
        <Toast ref={toast} position="bottom-right" />
    );
};

export default observer(ToastHandler);
