import { toJS } from "mobx";

import { deepEqual } from "../../../utils/common";
import { EntityNumber, LocalizeString } from "../../common/common.models";
import { participationActions } from "./participation/participation.actions";
import { BiographyEntity } from "./participation/participation.models";
import { NameColorEntity, ScheduleVersionEntity } from "./schedule/schedule.models";
import { ContentStatus, ObjectStatus } from "./squidex.models";

export const commonActions = {
    localizeStringActions: {
        isSomeNull: (l?: LocalizeString) => (l !== undefined ? !l.en || !l.ru : false),
    },
    contentActions: {
        isPublished: (c?: ContentStatus) => (c !== undefined ? c === "published" : false),
        isEdited: (c?: ContentStatus) => (c !== undefined ? c === "edited" : false),
        allIsExactlyPublished: (cs: ContentStatus[]) => cs.every((c) => c === "published"),
        isDraft: (c?: ContentStatus) => (c !== undefined ? c === "draft" : false),
        oneIsDraft: (cs: ContentStatus[]) => cs.some((c) => c === "draft"),
        isArchive: (c?: ContentStatus) => (c !== undefined ? c === "archived" : false),
        oneIsArchive: (cs: ContentStatus[]) => cs.some((c) => c === "archived"),
    },
    entityActions: {
        isActive: (c?: ObjectStatus) => (c !== undefined ? c === "active" : false),
        sortById: (a: EntityNumber, b: EntityNumber) => a.id - b.id,
    },
    imageActions: {
        getScaledImageUrl: (image?: string, width?: number, height?: number, mode?: string, cache?: number) => {
            if (!image) {
                return undefined;
            }

            const url = new URL(image);
            if (width) {
                url.searchParams.append("width", width.toString());
            }
            if (height) {
                url.searchParams.append("height", height.toString());
            }
            if (mode) {
                url.searchParams.append("mode", mode);
            }
            url.searchParams.append("cache", cache?.toString() ?? "3600");

            return url.toString();
        },
    },
    twitterActions: {
        makeTwitterLink: (biography?: BiographyEntity) => {
            if (!biography) {
                return undefined;
            }
            const twitter = participationActions.extractTwitterData(biography);

            if (!twitter) {
                return undefined;
            }
            return `https://twitter.com/${twitter}`;
        },
        getFieldValueWithoutSymbols: (v: string, symbols: string | RegExp) => v?.replaceAll(symbols, ""),
    },
    scheduleActions: {
        comparatorByDate: (a: ScheduleVersionEntity, b: ScheduleVersionEntity) =>
            b?.modifiedAt && a?.modifiedAt
                ? b?.modifiedAt > a?.modifiedAt
                    ? 1
                    : -1
                : b?.createdAt > a?.createdAt
                ? 1
                : -1,
        compareNameColorEntity: (a: NameColorEntity[], b: NameColorEntity[]) => {
            if (a.length !== b.length) {
                return true;
            }

            let result = false;

            a.forEach((s, i) => {
                if (!deepEqual(toJS(s), toJS(b[i]))) {
                    result = true;
                }
            });

            return result;
        },
        getEditNameColorEntities: (a: NameColorEntity[], b: NameColorEntity[]) => {
            const result = [];
            a.forEach((s, i) => {
                if (b[i] !== undefined) {
                    if (s.id !== b[i].id || s.name !== b[i].name || s.color !== b[i].color) {
                        result.push(b[i]);
                    }
                }
            });
            return result;
        },
    },
};
