import { error } from "next/dist/build/output/log";

import { AuthStore } from "../auth/auth.store";
import { coreApiAction } from "./core.actions";
import { CoreApiService } from "./core.api.service";
import { CoreStore } from "./core.store";
import { CoreApiEventEntity } from "./models/events.models";

const CORE_EVENTS_UPDATE_PERIOD = 5;

export class CoreService {
    private readonly coreApiService: CoreApiService;

    constructor(private coreStore: CoreStore, private authStore: AuthStore) {
        // for local development
        this.coreApiService = new CoreApiService(false, authStore);
    }

    loadCommitteeEvent(eventId: number): Promise<void> {
        const eventFromList = this.coreStore.events?.find((e) => e.id === eventId);
        if (eventFromList) {
            this.coreStore.setCurrentEvent(eventFromList);
            return;
        }

        return this.coreApiService
            .getCommitteeEvent(eventId)
            .then((event) => {
                if (event) {
                    this.coreStore.setCurrentEvent(event);
                    return;
                }
                throw new Error("Event not loaded");
            })
            .catch((e) => {
                this.coreStore.setCurrentEvent(null);
                error(e);
                throw e;
            });
    }

    loadCommitteeEvents(forceReload: boolean = false): Promise<CoreApiEventEntity[] | null> {
        const diff = this.coreStore.updatedAgeMin;
        if (forceReload || diff === null || diff >= CORE_EVENTS_UPDATE_PERIOD) {
            return this.coreApiService
                .getCommitteeEvents()
                .then((response) => {
                    if (response) {
                        const events = coreApiAction
                            .sortPeriodsListByDate(response)
                            .filter(coreApiAction.filterOutPackEvents)
                            .map(coreApiAction.transformEvent);

                        this.coreStore.setEventsAndSeasons(events);

                        return events;
                    }
                    return null;
                })
                .catch((e) => {
                    this.coreStore.setEventsAndSeasons(null);
                    throw e;
                });
        }
        return Promise.resolve(this.coreStore.events);
    }

    getEvents(): Promise<CoreApiEventEntity[]> {
        return this.coreApiService.getEvents();
    }

    loadCommitteeEventsAndSetCurrent(forceReload: boolean = false) {
        return this.loadCommitteeEvents(forceReload).then((events) => {
            if (events?.length > 0) {
                // find last event with season
                const event = this.coreStore.findCurrentEvent;
                this.coreStore.setCurrentEvent(event);
                return events;
            }
        });
    }

    archiveCommitteeEvent(eventId: number) {
        return this.coreApiService
            .archiveCommitteeEvent(eventId)
            .then((event) => {
                this.coreStore.setCurrentEvent(coreApiAction.transformEvent(event));
                return event;
            })
            .then((event) => {
                this.coreStore.updateEvent(event);
            });
    }
}
