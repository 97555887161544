import { SpeakerAddContentBlockMode } from "../../../../components/pages/ProposalHome/TabSpeakers/SpeakerAddContentBlock";
import { LocaleMessagesType } from "../../../../hooks/useI18nLocaleFormat";
import { EntityTypeValue, PossibleValueForSelect } from "../../../common/common.models";
import { ActivityScope } from "../activity/activity.models";
import { CommitteeParticipationInviteEntity, CommitteeParticipationRole } from "../committee/committee.models";
import { ParticipationInviteEntity, ParticipationRole, Titulus } from "./participation.models";

export const ParticipationEntityLocalizedIds = {
    format: "domain.proposal.participation.format",
};

export const ParticipationNewDefaultValue = (
    mode: SpeakerAddContentBlockMode,
    scope: ActivityScope,
): ParticipationInviteEntity | CommitteeParticipationInviteEntity => ({
    format: mode === "activity" ? "ONLINE_REMOTE" : undefined,
    role: mode === "activity" ? "EXPERT" : mode === "team" ? "MEMBER" : "EXPERT",
    invitee: {
        email: "",
        firstName: "",
    },
    scope: scope === "PARTNER" ? "PARTNER" : "REGULAR",
});

export const ParticipationEntityFormatValues = (scope: ActivityScope): EntityTypeValue<string>[] => {
    const isPartnerScope = scope === "PARTNER";
    const isMeetupScope = scope === "MEETUP";

    if (isMeetupScope) {
        return [
            {
                key: "ONLINE_REMOTE",
                displayId: "domain.proposal.participation.format.ONLINE_REMOTE.meetup",
            },
            {
                key: "ONLINE_STUDIO",
                displayId: "domain.proposal.participation.format.ONLINE_STUDIO.meetup",
            },
            {
                key: "UNKNOWN",
                displayId: "domain.proposal.participation.format.UNKNOWN",
                disabled: true,
            },
        ];
    }

    if (isPartnerScope) {
        return [
            // {
            //     key: "OFFLINE",
            //     displayId: "domain.proposal.participation.format.OFFLINE"
            // },
            {
                key: "ONLINE_REMOTE",
                displayId: "domain.proposal.participation.format.ONLINE_REMOTE.partner",
            },
            {
                key: "ONLINE_STUDIO",
                displayId: "domain.proposal.participation.format.ONLINE_STUDIO.partner",
            },
            {
                key: "UNKNOWN",
                displayId: "domain.proposal.participation.format.UNKNOWN",
            },
        ];
    }

    return [
        {
            key: "ONLINE_REMOTE",
            displayId: "domain.proposal.participation.format.ONLINE_REMOTE",
        },
        {
            key: "ONLINE_STUDIO",
            displayId: "domain.proposal.participation.format.ONLINE_STUDIO",
        },
        {
            key: "OFFLINE",
            displayId: "domain.proposal.participation.format.OFFLINE",
        },
        {
            key: "UNKNOWN",
            displayId: "domain.proposal.participation.format.UNKNOWN",
        },
    ];
};

export const ParticipationEntityRoleValues = (declination: "1d" | "2d" = "1d"): EntityTypeValue<string>[] => [
    {
        key: "SPEAKER",
        displayId: `domain.proposal.participation.role.${declination}.SPEAKER`,
    },
    {
        key: "EXPERT",
        displayId: `domain.proposal.participation.role.${declination}.EXPERT`,
    },
    {
        key: "HOST",
        displayId: `domain.proposal.participation.role.${declination}.HOST`,
    },
    {
        key: "PARTNER",
        displayId: `domain.proposal.participation.role.${declination}.PARTNER`,
    },
    {
        key: "MEMBER",
        displayId: `domain.proposal.participation.role.${declination}.MEMBER`,
    },
    {
        key: "EDITOR",
        displayId: `domain.proposal.participation.role.${declination}.EDITOR`,
    },
    {
        key: "COORDINATOR",
        displayId: `domain.proposal.participation.role.${declination}.COORDINATOR`,
    },
    {
        key: "MODERATOR",
        displayId: `domain.proposal.participation.role.${declination}.MODERATOR`,
    },
];

export const TitulusPossibleValuesForSelect = (): PossibleValueForSelect[] =>
    TitulusPossibleValues.map((name) => ({
        name,
        code: name === "-" ? undefined : name,
    }));

export const TitulusPossibleValues: Titulus[] = [
    "Java Champion",
    "Microsoft Most Valuable Professional",
    "Google Developer Expert",
    "MVP Reconnect",
    "-",
];

export const RolesPossibleCodes: ParticipationRole[] = ["SPEAKER", "EXPERT", "HOST"];
export const CommitteeRolesPossibleCodes: CommitteeParticipationRole[] = ["MEMBER", "COORDINATOR", "MODERATOR"]; // "EDITOR" ];

export const RolesPossibleValuesForSelect = (
    localeAs: (id: LocaleMessagesType) => string,
    mode: SpeakerAddContentBlockMode,
): PossibleValueForSelect[] =>
    (mode === "activity" ? RolesPossibleCodes : CommitteeRolesPossibleCodes).map((code) => ({
        name: localeAs(ParticipationEntityRoleValues().find((v) => v.key === code).displayId as any),
        code,
    }));

export const BiographyLocalizedIds = {
    content: {
        data: {
            firstName: "page.proposal.content.tabs.speakers.infoBlock.firstName",
            lastName: "page.proposal.content.tabs.speakers.infoBlock.lastName",
            company: "page.proposal.content.tabs.speakers.infoBlock.company",
            description: "page.proposal.content.tabs.speakers.infoBlock.description",
            titulus: "page.proposal.content.tabs.speakers.infoBlock.titulus",
            twitter: "page.proposal.content.tabs.speakers.infoBlock.twitter",
            position: "page.proposal.content.tabs.speakers.infoBlock.position",
            email: "page.proposal.content.tabs.speakers.infoBlock.email",
            phone: "page.proposal.content.tabs.speakers.infoBlock.phone",
            role: "page.proposal.content.tabs.speakers.infoBlock.role",
            isPartner: "page.proposal.content.tabs.speakers.infoBlock.isPartner",
            isMeetup: "page.proposal.content.tabs.speakers.infoBlock.isMeetup",
        },
    },
};

export const ParticipationLocalizedIds = {
    biography: BiographyLocalizedIds,
    consent: {
        publicationConsent: "page.proposal.content.tabs.speakers.infoBlock.publicationConsent",
    },
};
