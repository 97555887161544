import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { info } from "next/dist/build/output/log";

import { debugMode, isLocalEnvironment } from "../../environment";
import { AccessTokenValue } from "../data/auth/access-token";
import { AuthStore } from "../data/auth/auth.store";
import { error4xxInterceptor, HttpCodes, oK200Interceptor } from "./http-codes";
import { parseJwt } from "./jwt-parse";

const jwtAuthMiddleware = async (req) => {
    // check for jwt auth header
    if (!req.headers.authorization || req.headers.authorization.indexOf("Bearer ") === -1) {
        throw { status: HttpCodes.UNAUTHORIZED, message: "Missing Authorization Header" };
    }

    // verify auth credentials
    const tokenRaw = req.headers.authorization.split(" ")[1];
    const token = parseJwt<AccessTokenValue>(tokenRaw);
    if (!token) {
        throw { status: HttpCodes.UNAUTHORIZED, message: "Invalid Authentication Credentials" };
    }

    // attach user to request object
    req.token = token;
    req.tokenRaw = tokenRaw;
};

const errorHandler = (err, res) => {
    if (typeof err === "string") {
        // custom application error
        return res.status(HttpCodes.BAD_REQUEST).json({ message: err });
    }

    if (err.status) {
        // status code set in error object
        return res.status(err.status).json({ message: err.message });
    }

    // default to 500 server error
    return res.status(HttpCodes.SERVER_ERROR).json({ message: err.message });
};

export const apiHandler = (handler) => async (req, res) => {
    const method = req.method.toLowerCase();

    // check handler supports HTTP method
    if (!handler[method]) {
        return res.status(HttpCodes.NOT_ALLOWED).end(`Method ${req.method} Not Allowed`);
    }

    try {
        // global middleware
        await jwtAuthMiddleware(req);

        // route handler
        return await handler[method](req, res);
    } catch (err) {
        // global error handler
        return errorHandler(err, res);
    }
};

export const initAxios = (baseURL: string, authStore?: AuthStore, accessTokenRaw?: string): AxiosInstance => {
    const token =
        accessTokenRaw !== undefined
            ? accessTokenRaw
            : isLocalEnvironment && authStore?.accessTokenRaw
            ? authStore?.accessTokenRaw
            : undefined;

    let settings: AxiosRequestConfig = {
        baseURL,
    };

    if (token) {
        settings = {
            ...settings,
            headers: { authorization: `Bearer ${token}` },
        };
    }

    const api = axios.create(settings);
    if (debugMode) {
        api.interceptors.request.use((request) => {
            info("Starting Request", JSON.stringify(request, null, 2));
            return request;
        });
    }
    api.interceptors.response.use(oK200Interceptor, error4xxInterceptor);

    return api;
};
