import React, { useRef } from "react";

import cn from "classnames";

import styles from "./TopBar.module.scss";

import { observer } from "mobx-react-lite";
import { useRouter } from "next/router";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { MenuItem } from "primereact/menuitem";

import { getLkAdminLink, getLkCompaniesAdminLink, getLkTicketsLink } from "../../../../../environment";
import { useDataContext } from "../../../../data/DataContext";
import { Locale, useI18nLocaleFormat } from "../../../../hooks/useI18nLocaleFormat";
import { useIsBrowser } from "../../../../hooks/useIsBrowser";
import APPLICATION_ROUTES, { getLocaleUrl } from "../../../../utils/routes";


const MenuBar = () => {
    // DEPS
    const isBrowser = useIsBrowser();
    const { localeAs } = useI18nLocaleFormat();
    const router = useRouter();

    const { authStore } = useDataContext().stores;

    const { isGlobalAdmin, isCompaniesAdmin, isSrmUser, userInfoLoaded, thereAreAnyRoleInAnyEvent } = authStore;

    const { themeBrightness } = useDataContext().stores.appStateStore.appState;

    // DATA
    const menuRef = useRef(null);
    const locale = router.locale as Locale;

    const menuBarItemsFn = (): MenuItem[] => {
        const defaultMenu: MenuItem[] = [
            {
                label: localeAs("general.home"),
                url: APPLICATION_ROUTES.main.getHomeLink(locale),
                className: styles.topBar__left_menu__home_link,
            },
        ];

        if (!userInfoLoaded) {
            return defaultMenu;
        }

        if (userInfoLoaded) {
            defaultMenu.push({
                label: localeAs("main.topbar.menu.tickets"),
                url: getLkTicketsLink(),
            });
        }

        const proposalsMenu = {
            label: localeAs("main.topbar.menu.proposals"),
            url: getLocaleUrl(APPLICATION_ROUTES.proposals.route, locale),
            className:
                router.asPath.startsWith(APPLICATION_ROUTES.proposals.route) ||
                (!thereAreAnyRoleInAnyEvent && router.asPath === APPLICATION_ROUTES.main.route)
                    ? styles.topBar__left_menu__item_active
                    : undefined,
        };

        const companiesMenu = {
            label: localeAs("main.topbar.menu.companies"),
            url: getLkCompaniesAdminLink(),
        };

        const adminMenu = {
            label: localeAs("main.topbar.menu.admin"),
            url: getLkAdminLink(),
        };

        const eventsMenu = {
            label: localeAs("page.events.menu-item.title"),
            url: getLocaleUrl(APPLICATION_ROUTES.events.route, locale),
            className:
                router.asPath.startsWith(APPLICATION_ROUTES.events.route) ||
                (thereAreAnyRoleInAnyEvent && router.asPath === APPLICATION_ROUTES.main.route)
                    ? styles.topBar__left_menu__item_active
                    : undefined,
        };

        let result = [...defaultMenu];

        if (isSrmUser) {
            result = [...result, proposalsMenu];
        }

        if (isCompaniesAdmin) {
            result = [...result, companiesMenu];
        }

        if (isGlobalAdmin) {
            result = [...result, adminMenu];
        }

        if (thereAreAnyRoleInAnyEvent) {
            result = [...result, eventsMenu];
        }

        return result;
    };

    const menuBarItems = menuBarItemsFn();

    // RENDERS
    const renderItems = () =>
        menuBarItems.map((item) => {
            if (!item || !item?.label) {
                return null;
            }
            return (
                <div
                    key={`menu_${item.label}`}
                    className={cn(styles.topBar__left_menu__item, { [item.className]: item.className !== undefined })}
                >
                    <a href={item.url}>
                        <Button label={item.label} className="p-button-text" />
                    </a>
                </div>
            );
        });

    const renderLogoAndBurger = () => (
        <div className={styles.topBar__logo}>
            {menuBarItems.length > 1 && <Menu model={menuBarItems} popup ref={menuRef} />}
            <a href={APPLICATION_ROUTES.main.getHomeLink(locale)}>
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img
                    src={themeBrightness === "light" ? `/img/jugru_group.svg` : `/img/jugru_group-white.svg`}
                    alt="logo"
                />
            </a>
            {menuBarItems.length > 1 && (
                <button
                    className={cn("p-link", styles.topBar__button, styles.topBar__logo__home_button, {
                        [styles.topBar__logo__home_button_full]: isGlobalAdmin || isCompaniesAdmin,
                        [styles.topBar__logo__home_button_small]: !(isGlobalAdmin || isCompaniesAdmin),
                    })}
                    onClick={(event) => menuRef.current.toggle(event)}
                >
                    <i className="pi pi-bars" />
                </button>
            )}
        </div>
    );

    if (!isBrowser) {
        return null;
    }

    return (
        <>
            {renderLogoAndBurger()}
            <div
                className={cn(styles.topBar__left_menu, {
                    [styles.topBar__left_menu_full]: isGlobalAdmin || isCompaniesAdmin,
                    [styles.topBar__left_menu_small]: !(isGlobalAdmin || isCompaniesAdmin),
                })}
            >
                {menuBarItems.length > 1 && renderItems()}
            </div>
        </>
    );
};

export default observer(MenuBar);
