import moment from "moment";

import { groupBy, groupBySingleWithExtractor } from "../../utils/common";
import { countHoursBetweenDates } from "../../utils/time";
import { CoreApiEventEntity, EventPeriod, EventSeasonEntity } from "./models/events.models";

const getSeasonTypeNumber = (season: EventSeasonEntity) => {
    switch (season.type) {
        case "spring":
            return 0;
        case "summer":
            return 1;
        case "autumn":
            return 2;
        case "winter":
            return 3;
        default:
            return 4;
    }
};

export const coreApiAction = {
    sortPeriodsListByDate: (
        events: {
            period?: EventPeriod;
        }[],
        order: "asc" | "desc" = "desc",
        sortField: "startsAt" | "endsAt" = "startsAt",
    ): any[] =>
        events.sort((a, b) => {
            if (!b?.period || !a?.period) {
                return -1;
            }
            if (b.period[sortField] > a.period[sortField]) {
                return order === "desc" ? 1 : -1;
            } else {
                return order === "desc" ? -1 : 1;
            }
        }),
    sortSeasonByYearVersion: (a?: EventSeasonEntity, b?: EventSeasonEntity) => {
        if (!b) {
            return -1;
        }
        if (!a) {
            return 1;
        }
        if (a.year && b.year) {
            if (a.year > b.year) {
                return -1;
            }
            if (a.year < b.year) {
                return 1;
            }
            if (a.year === b.year) {
                if (getSeasonTypeNumber(a) > getSeasonTypeNumber(b)) {
                    return -1;
                } else {
                    return 1;
                }
            }
        }
        return a.id - b.id;
    },
    filterOutPackEvents: (event: CoreApiEventEntity) =>
        event.jira?.project !== "PACK" && !event.name.ru.includes("Full Pass"),

    comparatorEventByDate: (a?: CoreApiEventEntity, b?: CoreApiEventEntity) => {
        if (!a?.id && !b?.id) {
            return -1;
        }
        if (!a?.id) {
            return 1;
        }
        if (!b?.id) {
            return -1;
        }
        if (b?.period?.startsAt === a?.period?.startsAt) {
            return b.id - a.id;
        }
        if (b?.period?.startsAt > a?.period?.startsAt) {
            return 1;
        } else {
            return -1;
        }
    },

    getSeasonPeriod: (
        seasonId,
        eventsList: CoreApiEventEntity[],
    ): { period: EventPeriod; needToArchive: boolean } | undefined => {
        const seasonMap = coreApiAction.getSeasonsEventsMap(eventsList);
        if (seasonMap?.has(seasonId)) {
            const events = seasonMap.get(seasonId);
            const max = coreApiAction.sortPeriodsListByDate(events, "desc", "endsAt")[0];
            const min = coreApiAction.sortPeriodsListByDate(events, "asc")[0];
            const needToArchive = events.some((e) => e.isFinished && e.status !== "archived");

            if (min && max) {
                return {
                    period: {
                        startsAt: min.period?.startsAt,
                        endsAt: max.period?.endsAt,
                    },
                    needToArchive,
                };
            }
        }
        return undefined;
    },

    getSeasonsEventsMap: (events) => groupBy(events, (event: CoreApiEventEntity) => event.season?.id),

    findCurrentSeasons(getSeasonsArray: EventSeasonEntity[]) {
        const seasons = getSeasonsArray.filter((s) => s);

        let result = seasons.find((s) => moment().isBetween(moment(s.period?.startsAt), moment(s.period?.endsAt)));

        // if correct seasons don't found
        if (!result) {
            result = seasons[0];

            if (result) {
                let diff: number = countHoursBetweenDates(new Date(result.period?.startsAt), new Date());

                if (diff < 0) {
                    return result;
                }

                seasons.forEach((s) => {
                    const newDiff = countHoursBetweenDates(new Date(s?.period?.startsAt), new Date());

                    if (newDiff > 0 && newDiff < diff) {
                        diff = newDiff;
                        result = s;
                    }
                });
            }
        }

        return result;
    },

    isFinished(event: CoreApiEventEntity) {
        if (!event?.period?.endsAt) {
            return false;
        }
        return moment(event.period.endsAt).add(1, "day").isBefore(moment());
    },

    transformEvent(event: CoreApiEventEntity): CoreApiEventEntity {
        return {
            ...event,
            isFinished: coreApiAction.isFinished(event),
        };
    },

    calcSeasons(events: CoreApiEventEntity[]) {
        if (!events) {
            return null;
        }
        const seasons = groupBySingleWithExtractor<number, CoreApiEventEntity, EventSeasonEntity>(
            events,
            (item) => item.season?.id,
            (item) => item.season,
        );
        seasons.forEach((v, l, map) => {
            if (!v) {
                return;
            }
            const { period, needToArchive } = coreApiAction.getSeasonPeriod(v.id, events);
            map.set(v.id, { ...v, period, needToArchive });
        });
        return seasons;
    },
};
