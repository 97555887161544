import {
    COMMON_FILTER_SCOPE_KEY_FILTER,
    COMMON_FILTER_TEXT_KEY_FILTER,
    COMMON_FILTER_TYPE_KEY_FILTER,
} from "../../../../components/common/Components/Filter/filters.const";
import { FilterPanelData } from "../../../../components/common/Components/Filter/useFilterData";
import {
    ACTIVITIES_FILTER_PROPOSAL_RESERVED_KEY_FILTER,
    filterActivitiesByText,
} from "../../../../components/pages/events/activities/activities.utils";
import { ActivitiesControllerMode } from "../../../../components/pages/events/activities/ActivitiesController";
import {
    EVENT_REPORT_FILTER_FORMAT_KEY,
    EVENT_REPORT_FILTER_FORMAT_KEY_TYPE,
} from "../../../../components/pages/events/report/eventReport.utils";
import { LocaleMessagesType } from "../../../../hooks/useI18nLocaleFormat";
import { groupBy, removeUndefined } from "../../../../utils/common";
import { AuthStore } from "../../../auth/auth.store";
import { LocalizeStringArrayDefault } from "../../../common/common.const";
import { KeyValueCount, LocalizeString, LocalizeStringArray } from "../../../common/common.models";
import { TalkAnalyticsAggregate } from "../../../mdm/models/analytics.models";
import SrmService from "../../srm.service";
import { commonActions } from "../common.actions";
import { proposalActions } from "../proposal/proposal.actions";
import { ProposalFacet } from "../proposal/proposal.models";
import { ScheduleDayEntity, ScheduleSlotEntity, ScheduleTrackEntity } from "../schedule/schedule.models";
import { AssetEntity } from "../squidex.models";
import {
    ActivityEntityScopeValuePrefix,
    ActivityEntityTypeValuePrefix,
    ActivityEntityTypeValueValues,
} from "./activity.consts";
import {
    ActivityContentData,
    ActivityEntity,
    ActivityEntityView,
    ActivityProposalParticipationsAggregate,
    ActivityProposalParticipationsAggregateView,
} from "./activity.models";

export const activityActions = {
    isSpeakerRoomIdNeed(activityContentData?: ActivityContentData): boolean {
        if (!activityContentData) {
            return false;
        }
        const data = activityContentData?.internalSlots?.iv;

        if (data?.broadcast === "standard" || !data?.broadcast) {
            return true;
        }

        // noinspection RedundantIfStatementJS
        if (data?.broadcast === "disabled" && (data?.discussion === "disabled" || data?.discussion === "external")) {
            return false;
        }

        return true;
    },
    filterActivitiesByTextFn(a: ActivityProposalParticipationsAggregate, t: string): boolean {
        return (
            a.activity?.content?.data?.title?.ru?.toLowerCase().includes(t) ||
            a.activity?.content?.data?.title?.en?.toLowerCase().includes(t) ||
            a.participations?.some(
                (p) =>
                    p.biography?.content?.data?.firstName?.ru?.toLowerCase().includes(t) ||
                    p.biography?.content?.data?.firstName?.en?.toLowerCase().includes(t),
            ) ||
            a.participations?.some(
                (p) =>
                    p.biography?.content?.data?.lastName?.ru?.toLowerCase().includes(t) ||
                    p.biography?.content?.data?.lastName?.en?.toLowerCase().includes(t),
            )
        );
    },
    filterActivitiesForPublications(
        activities: ActivityProposalParticipationsAggregate[] | null | undefined,
        filtersData: FilterPanelData,
    ): ActivityProposalParticipationsAggregate[] | null | undefined {
        if (!activities) {
            return activities;
        }
        let filteredActivities = activities;
        const filterByTypes = filtersData.filters.find(COMMON_FILTER_TYPE_KEY_FILTER)?.data;
        if (filterByTypes?.length > 0) {
            filteredActivities = filteredActivities.filter((a) => filterByTypes.includes(a.activity?.typeValue));
        }

        const filterByScopes = filtersData.filters.find(COMMON_FILTER_SCOPE_KEY_FILTER)?.data;
        if (filterByScopes?.length > 0) {
            filteredActivities = filteredActivities.filter((a) => filterByScopes.includes(a.activity?.scope));
        }

        const filterByReservedStage = filtersData.filters.find(ACTIVITIES_FILTER_PROPOSAL_RESERVED_KEY_FILTER)?.data;
        if (filterByReservedStage) {
            filteredActivities = filteredActivities.filter((a) => a.proposal?.workflow?.status === "reserved");
        }

        const filterByText = filtersData.filters.find(COMMON_FILTER_TEXT_KEY_FILTER)?.data;

        return filterActivitiesByText(filteredActivities, filterByText);
    },
    getActivitiesFormatValuesDistinctWithCount: (
        activitiesForCount: TalkAnalyticsAggregate[],
        localeAs,
    ): KeyValueCount<string>[] => {
        const keys: EVENT_REPORT_FILTER_FORMAT_KEY_TYPE[] = ["offline", "online"];
        const groupingForCount = groupBy(activitiesForCount, (a) =>
            a?.talksStatistic?.offline_unique_users ? "offline" : "online",
        );

        return keys.map((key) => ({
            label: localeAs(`${EVENT_REPORT_FILTER_FORMAT_KEY}.${key}` as any),
            key,
            count: groupingForCount.get(key as "offline" | "online")?.length ?? 0,
        }));
    },
    getActivitiesTypeValuesDistinctWithCount: (
        activities: ActivityEntity[],
        activitiesForCount: ActivityEntity[],
        localeAs,
    ): KeyValueCount<string>[] => {
        const groupingForTypes = groupBy(activities, (a) => a.typeValue);
        const groupingForCount = groupBy(activitiesForCount, (a) => a.typeValue);

        return Array.from(groupingForTypes).map(([key, _]) => ({
            label: localeAs(`${ActivityEntityTypeValuePrefix}.${key}` as LocaleMessagesType),
            key,
            count: groupingForCount.get(key)?.length ?? 0,
        }));
    },
    getActivitiesScopesDistinctWithCount: (
        activities: ActivityEntity[],
        activitiesForCount: ActivityEntity[],
        localeAs,
    ): KeyValueCount<string>[] => {
        const groupingForTypes = groupBy(activities, (a) => a.scope);
        const groupingForCount = groupBy(activitiesForCount, (a) => a.scope);

        return Array.from(groupingForTypes).map(([key, _]) => ({
            label: localeAs(`${ActivityEntityScopeValuePrefix}.${key}` as LocaleMessagesType),
            key,
            count: groupingForCount.get(key)?.length ?? 0,
        }));
    },
    aggregateTransform: (
        mode: ActivitiesControllerMode,
        activities?: ActivityProposalParticipationsAggregate[] | null | undefined,
        authStore?: AuthStore,
    ): ActivityProposalParticipationsAggregateView[] | null | undefined => {
        if (activities === null) {
            return null;
        }
        if (activities === undefined) {
            return undefined;
        }
        const result: ActivityProposalParticipationsAggregateView[] = [];

        const activityTransform = (activity: ActivityEntity): ActivityEntityView => {
            const ogImages = activityActions.extractOGImagesUrls(activity, true);
            return {
                ...activity,
                ogImages,
                ogPresent: ogImages?.ru?.length > 0 && ogImages?.en?.length > 0,
            };
        };

        activities.forEach((agg) => {
            const participationsContentStatuses = agg?.participations?.map((p) => p.biography?.content?.status);
            const participationsImportantNotePresent =
                !commonActions.contentActions.allIsExactlyPublished(participationsContentStatuses);
            const activity = activityTransform(agg.activity);
            const activityImportantTooltips = activityActions.getActivityImportantTooltips(
                activity,
                participationsImportantNotePresent,
                agg.proposal,
                authStore,
            );

            const data: ActivityProposalParticipationsAggregateView[] = agg.participations?.map(
                (participation, index) => ({
                    proposal: agg.proposal,
                    activity,
                    activityImportantTooltips,
                    participationsImportantNotePresent,
                    participation,
                    index,
                }),
            );

            result.push(...data);
        });

        return mode === "activities"
            ? result
            : mode === "publications"
            ? result.sort(activityActions.sortActivitiesAggregatesByStatus)
            : result.sort(activityActions.sortActivitiesAggregatesByStatus);
    },
    getTypeLocalizeId: (activity?: ActivityEntity): string | undefined =>
        activity?.typeValue === undefined
            ? undefined
            : ActivityEntityTypeValueValues().find((f) => f.key === activity.typeValue)?.displayId,
    extractOGImagesUrls: (activity?: ActivityEntity, deleteDefaultOg: boolean = false): LocalizeStringArray => {
        if (!activity) {
            return LocalizeStringArrayDefault;
        }

        const ogIds = activity?.content?.data?.OGImage;

        if (!ogIds) {
            return LocalizeStringArrayDefault;
        }

        const assets = activity?.content?.assets;

        if (deleteDefaultOg && ogIds.ru?.length > 0 && ogIds.en?.length > 0) {
            if (ogIds.ru[0] === ogIds.en[0]) {
                return {
                    ru: (assets?.filter((a) => ogIds.ru?.includes(a.id)) ?? []).map((a) => a.links?.content),
                    en: [],
                };
            }
        }

        return {
            ru: (assets?.filter((a) => ogIds.ru?.includes(a.id)) ?? []).map((a) => a.links?.content),
            en: (assets?.filter((a) => ogIds.en?.includes(a.id)) ?? []).map((a) => a.links?.content),
        };
    },
    extractPresentationFiles: (activity?: ActivityEntity): AssetEntity[] => {
        const ids = activity?.content?.data?.presentation?.iv?.files;

        if (!ids) {
            return undefined;
        }

        if (ids.length === 0) {
            return [];
        }

        return activity?.content?.assets
            ?.filter((a) => ids.includes(a.id))
            .sort((a, b) => (new Date(a.created) > new Date(b.created) ? -1 : 1));
    },
    getActivityImportantTooltips: (
        activity: ActivityEntityView,
        participationsImportantNotePresent: boolean,
        proposal: ProposalFacet,
        authStore?: AuthStore,
    ): string[] => {
        const status = activity?.content?.status;
        if (!status) {
            return [];
        }
        const ogPresent = activity?.ogPresent;
        const presentationFilesExist = activityActions.extractPresentationFiles(activity)?.length > 0;
        const edited = commonActions.contentActions.isEdited(status);
        const draft = commonActions.contentActions.isDraft(status);
        const productionStage = proposalActions.isProposalProductionStage(proposal);

        const tooltips: string[] = [];

        tooltips.push(!productionStage ? "page.publications.table.information.stage.important" : undefined);

        tooltips.push(
            edited
                ? "domain.contentStatus.edited.tooltip"
                : draft
                ? "domain.contentStatus.activity.draft.tooltip"
                : undefined,
        );

        tooltips.push(
            participationsImportantNotePresent
                ? "page.publications.table.information.participant.important"
                : undefined,
        );

        if (authStore.isEditor(activity?.eventId) || authStore.isGlobalAdmin) {
            if (!ogPresent) {
                tooltips.push("page.publications.table.OpenGraph.tooltip.off");
            }
        }

        if (authStore.isCoordinator(activity?.eventId) || authStore.isGlobalAdmin) {
            if (!presentationFilesExist) {
                tooltips.push("page.publications.table.information.presentation.important");
            }
        }

        return tooltips.filter((t) => t);
    },
    sortActivitiesAggregatesByProposal: (
        a: ActivityProposalParticipationsAggregate,
        b: ActivityProposalParticipationsAggregate,
    ) => {
        return b.proposal?.id - a.proposal.id;
    },
    sortActivitiesAggregatesByStatus: (
        a: ActivityProposalParticipationsAggregateView,
        b: ActivityProposalParticipationsAggregateView,
    ) => {
        if (a.activityImportantTooltips.length > b.activityImportantTooltips.length) {
            return -1;
        }

        return 1;
    },
    sortActivitiesBySlotPresent: (
        a: ActivityProposalParticipationsAggregate,
        b: ActivityProposalParticipationsAggregate,
        currentSlots: { slot: ScheduleSlotEntity; day: ScheduleDayEntity; track: ScheduleTrackEntity }[],
    ) => {
        const isPresentSlotsA = currentSlots.some((s) => s.slot?.activityId === a.activity?.id);
        const isPresentSlotsB = currentSlots.some((s) => s.slot?.activityId === b.activity?.id);

        if (isPresentSlotsB && !isPresentSlotsA) {
            return 1;
        } else {
            return -1;
        }
    },
    isPartnerScope: (activity?: ActivityEntity): boolean | undefined =>
        activity?.scope === undefined ? undefined : activity.scope === "PARTNER",
    isMeetupScope: (activity?: ActivityEntity): boolean | undefined =>
        activity?.scope === undefined ? undefined : activity.scope === "MEETUP",
    isRegularScope: (activity?: ActivityEntity): boolean | undefined =>
        activity?.scope === undefined ? undefined : activity.scope === "REGULAR",
    editAction: {
        saveStringAction: (id: number, data: string, fieldName: string, srmService: SrmService) =>
            srmService.saveActivityData(id, { [fieldName]: data }),
        saveMultipleStringAction: (id: number, data: { data: string; fieldName: string }[], srmService: SrmService) => {
            const sendData = {};
            data.forEach((d) => {
                sendData[d.fieldName] = d.data;
            });
            return srmService.saveActivityData(id, sendData);
        },
        saveLocalizeStringAction: (id: number, data: LocalizeString, fieldName: string, srmService: SrmService) =>
            srmService.saveActivityData(id, { [fieldName]: data }),
        saveLocalizeStringContentDataAction: (
            id: number,
            data: LocalizeString,
            fieldName: string,
            srmService: SrmService,
        ) =>
            srmService.saveActivityData(
                id,
                {
                    content: {
                        data: {
                            [fieldName]: data,
                        },
                    },
                },
                true,
            ),
        saveStringContentDataAction: (id: number, data: string, fieldName: string, srmService: SrmService) =>
            srmService.saveActivityData(
                id,
                {
                    content: {
                        data: {
                            [fieldName]: data,
                        },
                    },
                },
                true,
            ),
        removeContentDataNotEditableFields: (data: ActivityContentData): object =>
            removeUndefined({
                ...data,
                id: undefined,
                OGImage: undefined,
                committeeComment: undefined,
                eventId: undefined,
                eventProject: undefined,
                eventVersion: undefined,
                fullDescription: undefined,
                links: undefined,
                marketingComment: undefined,
                options: undefined,
                presentation: undefined,
                shortDescription: undefined,
                title: undefined,
            }),
    },
};
