// noinspection DuplicatedCode
import { AxiosInstance, AxiosRequestHeaders, AxiosResponse } from "axios";
import { reaction, when } from "mobx";

import { getBaseUrl } from "../../../environment";
import { initAxios } from "../../utils/api-helpers";
import { UserInfoApiResponse, UserInfoEntity } from "../auth/auth.models";
import { AuthStore } from "../auth/auth.store";
import { committeeApiPath } from "../core-api/core.api.routes";
import {
    ActivitiesApiResponse,
    ActivityApiResponse,
    ActivityEntity,
    ActivityProposalAggregate,
    ActivityProposalAggregateApiResponse,
    ActivityProposalParticipationsAggregate,
    ActivityProposalParticipationsAggregateApiResponse,
} from "./models/activity/activity.models";
import {
    CommitteeApiResponse,
    CommitteeEntity,
    CommitteeParticipationApiResponse,
    CommitteeParticipationEntity,
    CommitteeParticipationInviteEntity,
    CommunicationInviteType,
} from "./models/committee/committee.models";
import { EquipmentApiResponse, EquipmentEntity } from "./models/equipment.models";
import { ExchangeCopyModel } from "./models/exchange.models";
import { GiftApiResponse, GiftEntity } from "./models/gift/gift.model";
import { LocationApiResponse, LocationEntity } from "./models/location/location.model";
import {
    BiographiesApiResponse,
    BiographyApiResponse,
    BiographyEntity,
    ParticipantApiResponse,
    ParticipantEntity,
    ParticipantsApiResponse,
    ParticipationApiResponse,
    ParticipationConsent,
    ParticipationConsentApiResponse,
    ParticipationEntity,
    ParticipationInviteEntity,
    ParticipationsApiResponse,
} from "./models/participation/participation.models";
import { PipelineApiResponse, PipelineEntity, PipelineName, PipelinesApiResponse } from "./models/pipeline.models";
import {
    ProposalApiResponse,
    ProposalEntity,
    ProposalsApiResponse,
    ProposalWorkflowStage,
} from "./models/proposal/proposal.models";
import {
    ScheduleDayEntity,
    ScheduleDayEntityApiResponse,
    ScheduleEntity,
    ScheduleEntityApiResponse,
    ScheduleModeratorEntity,
    ScheduleModeratorEntityApiResponse,
    ScheduleModeratorsEntityApiResponse,
    ScheduleNewDayEntity,
    SchedulePlanningSectionEntity,
    SchedulePlanningSectionEntityApiResponse,
    SchedulePlanningSectionsEntityApiResponse,
    ScheduleSlotEntity,
    ScheduleSlotEntityApiResponse,
    ScheduleTemplate,
    ScheduleTrackEntity,
    ScheduleTrackEntityApiResponse,
    ScheduleVersionEntity,
    ScheduleVersionEntityApiResponse,
    ScheduleVersionsEntityApiResponse,
    ShiftModel,
} from "./models/schedule/schedule.models";
import { TravelApiResponse, TravelEntity } from "./models/travel/travel.model";
import {
    activitiesByIdsApiPath,
    activityApiPath,
    activityPresentationApiPath,
    committeeActivitiesByEventApiPath,
    committeeParticipationApiPath,
    committeeParticipationBiographyApiPath,
    committeeParticipationBiographyPhotoApiPath,
    committeeParticipationsApiPath,
    committeeProposalCommunicationChannelJoinApiPath,
    exchangeCopyApiPath,
    participantApiPath,
    participantBiographiesApiPath,
    participantsApiPath,
    participantSearchApiPath,
    participationApiPath,
    participationBiographyApiPath,
    participationBiographyPhotoApiPath,
    participationConsentApiPath,
    participationEquipmentApiPath,
    participationGiftApiPath,
    participationLocationApiPath,
    participationsApiPath,
    participationTravelApiPath,
    pipelineApiPathById,
    pipelineApiPathByNameAndTriggeredAndContext,
    pipelineRestartApiPath,
    pipelineStartApiPath,
    proposalApiPath,
    proposalsApiPath,
    proposalSpeakerRoomApiPath,
    proposalsWithPagesApiPath,
    scheduleApiPath,
    scheduleDayApiPath,
    scheduleDayShiftApiPath,
    scheduleModeratorApiPath,
    scheduleModeratorsApiPath,
    schedulePlanningSectionApiPath,
    schedulePlanningSectionsApiPath,
    scheduleSlotApiPath,
    scheduleTrackApiPath,
    scheduleTrackShiftApiPath,
    scheduleVersionApiPath,
    scheduleVersionCloneApiPath,
    scheduleVersionPublishApiPath,
    scheduleVersionsApiPath,
    scheduleVersionShiftApiPath,
    sendNotificationToParticipantActivityContentPublishedApiPath,
    userInfoApiPath,
} from "./srm.api.routes";

export class SrmApiService {
    private api: AxiosInstance;

    constructor(private serverSide: boolean = false, private authStore?: AuthStore, private token?: string) {
        const baseURL = getBaseUrl(serverSide);
        this.api = initAxios(baseURL, authStore, token);

        if (authStore) {
            reaction(
                () => authStore?.accessToken,
                () => {
                    this.api = initAxios(baseURL, authStore);
                },
            );

            when(
                () => authStore?.isLogged,
                () => {
                    this.api = initAxios(baseURL, authStore);
                },
            );

            when(
                () => !authStore?.isLogged,
                () => {
                    this.api = initAxios(baseURL, authStore);
                },
            );
        }
    }

    // GET /api/v1/proposals
    async getProposals(pageSize?: number): Promise<ProposalEntity[]> {
        return this.api
            .get(pageSize ? proposalsWithPagesApiPath(pageSize) : proposalsApiPath)
            .then((result: AxiosResponse<ProposalsApiResponse>) => result.data?.data ?? ([] as ProposalEntity[]));
    }

    // GET /api/v1/proposals/{id}
    getProposal(id: number) {
        return this.api
            .get(proposalApiPath(id))
            .then((result: AxiosResponse<ProposalApiResponse>) => result.data?.data as ProposalEntity);
    }

    // PUT /api/v1/proposals/{id}
    putProposal(data: ProposalEntity) {
        return this.api
            .put(proposalApiPath(data.id), data)
            .then((result: AxiosResponse<ProposalApiResponse>) => result.data?.data as ProposalEntity);
    }

    // GET /api/v1/activities/{eventId}
    getActivitiesByEvent(eventId: number, stages?: ProposalWorkflowStage[]) {
        return this.api
            .get(committeeActivitiesByEventApiPath(eventId, stages))
            .then(
                (result: AxiosResponse<ActivityProposalParticipationsAggregateApiResponse>) =>
                    result.data?.data as ActivityProposalParticipationsAggregate[],
            );
    }

    // GET /api/v1/activities?ids=104823,10004245,10004275,10004435,10004436
    getActivitiesByIds(ids: number[]) {
        return this.api
            .get(activitiesByIdsApiPath(ids))
            .then((result: AxiosResponse<ActivitiesApiResponse>) => result.data?.data as ActivityEntity[]);
    }

    // GET /api/v1/activities/{id}
    getActivity(activityId, headers?: AxiosRequestHeaders) {
        return this.api
            .get(activityApiPath(activityId), {
                headers,
            })
            .then((result: AxiosResponse<ActivityApiResponse>) => result.data?.data as ActivityEntity);
    }

    // PUT /api/v1/activities/{id}
    putActivity(data: ActivityEntity) {
        return this.api
            .put(activityApiPath(data.id), data)
            .then((result: AxiosResponse<ActivityApiResponse>) => result.data?.data as ActivityEntity);
    }

    // PUT /api/v1/activities/{id}
    putActivityData(id: number, data: object, isContentDataEdit: boolean = false) {
        return this.api
            .put(activityApiPath(id, isContentDataEdit), data)
            .then((result: AxiosResponse<ActivityApiResponse>) => result.data?.data as ActivityEntity);
    }

    // GET /api/v1/participants
    getParticipants() {
        return this.api
            .get(participantsApiPath)
            .then((result: AxiosResponse<ParticipantsApiResponse>) => result.data?.data as ParticipantEntity[]);
    }

    // GET /api/v1/participants?query=<имя | фамилия | основной с т.з. ЛК email>
    searchParticipants(searchString: string) {
        return this.api
            .get(participantSearchApiPath(searchString))
            .then((result: AxiosResponse<ParticipantsApiResponse>) => result.data?.data as ParticipantEntity[]);
    }

    // GET /api/v1/participants/{id}
    getParticipant(participantId: number) {
        return this.api
            .get(participantApiPath(participantId))
            .then((result: AxiosResponse<ParticipantApiResponse>) => result.data?.data as ParticipantEntity);
    }

    // GET /api/v1/activities/{id}/participations
    getParticipations(activityId: number) {
        return this.api
            .get(participationsApiPath(activityId))
            .then((result: AxiosResponse<ParticipationsApiResponse>) => result.data?.data as ParticipationEntity[]);
    }

    // GET /api/v1/activities/{activityId}/participations/{participationId}
    getParticipation(activityId: number, participationId: number) {
        return this.api
            .get(participationApiPath(activityId, participationId))
            .then((result: AxiosResponse<ParticipationApiResponse>) => result.data?.data as ParticipationEntity);
    }

    // DELETE /api/v1/activities/{activityId}/participations/{participationId}
    deleteParticipation(activityId: number, participationId: number) {
        return this.api.delete(participationApiPath(activityId, participationId));
    }

    // PUT /api/v1/activities/{activityId}/participations/{participationId}
    putParticipationData(activityId: number, participationId: number, data: ParticipationEntity) {
        return this.api
            .put(participationApiPath(activityId, participationId), data)
            .then((result: AxiosResponse<ParticipationApiResponse>) => result.data?.data as ParticipationEntity);
    }

    // POST /api/v1/activities/{activityId}/participations
    postParticipationData(activityId: number, data: ParticipationInviteEntity) {
        return this.api
            .post(participationsApiPath(activityId), data)
            .then((result: AxiosResponse<ParticipationApiResponse>) => result.data?.data as ParticipationEntity);
    }

    // GET /api/v1/activities/{activityId}/participations/{participationId}/bio
    getParticipationBiography(activityId: number, participationId: number, headers?: AxiosRequestHeaders) {
        return this.api
            .get(participationBiographyApiPath(activityId, participationId), {
                headers,
            })
            .then((result: AxiosResponse<BiographyApiResponse>) => result.data?.data as BiographyEntity);
    }

    // PUT /api/v1/activities/{activityId}/participations/{participationId}/bio
    putParticipationBiography(activityId: number, participationId: number, data: BiographyEntity) {
        return this.api
            .put(participationBiographyApiPath(activityId, participationId), data)
            .then((result: AxiosResponse<BiographyApiResponse>) => result.data?.data as BiographyEntity);
    }

    // POST /api/v1/activities/{activityId}/participations/{participationId}/bio
    postParticipationBiography(activityId: number, participationId: number, data: BiographyEntity) {
        return this.api
            .post(participationBiographyApiPath(activityId, participationId), data)
            .then((result: AxiosResponse<BiographyApiResponse>) => result.data?.data as BiographyEntity);
    }

    // POST /api/v1/activities/{activityId}/participations/{participationId}/bio/photo
    postParticipationBiographyPhoto(activityId: number, participationId: number, data: FormData) {
        return this.api
            .post(participationBiographyPhotoApiPath(activityId, participationId), data)
            .then((result: AxiosResponse<BiographyApiResponse>) => result.data?.data as BiographyEntity);
    }

    // GET /api/v1/participants/{participantId}/biographies
    getParticipantBiographies(participantId: number) {
        return this.api
            .get(participantBiographiesApiPath(participantId))
            .then((result: AxiosResponse<BiographiesApiResponse>) => result.data?.data as BiographyEntity[]);
    }

    // GET /api/v1/activities/{activityId}/participations/{participationId}/travel
    getParticipationTravel(activityId: number, participationId: number) {
        return this.api
            .get(participationTravelApiPath(activityId, participationId))
            .then((result: AxiosResponse<TravelApiResponse>) => result.data?.data as TravelEntity);
    }

    // POST /api/v1/activities/{activityId}/participations/{participationId}/travel
    postParticipationTravel(activityId: number, participationId: number, data: TravelEntity) {
        return this.api
            .post(participationTravelApiPath(activityId, participationId), data)
            .then((result: AxiosResponse<TravelApiResponse>) => result.data?.data as TravelEntity);
    }

    // PUT /api/v1/activities/{activityId}/participations/{participationId}/travel
    putParticipationTravel(activityId: number, participationId: number, data: TravelEntity) {
        return this.api
            .put(participationTravelApiPath(activityId, participationId), data)
            .then((result: AxiosResponse<TravelApiResponse>) => result.data?.data as TravelEntity);
    }

    // GET /api/v1/activities/{activityId}/participations/{participationId}/location
    getParticipationLocation(activityId: number, participationId: number) {
        return this.api
            .get(participationLocationApiPath(activityId, participationId))
            .then((result: AxiosResponse<LocationApiResponse>) => result.data?.data as LocationEntity);
    }

    // POST /api/v1/activities/{activityId}/participations/{participationId}/location
    postParticipationLocation(activityId: number, participationId: number, data: LocationEntity) {
        return this.api
            .post(participationLocationApiPath(activityId, participationId), data)
            .then((result: AxiosResponse<LocationApiResponse>) => result.data?.data as LocationEntity);
    }

    // PUT /api/v1/activities/{activityId}/participations/{participationId}/location
    putParticipationLocation(activityId: number, participationId: number, data: LocationEntity) {
        return this.api
            .put(participationLocationApiPath(activityId, participationId), data)
            .then((result: AxiosResponse<LocationApiResponse>) => result.data?.data as LocationEntity);
    }

    // GET /api/v1/activities/{activityId}/participations/{participationId}/equipment
    getParticipationEquipment(activityId: number, participationId: number) {
        return this.api
            .get(participationEquipmentApiPath(activityId, participationId))
            .then((result: AxiosResponse<EquipmentApiResponse>) => result.data?.data as EquipmentEntity);
    }

    // POST /api/v1/activities/{activityId}/participations/{participationId}/equipment
    postParticipationEquipment(activityId: number, participationId: number, data: LocationEntity) {
        return this.api
            .post(participationEquipmentApiPath(activityId, participationId), data)
            .then((result: AxiosResponse<EquipmentApiResponse>) => result.data?.data as EquipmentEntity);
    }

    // PUT /api/v1/activities/{activityId}/participations/{participationId}/equipment
    putParticipationEquipment(activityId: number, participationId: number, data: LocationEntity) {
        return this.api
            .put(participationEquipmentApiPath(activityId, participationId), data)
            .then((result: AxiosResponse<EquipmentApiResponse>) => result.data?.data as EquipmentEntity);
    }

    // GET /api/v1/activities/{activityId}/participations/{participationId}/gift
    getParticipationGift(activityId: number, participationId: number) {
        return this.api
            .get(participationGiftApiPath(activityId, participationId))
            .then((result: AxiosResponse<GiftApiResponse>) => result.data?.data as GiftEntity);
    }

    // PUT /api/v1/activities/{activityId}/participations/{participationId}/gift
    putParticipationGift(activityId: number, participationId: number, data: GiftEntity) {
        return this.api
            .put(participationGiftApiPath(activityId, participationId), data)
            .then((result: AxiosResponse<GiftApiResponse>) => result.data?.data as GiftEntity);
    }

    // POST /api/v1/activities/{activityId}/participations/{participationId}/gift
    postParticipationGift(activityId: number, participationId: number, data: GiftEntity) {
        return this.api
            .post(participationGiftApiPath(activityId, participationId), data)
            .then((result: AxiosResponse<GiftApiResponse>) => result.data?.data as GiftEntity);
    }

    // GET /api/v1/activities/{activityId}/participations/{participationId}/consent
    getParticipationConsent(activityId: number, participationId: number) {
        return this.api
            .get(participationConsentApiPath(activityId, participationId))
            .then(
                (result: AxiosResponse<ParticipationConsentApiResponse>) => result.data?.data as ParticipationConsent,
            );
    }

    // PUT /api/v1/activities/{activityId}/participations/{participationId}/consent
    putParticipationConsent(activityId: number, participationId: number, data: ParticipationConsent) {
        return this.api
            .put(participationConsentApiPath(activityId, participationId), data)
            .then(
                (result: AxiosResponse<ParticipationConsentApiResponse>) => result.data?.data as ParticipationConsent,
            );
    }

    // POST /api/v1/activities/{activityId}/presentation
    postActivityPresentation(activityId: number, form: FormData) {
        return this.api
            .post(activityPresentationApiPath(activityId), form)
            .then((result: AxiosResponse<ActivityApiResponse>) => result.data?.data as ActivityEntity);
    }

    // GET /api/v1/schedules/{eventId}/versions
    getScheduleVersions(eventId: number) {
        return this.api
            .get(scheduleVersionsApiPath(eventId))
            .then(
                (result: AxiosResponse<ScheduleVersionsEntityApiResponse>) =>
                    result.data?.data as ScheduleVersionEntity[],
            );
    }

    // GET /api/v1/schedules/100100/versions/200300
    getScheduleVersion(eventId: number, scheduleId: number) {
        return this.api
            .get(scheduleVersionApiPath(eventId, scheduleId))
            .then((result: AxiosResponse<ScheduleEntityApiResponse>) => result.data?.data as ScheduleEntity);
    }

    // GET /api/v1/schedules/100100/
    getSchedulePublishedVersion(eventId: number) {
        return this.api
            .get(scheduleApiPath(eventId))
            .then((result: AxiosResponse<ScheduleEntityApiResponse>) => result.data?.data as ScheduleEntity);
    }

    // TODO putScheduleVersion
    putScheduleVersion(eventId: number, scheduleId: number, data: ScheduleVersionEntity) {
        return this.api
            .put(scheduleVersionApiPath(eventId, scheduleId), data)
            .then(
                (result: AxiosResponse<ScheduleVersionEntityApiResponse>) => result.data?.data as ScheduleVersionEntity,
            );
    }

    shiftSchedule(eventId: number, scheduleId: number, data: ShiftModel) {
        return this.api
            .put(scheduleVersionShiftApiPath(eventId, scheduleId), data)
            .then((result: AxiosResponse<ScheduleEntityApiResponse>) => result.data?.data as ScheduleEntity);
    }

    postScheduleVersion(eventId: number, data: ScheduleTemplate) {
        return this.api
            .post(scheduleVersionsApiPath(eventId), data)
            .then((result: AxiosResponse<ScheduleEntityApiResponse>) => result.data?.data as ScheduleEntity);
    }

    publishScheduleVersion(eventId: number, scheduleId: number) {
        return this.api
            .post(scheduleVersionPublishApiPath(eventId, scheduleId))
            .then(
                (result: AxiosResponse<ScheduleVersionsEntityApiResponse>) =>
                    result.data?.data as ScheduleVersionEntity[],
            );
    }

    cloneScheduleVersion(eventId: number, scheduleId: number) {
        return this.api
            .post(scheduleVersionCloneApiPath(eventId, scheduleId))
            .then((result: AxiosResponse<ScheduleEntityApiResponse>) => result.data?.data as ScheduleEntity);
    }

    deleteScheduleVersion(eventId: number, scheduleId: number) {
        return this.api.delete(scheduleVersionApiPath(eventId, scheduleId));
    }

    postScheduleDay(eventId: number, scheduleId: number, data: ScheduleNewDayEntity) {
        return this.api
            .post(scheduleDayApiPath(eventId, scheduleId), data)
            .then((result: AxiosResponse<ScheduleDayEntityApiResponse>) => result.data?.data as ScheduleDayEntity);
    }

    putScheduleDay(eventId: number, scheduleId: number, id: number, data: ScheduleNewDayEntity) {
        return this.api
            .put(scheduleDayApiPath(eventId, scheduleId, data.id), data)
            .then((result: AxiosResponse<ScheduleDayEntityApiResponse>) => result.data?.data as ScheduleDayEntity);
    }

    shiftScheduleDay(eventId: number, scheduleId: number, id: number, data: ShiftModel) {
        return this.api
            .put(scheduleDayShiftApiPath(eventId, scheduleId, id), data)
            .then((result: AxiosResponse<ScheduleDayEntityApiResponse>) => result.data?.data as ScheduleDayEntity);
    }

    deleteScheduleDay(eventId: number, scheduleId: number, id: number) {
        return this.api.delete(scheduleDayApiPath(eventId, scheduleId, id));
    }

    postScheduleTrack(eventId: number, scheduleId: number, dayId: number, data: ScheduleTrackEntity) {
        return this.api
            .post(scheduleTrackApiPath(eventId, scheduleId, dayId), data)
            .then((result: AxiosResponse<ScheduleTrackEntityApiResponse>) => result.data?.data as ScheduleTrackEntity);
    }

    putScheduleTrack(eventId: number, scheduleId: number, dayId: number, id: number, data: ScheduleTrackEntity) {
        return this.api
            .put(scheduleTrackApiPath(eventId, scheduleId, dayId, id), data)
            .then((result: AxiosResponse<ScheduleTrackEntityApiResponse>) => result.data?.data as ScheduleTrackEntity);
    }

    shiftScheduleTrack(eventId: number, scheduleId: number, dayId: number, id: number, data: ShiftModel) {
        return this.api
            .put(scheduleTrackShiftApiPath(eventId, scheduleId, dayId, id), data)
            .then((result: AxiosResponse<ScheduleTrackEntityApiResponse>) => result.data?.data as ScheduleTrackEntity);
    }

    deleteScheduleTrack(eventId: number, scheduleId: number, dayId: number, id: number) {
        return this.api.delete(scheduleTrackApiPath(eventId, scheduleId, dayId, id));
    }

    postScheduleSlot(eventId: number, scheduleId: number, dayId: number, trackId: number, data: ScheduleSlotEntity) {
        return this.api
            .post(scheduleSlotApiPath(eventId, scheduleId, dayId, trackId), data)
            .then((result: AxiosResponse<ScheduleSlotEntityApiResponse>) => result.data?.data as ScheduleSlotEntity);
    }

    putScheduleSlot(
        eventId: number,
        scheduleId: number,
        dayId: number,
        trackId: number,
        id: number,
        data: ScheduleSlotEntity,
    ) {
        return this.api
            .put(scheduleSlotApiPath(eventId, scheduleId, dayId, trackId, id), data)
            .then((result: AxiosResponse<ScheduleSlotEntityApiResponse>) => result.data?.data as ScheduleSlotEntity);
    }

    deleteScheduleSlot(eventId: number, scheduleId: number, dayId: number, trackId: number, id: number) {
        return this.api.delete(scheduleSlotApiPath(eventId, scheduleId, dayId, trackId, id));
    }

    getSchedulePlanningSections(eventId: number) {
        return this.api
            .get(schedulePlanningSectionsApiPath(eventId))
            .then(
                (result: AxiosResponse<SchedulePlanningSectionsEntityApiResponse>) =>
                    result.data?.data as SchedulePlanningSectionEntity[],
            );
    }

    postSchedulePlanningSection(eventId: number) {
        return this.api
            .post(schedulePlanningSectionsApiPath(eventId), {})
            .then(
                (result: AxiosResponse<SchedulePlanningSectionEntityApiResponse>) =>
                    result.data?.data as SchedulePlanningSectionEntity,
            );
    }

    putSchedulePlanningSection(eventId: number, id: number, data: SchedulePlanningSectionEntity) {
        return this.api
            .put(schedulePlanningSectionApiPath(eventId, id), data)
            .then(
                (result: AxiosResponse<SchedulePlanningSectionEntityApiResponse>) =>
                    result.data?.data as SchedulePlanningSectionEntity,
            );
    }

    deleteSchedulePlanningSection(eventId: number, id: number) {
        return this.api.delete(schedulePlanningSectionApiPath(eventId, id));
    }

    // Committee
    getCommittee(eventId: number) {
        return this.api
            .get(committeeApiPath(eventId))
            .then((result: AxiosResponse<CommitteeApiResponse>) => result.data?.data as CommitteeEntity);
    }

    getCommitteeParticipations(eventId: number) {
        return this.api
            .get(committeeParticipationsApiPath(eventId))
            .then(
                (result: AxiosResponse<CommitteeParticipationApiResponse>) =>
                    result.data?.data as CommitteeParticipationEntity[],
            );
    }

    addCommitteeParticipation(eventId: number, data: CommitteeParticipationInviteEntity) {
        return this.api
            .post(committeeParticipationsApiPath(eventId), data)
            .then(
                (result: AxiosResponse<CommitteeParticipationApiResponse>) =>
                    result.data?.data as CommitteeParticipationEntity,
            );
    }

    deleteCommitteeParticipation(eventId: number, id: number) {
        return this.api.delete(committeeParticipationApiPath(eventId, id));
    }

    saveCommitteeCommunicationInviteType(eventId: number, communicationInviteType: CommunicationInviteType) {
        return this.api
            .put(committeeApiPath(eventId), {
                communicationInviteType,
            })
            .then((result: AxiosResponse<CommitteeApiResponse>) => result.data?.data as CommitteeEntity);
    }

    saveCommitteeParticipationBiography(eventId: number, participationId: number, data: BiographyEntity) {
        return this.api
            .put(committeeParticipationBiographyApiPath(eventId, participationId), data)
            .then((result: AxiosResponse<BiographyApiResponse>) => result.data?.data as BiographyEntity);
    }

    addCommitteeParticipationBiography(eventId: number, participationId: number, data: BiographyEntity) {
        return this.api
            .post(committeeParticipationBiographyApiPath(eventId, participationId), data)
            .then((result: AxiosResponse<BiographyApiResponse>) => result.data?.data as BiographyEntity);
    }

    addCommitteeParticipationBiographyPhoto(eventId: number, participationId: number, data: FormData) {
        return this.api
            .post(committeeParticipationBiographyPhotoApiPath(eventId, participationId), data)
            .then((result: AxiosResponse<BiographyApiResponse>) => result.data?.data as BiographyEntity);
    }

    sendNotificationToParticipantActivityContentPublished(activityParticipationId: number) {
        return this.api.post(sendNotificationToParticipantActivityContentPublishedApiPath, { activityParticipationId });
    }

    // PUT /api/v1/proposals/{id}/speaker-room
    sendSpeakerRoomCreatePromise(proposalId: number, forced: boolean) {
        return this.api
            .put(proposalSpeakerRoomApiPath(proposalId), { forced })
            .then(
                (result: AxiosResponse<ActivityProposalAggregateApiResponse>) =>
                    result.data?.data as ActivityProposalAggregate,
            );
    }

    // GET /api/v1/userInfo
    getUserInfo() {
        return this.api
            .get(userInfoApiPath)
            .then((result: AxiosResponse<UserInfoApiResponse>) => result.data?.data as UserInfoEntity);
    }

    // GET /api/v1/pipeline?name=exchange_importer_pipeline&triggered_by=100500
    getPipelinesByNameAndTriggeredAndContext(name: PipelineName, triggeredBy: number, context: string) {
        return this.api
            .get(pipelineApiPathByNameAndTriggeredAndContext(name, triggeredBy, context))
            .then((result: AxiosResponse<PipelinesApiResponse>) => result.data?.data as PipelineEntity[]);
    }

    // POST /api/v1/pipeline/{pipelineId}/restart - рестартует FAILED
    postPipelineRestart(pipelineId: string) {
        return this.api
            .post(pipelineRestartApiPath(pipelineId))
            .then((result: AxiosResponse<PipelineApiResponse>) => result.data?.data as PipelineEntity);
    }

    // POST /api/v1/pipeline/{pipelineId}/start - стартует PENDING
    postPipelineStart(pipelineId: string) {
        return this.api
            .post(pipelineStartApiPath(pipelineId))
            .then((result: AxiosResponse<PipelineApiResponse>) => result.data?.data as PipelineEntity);
    }

    // GET /api/v1/pipeline/{pipelineId}
    getPipeline(pipelineId: string) {
        return this.api
            .get(pipelineApiPathById(pipelineId))
            .then((result: AxiosResponse<PipelineApiResponse>) => result.data?.data as PipelineEntity);
    }

    // POST /api/v1/exchange/copy
    postProposalCopy(data: ExchangeCopyModel) {
        return this.api
            .post(exchangeCopyApiPath, data)
            .then((result: AxiosResponse<PipelinesApiResponse>) => result.data?.data as PipelineEntity[]);
    }

    getScheduleModerators(eventId: number) {
        return this.api
            .get(scheduleModeratorsApiPath(eventId))
            .then(
                (result: AxiosResponse<ScheduleModeratorsEntityApiResponse>) =>
                    result.data?.data as ScheduleModeratorEntity[],
            );
    }

    putScheduleModerator(eventId: number, id: number, data: ScheduleModeratorEntity) {
        return this.api
            .put(scheduleModeratorApiPath(eventId, id), data)
            .then(
                (result: AxiosResponse<ScheduleModeratorEntityApiResponse>) =>
                    result.data?.data as ScheduleModeratorEntity,
            );
    }

    postScheduleModerator(eventId: number, data: object) {
        return this.api
            .post(scheduleModeratorsApiPath(eventId), data)
            .then(
                (result: AxiosResponse<ScheduleModeratorEntityApiResponse>) =>
                    result.data?.data as ScheduleModeratorEntity,
            );
    }

    deleteScheduleModerator(eventId: number, id: number) {
        return this.api.delete(scheduleModeratorApiPath(eventId, id));
    }

    putCommunicationChannel(eventId: number, proposalId: number) {
        return this.api.put(committeeProposalCommunicationChannelJoinApiPath(eventId, proposalId));
    }
}
