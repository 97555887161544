import { LocalizeString } from "./common.models";

export const isInstanceOfLocalizeString = (object: any): boolean => {
    if (object === undefined) {
        return false;
    }
    if (typeof object === "string") {
        return false;
    }
    return "ru" in (object as LocalizeString) && "en" in (object as LocalizeString);
};
