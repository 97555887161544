import { AccessToken } from "../data/auth/access-token";

const ACCESS_TOKEN_FIXTURE =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJsay1vYXV0aDJ8MTk2NSIsInVzZXJfbmFtZSI6Inl1bGlhLmxlYmVkZXZhKzFAanVncnUub3JnIiwiZGlzcGxheU5hbWUiOiLQktCw0YHRjyDQn9GD0L_QutC40L0iLCJzY29wZSI6WyJyZWd1bGFyIl0sImV4cCI6MTY5NjMxOTk4NSwibGFuZyI6IlJVIiwiYXV0aG9yaXRpZXMiOlsicmVndWxhciJdLCJqdGkiOiI5YjJhNzhlYi05NDkwLTQ2MWQtOTk4ZC0zZTdhMDM5YWFjZTMiLCJlbWFpbCI6Inl1bGlhLmxlYmVkZXZhKzFAanVncnUub3JnIiwiY2xpZW50X2lkIjoib25saW5lLWZyb250ZW5kLWJhY2tlbmQiLCJsa191c2VyX2lkIjoxOTY1fQ.KXvCFf_2kZbVKXLkD4xCmDRGBTG8EPn0xd-5yaFF03A";
export const ACCESS_TOKEN_FIXTURE_SOLYANIK_ADMIN =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJsay1vYXV0aDJ8NzIyMDQ1IiwidXNlcl9uYW1lIjoiR09PR0xFX0lEOjEwOTU2NDU4NzExNTU5ODIyMjAzNyIsImRpc3BsYXlOYW1lIjoiQWxleGV5IFNvbHlhbmlrIiwicHJvZmlsZSI6Imh0dHBzOi8vb2gtbXktZHVjay1kZXYuanVncnUub3JnL3VzZXJzLzhOTXFkdSIsImF1dGhvcml0aWVzIjpbIm9ubGluZV9hZG1pbiIsInJlZ3VsYXIiXSwicGljdHVyZSI6Imh0dHBzOi8vbGgzLmdvb2dsZXVzZXJjb250ZW50LmNvbS9hLS9BT2gxNEdqbW5OSG5YMFRUeGpUVUhWRE5BbDNRUXhnUXJUVWpWcmxhQ2JBMj1zOTYtYyIsImNsaWVudF9pZCI6ImxpdmUyLWNsaWVudCIsImxrX3VzZXJfaWQiOjcyMjA0NSwic2NvcGUiOlsicmVndWxhciJdLCJsa19zZXNzaW9uX2lkIjoxMzMyNCwiZXhwIjoxNjI3Mzc0MTQ4LCJsYW5nIjoicnUiLCJqdGkiOiI0ZDc2MTE4MS02M2MyLTQ2ODMtOGViMS0yMWVmZjgzYWNjNzMiLCJlbWFpbCI6ImFsZXhleS5zb2x5YW5pa0BqdWdydS5vcmcifQ";
export const ACCESS_TOKEN_FIXTURE_SOLYANIK_REGULAR =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJsay1vYXV0aDJ8NzIyMDQ1IiwidXNlcl9uYW1lIjoiR09PR0xFX0lEOjEwOTU2NDU4NzExNTU5ODIyMjAzNyIsImRpc3BsYXlOYW1lIjoiQWxleGV5IFNvbHlhbmlrIiwicHJvZmlsZSI6Imh0dHBzOi8vb2gtbXktZHVjay1kZXYuanVncnUub3JnL3VzZXJzLzhOTXFkdSIsImF1dGhvcml0aWVzIjpbInJlZ3VsYXIiXSwicGljdHVyZSI6Imh0dHBzOi8vbGgzLmdvb2dsZXVzZXJjb250ZW50LmNvbS9hLS9BT2gxNEdqbW5OSG5YMFRUeGpUVUhWRE5BbDNRUXhnUXJUVWpWcmxhQ2JBMj1zOTYtYyIsImNsaWVudF9pZCI6ImxpdmUyLWNsaWVudCIsImxrX3VzZXJfaWQiOjcyMjA0NSwic2NvcGUiOlsicmVndWxhciJdLCJsa19zZXNzaW9uX2lkIjoxMzMyNCwiZXhwIjoxNjI3Mzc0MTQ4LCJsYW5nIjoicnUiLCJqdGkiOiI0ZDc2MTE4MS02M2MyLTQ2ODMtOGViMS0yMWVmZjgzYWNjNzMiLCJlbWFpbCI6ImFsZXhleS5zb2x5YW5pa0BqdWdydS5vcmcifQ.igy6Ij2G9ji4vl7mtyB40wyaPnwt8QwXkheQ5WJ6Mqo";

export const getAccessTokenFixtureFromLocalStorage = (): string => {
    return typeof window !== "undefined"
        ? localStorage.getItem(AccessToken.ONLINE_SESSION_ACCESS_TOKEN_KEY) ?? ACCESS_TOKEN_FIXTURE
        : "";
};

export const setAccessTokenFixtureToLocalStorage = (isAdmin: boolean = true) => {
    if (isAdmin) {
        localStorage.setItem(AccessToken.ONLINE_SESSION_ACCESS_TOKEN_KEY, ACCESS_TOKEN_FIXTURE_SOLYANIK_ADMIN);
    } else {
        localStorage.setItem(AccessToken.ONLINE_SESSION_ACCESS_TOKEN_KEY, ACCESS_TOKEN_FIXTURE_SOLYANIK_REGULAR);
    }
};

export const removeAccessTokenFixtureFromLocalStorage = () => {
    localStorage.removeItem(AccessToken.ONLINE_SESSION_ACCESS_TOKEN_KEY);
};
