export const MDM_CONST = {
    __NO_CATEGORY__TAG: "__NO_CATEGORY__",
    __NO_TAG__TAG: "__NO_TAG__",
};

export const ProposalAnalyticsModelLocalizedIds = {
    talkStatistics: {
        all_unique_users: "domain.analytics.talkStatistics.all_unique_users",
        video_avg_rating: "domain.analytics.talkStatistics.video_avg_rating",
        all_sum_watch_duration: "domain.analytics.talkStatistics.all_sum_watch_duration",
        video_live_max_peack_users: "domain.analytics.talkStatistics.video_live_max_peack_users",
        talk_favorites_added: "domain.analytics.talkStatistics.talk_favorites_added",
        video_unique_users_perc1: "domain.analytics.talkStatistics.video_unique_users_perc1",
        video_unique_users_perc50: "domain.analytics.talkStatistics.video_unique_users_perc50",
        video_unique_users_perc90: "domain.analytics.talkStatistics.video_unique_users_perc90",
        discussion_duration: "domain.analytics.talkStatistics.discussion_duration",
        date_executed: "domain.analytics.talkStatistics.date_executed",
        offline_unique_users: "domain.analytics.talkStatistics.offline_unique_users",
        offline_discussion_unique_users: "domain.analytics.talkStatistics.offline_discussion_unique_users",
    },
    talkWatchSpread: "domain.analytics.talkWatchSpread",
    discussionHist: "domain.analytics.discussionHist",
    talkRecordHist: "domain.analytics.talkRecordHist",
    talkRatings: {
        form_advantages: "domain.analytics.talkRatings.form_advantages",
        form_disadvantages: "domain.analytics.talkRatings.form_disadvantages",
        form_comment: "domain.analytics.talkRatings.form_comment",
        title: "domain.analytics.talkRatings",
        count: "domain.analytics.talkRatings.count",
    },
    talkLiveHist: "domain.analytics.talkLiveHist",
};
