import { removeUndefined } from "../../../../utils/common";
import { AuthStore } from "../../../auth/auth.store";
import { rightsActions } from "../../../common/rights.actions";
import { coreApiAction } from "../../../core-api/core.actions";
import { CoreApiEventEntity } from "../../../core-api/models/events.models";
import { activityActions } from "../activity/activity.actions";
import { ActivityEntity, ActivityProposalAggregate, ActivityScope } from "../activity/activity.models";
import { CommitteeParticipationEntity } from "../committee/committee.models";
import { commonActions } from "../common.actions";
import { Form } from "../common.models";
import { EquipmentEntity } from "../equipment.models";
import { LocationEntity } from "../location/location.model";
import { proposalActions } from "../proposal/proposal.actions";
import { ProposalEntity } from "../proposal/proposal.models";
import { ContentStatus } from "../squidex.models";
import { TravelEntity } from "../travel/travel.model";
import { ParticipationEntityFormatValues } from "./participation.consts";
import {
    BiographyEntity,
    ExtendedBiographyEntity,
    ParticipationConsent,
    ParticipationEntity,
} from "./participation.models";

// noinspection RedundantConditionalExpressionJS
export const participationActions = {
    isParticipationPublicationConsentAccepted: (participation?: ParticipationEntity) => {
        if (!participation) {
            return undefined;
        }

        return participationActions.isPublicationConsentAccepted(participation.consent);
    },
    isPublicationConsentAccepted: (consent?: ParticipationConsent) => {
        if (!consent || !consent.forms) {
            return false;
        }

        return consent.forms?.some((a) => a.type === "content_publishing" && a.accepted);
    },
    getPublicationConsentForm: (consent?: ParticipationConsent): Form | undefined => {
        if (!consent || !consent.forms) {
            return undefined;
        }

        return consent.forms?.find((a) => a.type === "content_publishing");
    },
    getBiographyContentPublishedStatus: (
        aggregate?: ActivityProposalAggregate,
        participation?: ParticipationEntity,
    ): ContentStatus => {
        const productionStage = !aggregate ? true : proposalActions.isProposalProductionStage(aggregate?.proposal);
        const activityDraft = !aggregate?.activity
            ? false
            : commonActions.contentActions.isDraft(aggregate?.activity?.content?.status);

        return !productionStage ? "draft" : activityDraft ? "draft" : participation?.biography?.content?.status;
    },
    isSecondaryRole: (participation?: ParticipationEntity): boolean => participation?.role === "EXPERT",
    isTravelPossible: (participation?: ParticipationEntity): boolean =>
        participation?.format === undefined
            ? false
            : participation?.format === "ONLINE_STUDIO" || participation?.format === "OFFLINE",
    isLocationPossible: (participation?: ParticipationEntity): boolean =>
        participation?.format === undefined ? false : participation?.format === "ONLINE_REMOTE",
    isGiftPossible: (participation: ParticipationEntity): boolean =>
        participation?.format === undefined ? false : true,
    // REMOVE by Oksana 20.04.2022
    // participation?.format === "ONLINE_REMOTE" || participation?.format === "ONLINE_STUDIO",
    getFormatLocalizeId: (participation?: ParticipationEntity, scope?: ActivityScope): string | undefined =>
        participation?.format === undefined
            ? undefined
            : ParticipationEntityFormatValues(scope).find((f) => f.key === participation.format)?.displayId,
    getMyParticipation: (participations: ParticipationEntity[]) => participations.find((p) => p.isMe),
    getOtherParticipation: (participations: ParticipationEntity[]) => participations.filter((p) => !p.isMe),
    extractBiographyImages: (biography?: BiographyEntity) => {
        const photoIds = biography?.content?.data?.photo?.iv;

        if (!photoIds) {
            return undefined;
        }

        if (photoIds.length === 0) {
            return [];
        }

        const assets = biography?.content?.assets?.filter((a) => photoIds.includes(a.id));

        return assets?.map((a) => a.links?.content);
    },
    extractContentData: (biography?: BiographyEntity) => biography?.content?.data,
    extractTwitterData: (biography?: BiographyEntity) =>
        commonActions.twitterActions.getFieldValueWithoutSymbols(
            biography?.content?.data?.contacts?.iv?.filter((c) => c.type === "twitter")[0]?.value,
            "@",
        ),
    editAction: {
        removeParticipationNotEditableFields: (data: ParticipationEntity): ParticipationEntity =>
            removeUndefined({
                ...data,
                id: undefined,
                actions: undefined,
                role: undefined,
                status: undefined,
                isMe: undefined,
                participant: undefined,
                biography: undefined,
            } as ParticipationEntity),
        removeNotEditableFields: (
            data: LocationEntity | EquipmentEntity | TravelEntity,
        ): LocationEntity | EquipmentEntity | TravelEntity =>
            removeUndefined({
                ...data,
                id: undefined,
                participantId: undefined,
                actions: undefined,
            }),
        removeBiographyNotEditableFields: (data: BiographyEntity, withPhoto?: boolean): BiographyEntity =>
            removeUndefined({
                ...data,
                id: undefined,
                actions: undefined,
                participantId: undefined,
                participationId: undefined,
                version: undefined,
                status: undefined,
                referencedBy: undefined,
                content: {
                    ...data.content,
                    id: undefined,
                    created: undefined,
                    status: undefined,
                    schemaId: undefined,
                    schemaName: undefined,
                    lastModified: undefined,
                    assets: undefined,
                    data: {
                        ...data.content?.data,
                        id: undefined,
                        ownerId: undefined,
                        photo: withPhoto ? data.content?.data?.photo : undefined,
                    },
                    schema: undefined,
                    version: undefined,
                },
            }),
    },
    isEditingEnabled: (participation?: ParticipationEntity, proposal?: ProposalEntity, authStore?: AuthStore) => {
        if (!participation || !proposal?.activity || !proposal.event?.id || !authStore) {
            return false;
        }

        const isPartnerScope = activityActions.isPartnerScope(proposal?.activity);

        if (isPartnerScope && !(authStore.isGlobalAdmin || authStore.isCoordinator(proposal.event?.id))) {
            return false;
        }

        return rightsActions.canEdit(participation);
    },
    canEditSomeParticipation(currentParticipations?: ParticipationEntity[]) {
        if (!currentParticipations || currentParticipations?.length === 0) {
            return false;
        }

        return currentParticipations.some(rightsActions.canEdit);
    },
    sortCommitteeParticipations(a: CommitteeParticipationEntity, b: CommitteeParticipationEntity) {
        if (a.role === "COORDINATOR" && b.role !== "COORDINATOR") {
            return -1;
        }

        if (a.role === "COORDINATOR" && b.role === "COORDINATOR") {
            return a.id - b.id;
        }

        if (a.role === b.role) {
            return a.id - b.id;
        }

        return 1;
    },
    sortParticipationsByRole(a: ParticipationEntity, b: ParticipationEntity) {
        if (a.role === b.role) {
            return a.id - b.id;
        }

        if (a.role === "SPEAKER") {
            return -1;
        }

        if (a.role === "EXPERT" && b.role !== "SPEAKER") {
            return -1;
        }

        return 1;
    },
    getActivitiesIdFromBios(bios: BiographyEntity[]) {
        const ids = bios
            .flatMap((b) => b.referencedBy)
            .filter((r) => r.type === "activity")
            .map((r) => r.id);

        return [...new Set(ids)];
    },
    getEventsIdFromBios(bios: BiographyEntity[]) {
        const ids = bios
            .flatMap((b) => b.referencedBy)
            .filter((r) => r.type === "event")
            .map((r) => r.id);

        return [...new Set(ids)];
    },
    sortBiographiesByEvent(a: ExtendedBiographyEntity, b: ExtendedBiographyEntity) {
        const aEvents = a.referencedBy
            ?.filter((r) => r.type === "event")
            .map((r) => r.entity as CoreApiEventEntity)
            .sort(coreApiAction.comparatorEventByDate);
        const bEvents = b.referencedBy
            ?.filter((r) => r.type === "event")
            .map((r) => r.entity as CoreApiEventEntity)
            .sort(coreApiAction.comparatorEventByDate);

        if (!aEvents || !bEvents) {
            return -1;
        }

        return coreApiAction.comparatorEventByDate(aEvents[0], bEvents[0]);
    },
    mergeParticipantBiographiesWithActivities(
        bios: BiographyEntity[],
        activities: ActivityEntity[],
    ): ExtendedBiographyEntity[] {
        return bios
            .map((b) => {
                return {
                    ...b,
                    referencedBy: b.referencedBy?.map((r) => {
                        if (r?.type === "activity") {
                            return {
                                ...r,
                                entity: activities.find((a) => a.id === r.id),
                            };
                        }
                        return r;
                    }),
                };
            })
            .sort(participationActions.sortBiographiesByEvent);
    },
    mergeParticipantBiographiesWithEvents(
        bios: BiographyEntity[],
        events: CoreApiEventEntity[],
    ): ExtendedBiographyEntity[] {
        return bios
            .map((b) => {
                return {
                    ...b,
                    referencedBy: b.referencedBy?.map((r) => {
                        if (r?.type === "event") {
                            return {
                                ...r,
                                entity: events.find((a) => a.id === r.id),
                            };
                        }
                        return r;
                    }),
                } as ExtendedBiographyEntity;
            })
            .sort(participationActions.sortBiographiesByEvent);
    },
    getEventsFromBio(biography: ExtendedBiographyEntity) {
        return biography?.referencedBy
            ?.filter((r) => r.type === "event")
            .map((r) => r.entity as CoreApiEventEntity)
            .sort(coreApiAction.comparatorEventByDate);
    },
};
