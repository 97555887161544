import React, { FC, ReactNode } from "react";

import styles from "./Notification.module.scss";

import { useI18nLocaleFormat } from "../../../hooks/useI18nLocaleFormat";

interface CenterAlertWithTextProps {
    topNode?: ReactNode;
    textId?: string;
    headerId?: string;
}

const CenterAlertWithText: FC<CenterAlertWithTextProps> = ({ headerId, textId, topNode }) => {
    const { localeAs } = useI18nLocaleFormat();
    return (
        <div className={styles.CenterAlertWithText}>
            <div className={styles.CenterAlertWithText__content}>
                {topNode && topNode}
                <div className={styles.CenterAlertWithText__content_header}>{localeAs(headerId as any)}</div>
                <div>{localeAs(textId as any)}</div>
            </div>
        </div>
    );
};

export default CenterAlertWithText;
