import { useState } from "react";

import "../styles/fonts.scss";
import "../styles/mixins.scss";
import "../styles/styles.scss";
import "../styles/variables.scss";

import { AppProps } from "next/app";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import PrimeReact from "primereact/api";
import "primereact/resources/primereact.min.css";
import "tippy.js/dist/tippy.css";

import Application from "../components/common/Application/Application";
import initializeAll, { DataContextData } from "../data/initializeAll";

const App = ({ Component, pageProps, router }: AppProps) => {
    PrimeReact.ripple = true;

    const [contextData] = useState<DataContextData>(() => initializeAll());

    return <Application Component={Component} pageProps={pageProps} router={router} contextData={contextData} />;
};

// noinspection JSUnusedGlobalSymbols
export default App;
