import { makeAutoObservable } from "mobx";

import { AccessToken } from "./access-token";
import { authActions } from "./auth.actions";
import {
    COMPANIES_ADMIN_ROLE,
    COORDINATOR_ROLE,
    EDITOR_ROLE,
    GLOBAL_ADMIN_ROLE,
    ONLINE_TEAM_ROLE,
    SRM_USER_ROLE,
    SRM_VIEWER,
    SUPPORT_ROLE,
    UserInfoEntity,
} from "./auth.models";

export class AuthStore {
    public accessToken?: AccessToken;
    public isLogged?: boolean = undefined;
    public userId?: number | undefined | null;
    public userEmail?: string;
    public displayName?: string;
    public picture?: string;

    private lkLang: string = "ru";
    private lkSessionId?: number;
    private sub?: string;
    private userInfo?: UserInfoEntity;
    private authorities?: string[];

    constructor() {
        makeAutoObservable(this);
    }

    private setDisplayName(displayName?: string) {
        this.displayName = displayName;
    }

    private setIsLogged(isLogged: boolean) {
        this.isLogged = isLogged;
    }

    private setUserId(userId?: number) {
        this.userId = userId;
    }

    private setUserEmail(userEmail?: string) {
        this.userEmail = userEmail;
    }

    private setLkLang(lang: string) {
        this.lkLang = lang.toLowerCase();
    }

    private setLkSessionId(lkSessionId?: number) {
        this.lkSessionId = lkSessionId;
    }

    private setSub(sub?: string) {
        this.sub = sub;
    }

    private setPicture(picture?: string) {
        this.picture = picture;
    }

    private setTokens(accessToken?: AccessToken) {
        this.accessToken = accessToken;
    }

    private setAuthorities(authorities?: string[]) {
        this.authorities = authorities;
    }

    public setUserInfo(info: UserInfoEntity) {
        this.userInfo = info;
        return info;
    }

    public updateFromAccessToken(accessToken?: AccessToken): AuthStore {
        if (!accessToken || !accessToken.value) {
            this.setTokens(undefined);
            this.setIsLogged(false);
            this.setUserId(null);
            this.setUserEmail(undefined);
            this.setDisplayName(undefined);
            this.setLkLang("ru");
            this.setLkSessionId(undefined);
            this.setSub(undefined);
            this.setPicture(undefined);
            this.setAuthorities(undefined);
        } else {
            this.setTokens(accessToken);
            this.setIsLogged(true);
            this.setUserId(accessToken.value?.lk_user_id);
            this.setUserEmail(accessToken.value?.email);
            this.setDisplayName(accessToken.value?.displayName);
            this.setLkLang(accessToken.value?.lang);
            this.setLkSessionId(accessToken.value?.lk_session_id);
            this.setSub(accessToken.value?.sub);
            this.setPicture(accessToken.value?.picture);
            this.setAuthorities(accessToken.value?.authorities);
        }

        return this;
    }

    get accessTokenRaw(): string | undefined {
        return this.accessToken?.raw;
    }

    get isGlobalAdmin(): boolean {
        return this.authorities?.includes(GLOBAL_ADMIN_ROLE) ?? false;
    }

    get isCompaniesAdmin(): boolean {
        return this.authorities?.filter((a) => a.startsWith(COMPANIES_ADMIN_ROLE)).length > 0 ?? false;
    }

    get isGlobalCoordinator(): boolean {
        return this.authorities?.includes(COORDINATOR_ROLE) ?? false;
    }

    public isCoordinator(eventId: number): boolean {
        if (!eventId) {
            return false;
        }
        if (this.isGlobalCoordinator) {
            return true;
        }

        return authActions.isLocalRoleForEvent(this.userInfo, "committee:coordinator", eventId);
    }

    get isGlobalEditor(): boolean {
        return this.authorities?.includes(EDITOR_ROLE) ?? false;
    }

    public isAdminCoordinatorEditor(eventId: number): boolean {
        if (!eventId) {
            return false;
        }

        if (this.isGlobalAdmin || this.isGlobalCoordinator || this.isGlobalEditor) {
            return true;
        }

        return (
            authActions.isLocalRoleForEvent(this.userInfo, "committee:coordinator", eventId) ||
            authActions.isLocalRoleForEvent(this.userInfo, "committee:editor", eventId)
        );
    }

    public isEditor(eventId: number): boolean {
        if (!eventId) {
            return false;
        }

        if (this.isGlobalEditor) {
            return true;
        }

        return authActions.isLocalRoleForEvent(this.userInfo, "committee:editor", eventId);
    }

    public isModerator(eventId: number): boolean {
        if (!eventId) {
            return false;
        }

        return authActions.isLocalRoleForEvent(this.userInfo, "committee:moderator", eventId);
    }

    public isCommitteeMember(eventId: number): boolean {
        if (this.isCoordinator(eventId) || this.isGlobalAdmin) {
            return true;
        }

        return authActions.isLocalRoleForEvent(this.userInfo, "committee:member", eventId);
    }

    public isOnlyCommitteeMember(eventId: number): boolean {
        return this.isCommitteeMember(eventId) && !this.isAdminCoordinatorEditor(eventId);
    }

    get isSrmUser(): boolean {
        return (
            (this.authorities?.includes(SRM_USER_ROLE) ||
                this.isGlobalCoordinator ||
                this.isGlobalEditor ||
                this.isGlobalAdmin) ??
            false
        );
    }

    get isGlobalInternalUser(): boolean {
        return (
            this.isGlobalCoordinator ||
            this.isGlobalEditor ||
            this.isGlobalAdmin ||
            this.isGlobalOnlineTeam ||
            this.isSrmViewer ||
            this.isGlobalSupportTeam
        );
    }

    get isGlobalOnlineTeam(): boolean {
        return this.authorities?.includes(ONLINE_TEAM_ROLE);
    }

    // DEPRECATED by SRM-831
    // get isGlobalModeratorsTeam(): boolean {
    //     return this.authorities?.includes(ONLINE_DISCUSSION_ADMIN_ROLE);
    // }

    get isGlobalSupportTeam(): boolean {
        return this.authorities?.includes(SUPPORT_ROLE);
    }

    get userInfoLoaded(): boolean {
        return this.isLogged && this.userInfo !== undefined;
    }

    get thereAreAnyRoleInAnyEvent(): boolean {
        if (this.isGlobalInternalUser) {
            return true;
        }

        return authActions.hasAnyCommitteeRoleInAnyEvent(this.userInfo);
    }

    get isSrmViewer(): boolean {
        return this.authorities?.includes(SRM_VIEWER);
    }
}
