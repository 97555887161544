import { ApiResponse, EntityNumber } from "../common/common.models";

export type UserInfoApiResponse = ApiResponse<UserInfoEntity>;

export type UserInfoRoleName =
    | "committee:coordinator"
    | "committee:member"
    | "committee:editor"
    | "committee:moderator"
    | "partner:speaker"
    | "regular:expert"
    | "regular:host"
    | "regular:speaker";

export interface UserInfoRoleValue {
    readonly event?: number[];
    readonly activity?: number[];
    readonly proposal?: number[];
}

export type UserInfoRoles = {
    [key in UserInfoRoleName]?: UserInfoRoleValue;
};

export interface UserInfoEntity extends EntityNumber {
    readonly name?: string;
    readonly photo?: string;
    readonly authorities?: string[];
    readonly roles?: UserInfoRoles;
}

export const GLOBAL_ADMIN_ROLE = "admin";
export const SRM_USER_ROLE = "srm_user";
export const COORDINATOR_ROLE = "srm_coordinator";
export const EDITOR_ROLE = "srm_editor";
export const ONLINE_EDITOR_ROLE = "online_editor";
export const ONLINE_TEAM_ROLE = "online_team";
export const COMPANIES_ADMIN_ROLE = "company_admin_";
export const SRM_VIEWER = "srm_viewer";
export const ONLINE_DISCUSSION_ADMIN_ROLE = "online_discussion_admin";
export const SUPPORT_ROLE = "support";
