import * as Sentry from "@sentry/nextjs";
import {getEnvironment, release} from "./environment";
import {Integrations} from "@sentry/tracing";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
    dsn: SENTRY_DSN || "https://3e31f589d0e743dda77a89e7d415d454@vulpes-lagopus.jugru.team/5",
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    environment: getEnvironment(),
    release,

    integrations: [new Integrations.BrowserTracing()],
});
