import { DispatchWithoutAction, useState } from "react";

import { useLocalStorageState } from "../../../../hooks/useLocalStorageState";
import { arrayWeakEquals } from "../../../../utils/common";

export const FILTER_LOGIC_VERSION = 13;

export interface FilterData {
    key: string;
    label: string;
    prevData?: any;
    data?: any;
    type: "boolean" | "text";
    negativeLabel?: string;
    order?: number;
    noteId?: string;
}

export interface FilterPanelInitData {
    filters: FilterData[];
    version?: number;
}

export interface FilterPanelData extends FilterPanelInitData {
    changedFilters: FilterData[];
    changedCount: number;
}

const FilterPanelDataDefault: FilterPanelData = {
    filters: [],
    changedFilters: [],
    changedCount: 0,
    version: FILTER_LOGIC_VERSION,
};

export type SetFilterDispatchValueType = boolean | string | object | number | string[] | undefined | null;
export type SetFilterDispatchObject = { key: string; value?: SetFilterDispatchValueType; filterData?: FilterData };
export type SetFilterDispatch = (setFilterDispatchObjects: SetFilterDispatchObject[]) => void;
export type FilterDispatch = (key: string) => void;
export type SetFilterDispatchEffector = (
    filtersData: FilterPanelData,
    newFiltersData: SetFilterDispatchObject[],
) => SetFilterDispatchObject[];
export type SetFilterDispatchEffectors = SetFilterDispatchEffector[];

export const filterDataSortFn = (a, b) => {
    if (a.order === undefined && b.order === undefined) {
        return b.key - a.key;
    }
    return (a.order ?? -1) - (b.order ?? -1);
};

const useFilterData = (
    filterKey: string,
    initData?: FilterPanelInitData,
    setFiltersEffectors?: SetFilterDispatchEffectors,
): [FilterPanelData, SetFilterDispatch, DispatchWithoutAction, FilterDispatch] => {
    const [initState] = useState<FilterPanelInitData>(initData ?? FilterPanelDataDefault);
    const [state, setState] = useLocalStorageState<FilterPanelData>(filterKey, {
        ...FilterPanelDataDefault,
        ...(initData ?? {}),
    });

    const calculateChangedFilters = (newState: FilterPanelData) =>
        newState.filters
            .map((f) => {
                const initStateData = initState.filters.find((fi) => fi.key === f.key)?.data;

                if (Array.isArray(f.data)) {
                    if (!arrayWeakEquals(f.data, initStateData)) {
                        return f;
                    }
                    return undefined;
                }

                if (initStateData !== f.data) {
                    return f;
                }
                return undefined;
            })
            .filter((f) => f)
            .sort(filterDataSortFn);

    const calculateChangedCount = (newState: FilterData[]) =>
        newState.reduce((acc, f) => {
            if (f.data !== undefined) {
                if (Array.isArray(f.data)) {
                    return acc + f.data.length;
                } else {
                    return acc + 1;
                }
            }
        }, 0);

    const setFilter: SetFilterDispatch = (setFilterDispatchObjects: SetFilterDispatchObject[]) => {
        let finalSetFilterDispatchObjects = [...setFilterDispatchObjects];
        if (setFiltersEffectors?.length > 0) {
            setFiltersEffectors.forEach((effector) => {
                finalSetFilterDispatchObjects = [
                    ...finalSetFilterDispatchObjects,
                    ...effector(state, finalSetFilterDispatchObjects),
                ];
            });
        }

        let newState = {
            ...state,
        };

        finalSetFilterDispatchObjects.forEach(({ key, value: data, filterData }) => {
            // delete key
            if (data === undefined || (Array.isArray(data) && data.length === 0) || data === "") {
                // try to find init data
                const initFilterData = initData.filters.find((f) => f.key === key);

                if (initFilterData) {
                    newState = {
                        ...newState,
                        filters: [...newState.filters.filter((f) => f.key !== key), initFilterData],
                    };
                } else {
                    newState = {
                        ...newState,
                        filters: [...newState.filters.filter((f) => f.key !== key)],
                    };
                }
            } else {
                const oldData = newState.filters.find((f) => f.key === key);

                if (!oldData && !filterData) {
                    return;
                }

                const newFilterData: FilterData = {
                    ...(oldData ?? filterData),
                    label: filterData?.label ?? oldData?.label ?? "",
                    prevData: oldData?.data,
                    data,
                };

                newState = {
                    ...newState,
                    filters: [...newState.filters.filter((f) => f.key !== key), newFilterData],
                };
            }
        });

        const changedFilters = calculateChangedFilters(newState);

        setState({
            ...newState,
            changedFilters,
            changedCount: calculateChangedCount(changedFilters),
        });
    };

    const resetAllFilters = () =>
        setState({
            ...FilterPanelDataDefault,
            ...initState,
        });

    const resetFilter: FilterDispatch = (key: string) => {
        const value = initData.filters.find((f) => f.key === key);

        if (value) {
            setFilter([{ key, value }]);
        }
    };

    return [state, setFilter, resetAllFilters, resetFilter];
};

export { useFilterData };
