import { BootstrappingResult, SaveAnalyticsService } from "@analytics-ring/analytics-ring-collector-client";

class AnalyticsClientProviderAsync {
    private analyticsRingClientServicesPromise: Promise<BootstrappingResult | undefined>;

    constructor() {
        this.analyticsRingClientServicesPromise = new Promise((resolve) => {
            const isClientSide = typeof window !== "undefined";

            if (isClientSide) {
                return import("./AnalyticsClientProviderWrapper").then((module) => {
                    resolve(module.default());
                });
            }
            return resolve(undefined);
        });
    }

    getSaveAnalyticsServiceAsync(): Promise<SaveAnalyticsService | undefined> {
        return this.analyticsRingClientServicesPromise.then((result) => {
            if (result) {
                return result.services.saveAnalytics;
            }
            return undefined;
        });
    }
}

export default AnalyticsClientProviderAsync;
