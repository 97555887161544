import { SaveAnalyticsService } from "@analytics-ring/analytics-ring-collector-client";

import AnalyticsClientProviderAsync from "./analytics/AnalyticsClientProviderAsync";
import { CFP_APPSTATE_KEY, defaultAppState } from "./app/AppState";
import { AppStateStore } from "./app/AppStateStore";
import { AuthApiService } from "./auth/auth.api.service";
import AuthService from "./auth/auth.service";
import { AuthStore } from "./auth/auth.store";
import { CoreService } from "./core-api/core.service";
import { CoreStore } from "./core-api/core.store";
import { MdmService } from "./mdm/mdm.service";
import { NotificationsStore } from "./notifications/notifications.store";
import { RatingsService } from "./ratings/ratings.service";
import SrmService from "./srm/srm.service";
import SrmStore from "./srm/srm.store";

export interface DataContextData {
    stores: {
        authStore: AuthStore;
        notificationsStore: NotificationsStore;
        appStateStore: AppStateStore;
        srmStore: SrmStore;
        coreStore: CoreStore;
    };
    services: {
        authService: AuthService;
        analyticServiceAsync: Promise<SaveAnalyticsService | undefined>;
        authApiService: AuthApiService;
        srmService: SrmService;
        coreService: CoreService;
        mdmService: MdmService;
        ratingsService: RatingsService;
    };
}

const initializeAll = (): DataContextData => {
    const authStore = new AuthStore();
    const notificationsStore = new NotificationsStore(authStore);
    const srmStore = new SrmStore();
    const coreStore = new CoreStore();

    const appStateStore = new AppStateStore(CFP_APPSTATE_KEY, defaultAppState);
    // temporary lock
    // const configService = new ConfigService();

    const authApiService = new AuthApiService();
    const authService = new AuthService(authApiService, authStore);
    const analyticServiceAsync = new AnalyticsClientProviderAsync().getSaveAnalyticsServiceAsync();
    const coreService = new CoreService(coreStore, authStore);
    const srmService = new SrmService(srmStore, authStore, coreService);
    const mdmService = new MdmService(authStore);
    const ratingsService = new RatingsService(authStore);

    return {
        stores: {
            authStore,
            notificationsStore,
            appStateStore,
            srmStore,
            coreStore,
        },
        services: {
            authApiService,
            authService,
            analyticServiceAsync,
            srmService,
            coreService,
            mdmService,
            ratingsService,
        },
    };
};

export default initializeAll;
