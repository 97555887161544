import { isLocalEnvironment } from "../../../environment";
import { getAndSetStateFromLocalStorageCached } from "../../utils/localStorage";
import { AuthStore } from "../auth/auth.store";
import { mdmActions } from "./mdm.actions";
import { MdmApiService } from "./mdm.api.service";
import { EventAnalyticsModel, ProposalAnalyticsModel } from "./models/analytics.models";

export class MdmService {
    private readonly mdmApiService: MdmApiService;

    constructor(private authStore: AuthStore) {
        this.mdmApiService = new MdmApiService(
            false,
            // for local development
            isLocalEnvironment,
            authStore,
        );
    }

    async loadEventAnalytics(eventId: number): Promise<EventAnalyticsModel | null | undefined> {
        return getAndSetStateFromLocalStorageCached<EventAnalyticsModel>(
            mdmActions.SAVED_EVENT_STAT_DATA_KEY(eventId),
            () => this.mdmApiService.getEventAnalytics(eventId),
        );
    }

    async loadProposalAnalytics(proposalId: number): Promise<ProposalAnalyticsModel | null> {
        return getAndSetStateFromLocalStorageCached<ProposalAnalyticsModel>(
            mdmActions.SAVED_PROPOSAL_STAT_DATA_KEY(proposalId),
            () => this.mdmApiService.getProposalAnalytics(proposalId),
        );
    }
}
