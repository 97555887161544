// https://gitlab.jugru.team/program-department/speaker-personal-area/srm-docs/-/blob/main/docs/architecture/cfp/index.md
import { committeeApiPath, committeeEventApiPath } from "../core-api/core.api.routes";
import { ProposalWorkflowStage } from "./models/proposal/proposal.models";

export const pipelineApiPath = "/pipeline";
export const userInfoApiPath = "/userinfo";
export const proposalsApiPath = "/proposals";
const exchangeApiPath = "/exchange";
export const proposalsWithPagesApiPath = (pageSize: number) => `${proposalsApiPath}?page_size=${pageSize}`;
export const proposalApiPath = (proposalId: number) => `${proposalsApiPath}/${proposalId}`;
export const proposalSpeakerRoomApiPath = (proposalId: number) => `${proposalApiPath(proposalId)}/speaker-room`;

export const participantsApiPath = "/participants";
const schedulesApiPath = "/schedules";

export const notificationsApiPath = "/notifications";

const activitiesApiPath = "/activities";
export const activitiesByIdsApiPath = (ids: number[]) => `${activitiesApiPath}?ids=${ids.join(",")}`;

export const activityApiPath = (activityId: number, isContentDataEdit: boolean = false) =>
    `${activitiesApiPath}/${activityId}${isContentDataEdit ? "?isContentDataEdit=true" : ""}`;
export const activityPresentationApiPath = (activityId: number) => `${activityApiPath(activityId, false)}/presentation`;

const committeeProposalsOrActivitiesByEventApiPath = (type: "activities" | "proposals", eventId: number) =>
    `${committeeEventApiPath(eventId)}/${type}`;

const committeeProposalByEventApiPath = (eventId: number, proposalId: number) =>
    `${committeeProposalsOrActivitiesByEventApiPath("proposals", eventId)}/${proposalId}`;

export const committeeProposalCommunicationChannelJoinApiPath = (eventId: number, proposalId: number) =>
    `${committeeProposalByEventApiPath(eventId, proposalId)}/communication-channel/join`;

export const committeeActivitiesByEventApiPath = (eventId: number, stages?: ProposalWorkflowStage[]) => {
    const url = committeeProposalsOrActivitiesByEventApiPath("activities", eventId);

    if (stages) {
        return `${url}?stages=${stages.join(",")}`;
    }

    return url;
};

export const committeeProposalsByEventApiPath = (eventId: number, stages?: ProposalWorkflowStage[]) => {
    const url = committeeProposalsOrActivitiesByEventApiPath("proposals", eventId);

    if (stages) {
        return `${url}?stages=${stages.join(",")}`;
    }

    return url;
};

export const participationsApiPath = (activityId: number) => `${activityApiPath(activityId, false)}/participations`;
export const participationApiPath = (activityId: number, participationId: number) =>
    `${participationsApiPath(activityId)}/${participationId}`;
export const participationBiographyApiPath = (activityId: number, participationId: number) =>
    `${participationApiPath(activityId, participationId)}/bio`;
export const participationBiographyPhotoApiPath = (activityId: number, participationId: number) =>
    `${participationBiographyApiPath(activityId, participationId)}/photo`;

export const participantApiPath = (participantId: number) => `${participantsApiPath}/${participantId}`;
export const participantBiographiesApiPath = (participantId: number) =>
    `${participantApiPath(participantId)}/biographies`;
export const participantSearchApiPath = (query: string) => `${participantsApiPath}/?query=${query}`;

export const participationTravelApiPath = (activityId: number, participationId: number) =>
    `${participationApiPath(activityId, participationId)}/travel`;
export const participationLocationApiPath = (activityId: number, participationId: number) =>
    `${participationApiPath(activityId, participationId)}/location`;
export const participationEquipmentApiPath = (activityId: number, participationId: number) =>
    `${participationApiPath(activityId, participationId)}/equipment`;
export const participationGiftApiPath = (activityId: number, participationId: number) =>
    `${participationApiPath(activityId, participationId)}/gift`;
export const participationConsentApiPath = (activityId: number, participationId: number) =>
    `${participationApiPath(activityId, participationId)}/consent`;

// - получение расписания publish версии GET /api/v1/schedules/100100
// - получение версий GET /api/v1/schedules/100100/versions
// - получение версии GET /api/v1/schedules/100100/versions/200300
// - добавление новой версии POST /api/v1/schedules/100100/versions
// - изменение версии PUT /api/v1/schedules/100100/versions/200300
// - публикация версии POST /api/v1/schedules/100100/versions/200300/publish
// - клонирование версии POST /api/v1/schedules/100100/versions/200300/clone
// - перемещение дней версии PUT /api/v1/schedules/100100/versions/200300/shift
// - добавление дня POST /api/v1/schedules/100100/versions/200300/days
// - добавление трека POST /api/v1/schedules/100100/versions/200300/days/200400/tracks
// - добавление слота POST /api/v1/schedules/100100/versions/200300/days/200400/tracks/200500/slots
// - изменение дня PUT /api/v1/schedules/100100/versions/200300/days/200400
// - перемещение по времени PUT /api/v1/schedules/100100/versions/200300/days/200400/shift
// - изменение трека PUT /api/v1/schedules/100100/versions/200300/days/200400/tracks/200500
// - перемещение по времени PUT /api/v1/schedules/100100/versions/200300/days/200400/tracks/200500/shift
// - изменение слота PUT /api/v1/schedules/100100/versions/200300/days/200400/tracks/200500/slots/200600
export const scheduleApiPath = (eventId: number) => `${schedulesApiPath}/${eventId}`;
export const scheduleVersionsApiPath = (eventId: number) => `${scheduleApiPath(eventId)}/versions`;
export const scheduleVersionApiPath = (eventId: number, scheduleId: number) =>
    `${scheduleVersionsApiPath(eventId)}/${scheduleId}`;
export const scheduleVersionPublishApiPath = (eventId: number, scheduleId: number) =>
    `${scheduleVersionApiPath(eventId, scheduleId)}/publish`;
export const scheduleVersionCloneApiPath = (eventId: number, scheduleId: number) =>
    `${scheduleVersionApiPath(eventId, scheduleId)}/clone`;
export const scheduleVersionShiftApiPath = (eventId: number, scheduleId: number) =>
    `${scheduleVersionApiPath(eventId, scheduleId)}/shift`;
export const scheduleDayApiPath = (eventId: number, scheduleId: number, id?: number) =>
    `${scheduleVersionApiPath(eventId, scheduleId)}/days${id ? "/" : ""}${id ? id : ""}`;
export const scheduleDayShiftApiPath = (eventId: number, scheduleId: number, id: number) =>
    `${scheduleDayApiPath(eventId, scheduleId, id)}/shift`;
export const scheduleTrackApiPath = (eventId: number, scheduleId: number, dayId: number, id?: number) =>
    `${scheduleDayApiPath(eventId, scheduleId, dayId)}/tracks${id ? "/" : ""}${id ? id : ""}`;
export const scheduleTrackShiftApiPath = (eventId: number, scheduleId: number, dayId: number, id: number) =>
    `${scheduleTrackApiPath(eventId, scheduleId, dayId, id)}/shift`;
export const scheduleSlotApiPath = (eventId: number, scheduleId: number, dayId: number, trackId: number, id?: number) =>
    `${scheduleTrackApiPath(eventId, scheduleId, dayId, trackId)}/slots${id ? "/" : ""}${id ? id : ""}`;

export const schedulePlanningSectionsApiPath = (eventId: number) => `${scheduleApiPath(eventId)}/planning-sections`;
export const schedulePlanningSectionApiPath = (eventId: number, id: number) =>
    `${schedulePlanningSectionsApiPath(eventId)}/${id}`;
export const scheduleModeratorsApiPath = (eventId: number) => `${scheduleApiPath(eventId)}/moderators`;
export const scheduleModeratorApiPath = (eventId: number, id: number) => `${scheduleModeratorsApiPath(eventId)}/${id}`;

export const committeeParticipationsApiPath = (eventId: number) => `${committeeApiPath(eventId)}/participations`;
export const committeeParticipationApiPath = (eventId: number, id: number) =>
    `${committeeParticipationsApiPath(eventId)}/${id}`;
export const committeeParticipationBiographyApiPath = (eventId: number, id: number) =>
    `${committeeParticipationApiPath(eventId, id)}/bio`;
export const committeeParticipationBiographyPhotoApiPath = (eventId: number, id: number) =>
    `${committeeParticipationBiographyApiPath(eventId, id)}/photo`;

export const sendNotificationToParticipantActivityContentPublishedApiPath = `${notificationsApiPath}/activity-content-published`;

export const pipelineApiPathByNameAndTriggeredAndContext = (name: string, triggeredBy: number, context: string) =>
    `${pipelineApiPath}?name=${name}&triggered_by=${triggeredBy}&context=${context}`;
export const pipelineApiPathById = (id: string) => `${pipelineApiPath}/${id}`;
export const pipelineRestartApiPath = (id: string) => `${pipelineApiPathById(id)}/restart`;
export const pipelineStartApiPath = (id: string) => `${pipelineApiPathById(id)}/start`;

export const exchangeCopyApiPath = `${exchangeApiPath}/copy`;
