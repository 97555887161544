import axios from "axios";

import { getHostUrl } from "../../../environment";
import { HttpCodes } from "../../utils/http-codes";

const TENANT = "srm";

const baseURL = `${getHostUrl()}api/v2/auth`;

const api = axios.create({
    baseURL,
});

export const publicEndpointLogin = (returnPage?: string) =>
    `${baseURL}/login/${TENANT}/${returnPage ? `?redirect_uri=${returnPage}` : ``}`;

const privateEndpoints = {
    silentLogin: () => `login/${TENANT}?prompt=none`,
    logout: `logout?tenant=${TENANT}`,
    verify: `verify/${TENANT}`,
};

export class AuthApiService {
    public logout(): Promise<Response> {
        return api.get(privateEndpoints.logout);
    }

    public verify(): Promise<boolean> {
        return api
            .get(privateEndpoints.verify, { withCredentials: true })
            .then((response) => response.status !== HttpCodes.UNAUTHORIZED)
            .catch((error) => error.response.status !== HttpCodes.UNAUTHORIZED);
    }

    public silentLogin(): Promise<Response> {
        return api.get(privateEndpoints.silentLogin(), { withCredentials: true });
    }
}
