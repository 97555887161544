import { makeAutoObservable } from "mobx";

import { countMinutesBetweenDates } from "../../utils/time";
import { coreApiAction } from "./core.actions";
import { CoreApiEventEntity, EventPeriod, EventSeasonEntity } from "./models/events.models";

export class CoreStore {
    events?: CoreApiEventEntity[] | undefined | null = undefined;
    seasons: Map<number, EventSeasonEntity> = new Map<number, EventSeasonEntity>();
    currentEvent?: CoreApiEventEntity = undefined;

    private updated?: Date;

    constructor() {
        makeAutoObservable(this);
    }

    public setEventsAndSeasons(events: CoreApiEventEntity[] | null) {
        this.events = events;
        this.seasons = coreApiAction.calcSeasons(events);
        this.updated = new Date();
    }

    updateEvent(event: CoreApiEventEntity) {
        if (this.events?.length === 0) {
            return;
        }

        const events = coreApiAction
            .sortPeriodsListByDate([...this.events.filter((e) => e.id !== event.id), event])
            .filter(coreApiAction.filterOutPackEvents)
            .map(coreApiAction.transformEvent);

        this.setEventsAndSeasons(events);
    }

    public setCurrentEvent(event: CoreApiEventEntity | null) {
        this.currentEvent = event;
    }

    public setCurrentEventId(id: number) {
        if (this.events) {
            const event = this.events.find((e) => e.id === id);
            if (event) {
                this.setCurrentEvent(event);
            }
        }
    }

    get updatedAgeMin(): number | null {
        if (!this.updated) {
            return null;
        }
        return countMinutesBetweenDates(new Date(), this.updated);
    }

    get getSeasonsEventsMap(): Map<number, CoreApiEventEntity[]> {
        return coreApiAction.getSeasonsEventsMap(this.events);
    }

    get getSeasonsArray() {
        return Array.from(this.seasons.values()).sort(coreApiAction.sortSeasonByYearVersion);
    }

    get getCurrentEventSeasonPeriod(): EventPeriod | undefined {
        if (this.currentEvent?.season?.id) {
            return this.seasons.get(this.currentEvent?.season?.id)?.period;
        }
        return undefined;
    }

    get getCurrentSeason() {
        if (!this.getSeasonsArray) {
            return undefined;
        }

        return coreApiAction.findCurrentSeasons(this.getSeasonsArray);
    }

    get findCurrentEvent() {
        if (!this.events) {
            return null;
        }

        const currentSeason = this.getCurrentSeason;

        if (!currentSeason) {
            return null;
        }

        return this.getSeasonsEventsMap?.get(currentSeason.id)[0];
    }
}
