import { DispatchWithoutAction } from "react";

import { AccessToken } from "./access-token";
import { AuthApiService } from "./auth.api.service";
import { AuthStore } from "./auth.store";

export const NUMBER_OF_VERIFICATION_ATTEMPTS = 1;

class AuthService {
    private accessToken: AccessToken;
    private onSilentLogin?: DispatchWithoutAction;
    private onAnywaySilentLogin?: DispatchWithoutAction;

    private currentVerificationAttemptsCount = 0;

    constructor(private authApiService: AuthApiService, private readonly authStore: AuthStore) {}

    public zeroOutCurrentVerificationAttemptsCount(): void {
        this.currentVerificationAttemptsCount = 0;
    }

    public refreshAuthData(): Promise<boolean> {
        this.accessToken = AccessToken.receive();

        if (!this.accessToken.hasValue) {
            return this.tryVerify();
        } else {
            if (this.accessToken.isExpired) {
                return this.tryVerify();
            } else {
                this.authStore.updateFromAccessToken(this.accessToken);
                return Promise.resolve(true);
            }
        }
    }

    public logout(): Promise<Response> {
        return this.authApiService.logout();
    }

    public tryVerify(): Promise<boolean> {
        // disable
        // if (getEnvironment() === "local") {
        //     return Promise.resolve(true);
        // }

        if (this.currentVerificationAttemptsCount >= NUMBER_OF_VERIFICATION_ATTEMPTS) {
            return Promise.resolve(false);
        }

        this.currentVerificationAttemptsCount++;

        return this.authApiService
            .verify()
            .then((ok) => {
                if (!ok) {
                    return this.authApiService.silentLogin().then(() => {
                        if (AccessToken.receive().hasValue) {
                            this.onAnywaySilentLogin?.();
                        }
                        return false;
                    });
                }

                return Promise.resolve(true);
            })
            .then((silentLoginResult) => {
                this.accessToken = AccessToken.receive();
                this.authStore.updateFromAccessToken(this.accessToken);

                if (silentLoginResult) {
                    this.onSilentLogin?.();
                }

                return true;
            })
            .catch(() => {
                this.authStore.updateFromAccessToken(undefined);
                return false;
            });
    }
}

export default AuthService;
