// noinspection DuplicatedCode
import { DispatchWithoutAction } from "react";

import moment from "moment";

import { NotFoundError } from "../../utils/errors/NotFoundError";
import { RightsError } from "../../utils/errors/RightsError";
import { AuthStore } from "../auth/auth.store";
import { rightsActions } from "../common/rights.actions";
import { CoreService } from "../core-api/core.service";
import { activityActions } from "./models/activity/activity.actions";
import {
    ActivityBroadcastOptions,
    ActivityContentData,
    ActivityEntity,
    ActivitySupportOptions,
} from "./models/activity/activity.models";
import { CommitteeParticipationInviteEntity, CommunicationInviteType } from "./models/committee/committee.models";
import { commonActions } from "./models/common.actions";
import { Form, FormType } from "./models/common.models";
import { ExchangeCopyModel } from "./models/exchange.models";
import { GiftEntity } from "./models/gift/gift.model";
import { LocationEntity } from "./models/location/location.model";
import { participationActions } from "./models/participation/participation.actions";
import {
    ActivityParticipationTuple,
    BiographyEntity,
    ParticipantEntity,
    ParticipationConsent,
    ParticipationEntity,
    ParticipationInviteEntity,
} from "./models/participation/participation.models";
import { ProposalEntity, ProposalWorkflowStage } from "./models/proposal/proposal.models";
import { scheduleActions } from "./models/schedule/schedule.actions";
import {
    ScheduleDayEntity,
    ScheduleEntity,
    ScheduleModeratorEntity,
    ScheduleNewDayEntity,
    SchedulePlanningSectionEntity,
    ScheduleSlotEntity,
    ScheduleTemplate,
    ScheduleTrackEntity,
    ShiftModel,
} from "./models/schedule/schedule.models";
import { TravelEntity } from "./models/travel/travel.model";
import { SrmApiService } from "./srm.api.service";
import SrmStore from "./srm.store";

class SrmService {
    private readonly srmApiService: SrmApiService;

    constructor(private srmStore: SrmStore, private authStore: AuthStore, private coreService: CoreService) {
        this.srmApiService = new SrmApiService(false, authStore);
    }

    // noinspection JSUnusedLocalSymbols
    private reset() {
        this.srmStore.reset();
    }

    // load all proposals and return stage
    loadProposals(): Promise<void> {
        if (!this.authStore.isSrmUser && this.srmStore.proposalsNotLoaded) {
            this.srmStore.setProposals([]);
            return Promise.resolve();
        }

        // TODO set filters and paging
        return this.srmApiService
            .getProposals(100)
            .then((response) => {
                // remove non view proposals
                this.srmStore.setProposals(response);
            })
            .catch((error) => {
                this.srmStore.setProposals([]);
                throw error;
            });
    }

    // load all activities for event
    loadActivities(eventId: number, stages?: ProposalWorkflowStage[]): Promise<boolean> {
        return this.srmApiService
            .getActivitiesByEvent(eventId, stages)
            .then((response) => {
                this.srmStore.setActivities(response);
                return true;
            })
            .catch((error) => {
                if (error instanceof NotFoundError) {
                    this.srmStore.setActivities(null);
                }

                if (error instanceof RightsError) {
                    this.srmStore.setActivities(null);
                }

                throw error;
            });
    }

    // load one proposal
    loadProposal(id: number): Promise<void> {
        return (
            this.srmApiService
                .getProposal(id)
                .then((proposal) => {
                    this.srmStore.setCurrentProposal(proposal);
                    return proposal;
                })
                .catch((error) => {
                    if (error instanceof NotFoundError) {
                        this.srmStore.setCurrentProposal(null);
                        return null;
                    }

                    if (error instanceof RightsError) {
                        this.srmStore.setCurrentProposal(null);
                    }

                    throw error;
                })
                // load participations for activity
                .then((proposal) => {
                    if (
                        proposal?.activity?.id !== undefined &&
                        rightsActions.canNestedResource(proposal.activity, "participation", "query")
                    ) {
                        return this.loadParticipations(proposal.activity.id);
                    }
                })
        );
    }

    public loadParticipationAdditionalData(data: ActivityParticipationTuple[]): Promise<void> {
        const tasks = [];
        for (const tuple of data) {
            (rightsActions.canReadNestedResource(tuple.participation, "travel") ||
                rightsActions.canCreateNestedResource(tuple.participation, "travel")) &&
                tasks.push(this.loadParticipationTravel(tuple.activityId, tuple.participation.id));

            (rightsActions.canReadNestedResource(tuple.participation, "location") ||
                rightsActions.canCreateNestedResource(tuple.participation, "location")) &&
                tasks.push(this.loadParticipationLocation(tuple.activityId, tuple.participation.id));

            (rightsActions.canReadNestedResource(tuple.participation, "gift") ||
                rightsActions.canCreateNestedResource(tuple.participation, "gift")) &&
                tasks.push(this.loadParticipationGift(tuple.activityId, tuple.participation.id));
            // this.loadParticipationEquipment(ids.activityId, ids.participationId),
        }

        return Promise.all(tasks).then();
    }

    // put one proposal
    // noinspection JSUnusedLocalSymbols
    private saveWholeProposal(data: ProposalEntity) {
        return this.srmApiService.putProposal(data).then((response) => {
            this.srmStore.setCurrentProposal(response);
        });
    }

    getActivitiesParallel(ids: number[]) {
        const tasks = ids.map((id) => this.srmApiService.getActivity(id));
        return Promise.all(tasks);
    }

    getActivities(ids: number[]) {
        return this.srmApiService.getActivitiesByIds(ids);
    }

    getPublishedActivity(id: number) {
        return this.srmApiService.getActivity(id, {
            "X-Published": "true",
        });
    }

    // put whole activity
    // noinspection JSUnusedLocalSymbols
    private saveWholeActivity(data: ActivityEntity) {
        return this.srmApiService.putActivity(data).then((response) => {
            this.srmStore.setCurrentActivity(response);
        });
    }

    // put one activity
    saveActivityData(id: number, data: object, isContentDataEdit: boolean = false) {
        return this.srmApiService.putActivityData(id, data, isContentDataEdit).then((response) => {
            this.srmStore.setCurrentActivity(response);
        });
    }

    saveActivityDataForSchedule(id: number, data: ActivityContentData) {
        return this.srmApiService
            .putActivityData(
                id,
                {
                    content: {
                        data: activityActions.editAction.removeContentDataNotEditableFields(data),
                    },
                },
                true,
            )
            .then((response) => {
                this.srmStore.setActivity(response);
            });
    }

    saveActivityBroadcastDataAction(activityId: number, data: ActivityBroadcastOptions) {
        return this.srmApiService
            .putActivityData(activityId, {
                broadcastOptions: data,
            })
            .then((response) => {
                this.srmStore.setActivity(response);
            });
    }

    saveActivitySupportDataAction(activityId: number, data: ActivitySupportOptions) {
        return this.srmApiService
            .putActivityData(activityId, {
                supportOptions: data,
            })
            .then((response) => {
                this.srmStore.setActivity(response);
            });
    }

    // load all participations for activity
    private loadParticipations(activityId: number) {
        return this.srmApiService.getParticipations(activityId).then((response) => {
            this.srmStore.setCurrentParticipations(response);
        });
    }

    // search participants
    searchParticipants(searchString: string): Promise<ParticipantEntity[]> {
        return this.srmApiService.searchParticipants(searchString);
    }

    // save participation field
    saveParticipationData(activityId: number, participationId: number, data: ParticipationEntity) {
        return this.srmApiService
            .putParticipationData(
                activityId,
                participationId,
                participationActions.editAction.removeParticipationNotEditableFields(data),
            )
            .then((response) => {
                this.srmStore.updateCurrentParticipations(response);
            });
    }

    deleteParticipation(activityId: number, participationId: number) {
        return this.srmApiService.deleteParticipation(activityId, participationId).then(() => {
            this.srmStore.deleteParticipation(participationId);
        });
    }

    // add participation
    addParticipation(activityId: number, data: ParticipationInviteEntity) {
        return this.srmApiService.postParticipationData(activityId, data).then((response) => {
            this.srmStore.updateCurrentParticipations(response);
        });
    }

    // put one bio
    saveParticipationBiography(
        activityId: number,
        participationId: number,
        data: BiographyEntity,
        withPhoto?: boolean,
        callback?: DispatchWithoutAction,
    ) {
        if (data.status === "active") {
            return this.srmApiService
                .putParticipationBiography(
                    activityId,
                    participationId,
                    participationActions.editAction.removeBiographyNotEditableFields(data, withPhoto),
                )
                .then((response) => {
                    callback && callback();
                    this.srmStore.updateCurrentParticipationBiography(response);
                    return response;
                });
        } else if (data.status === "archived") {
            return this.srmApiService
                .postParticipationBiography(
                    activityId,
                    participationId,
                    participationActions.editAction.removeBiographyNotEditableFields(data, true),
                )
                .then((response) => {
                    callback && callback();
                    this.srmStore.updateCurrentParticipationBiography(response);
                    return response;
                });
        }

        return Promise.reject(undefined);
    }

    saveCommitteeParticipationBiography(
        eventId: number,
        participationId: number,
        data: BiographyEntity,
        withPhoto?: boolean,
    ) {
        if (data.status === "active") {
            return this.srmApiService
                .saveCommitteeParticipationBiography(
                    eventId,
                    participationId,
                    participationActions.editAction.removeBiographyNotEditableFields(data, withPhoto),
                )
                .then((response) => {
                    this.srmStore.updateCommitteeParticipationBiography(response);
                    return response;
                });
        } else if (data.status === "archived") {
            return this.srmApiService
                .addCommitteeParticipationBiography(
                    eventId,
                    participationId,
                    participationActions.editAction.removeBiographyNotEditableFields(data, true),
                )
                .then((response) => {
                    this.srmStore.updateCommitteeParticipationBiography(response);
                    return response;
                });
        }

        return Promise.reject(undefined);
    }

    // post new photo
    saveParticipationBiographyPhoto(
        activityId: number,
        participationId: number,
        form: FormData,
        data: BiographyEntity,
    ) {
        if (data.status === "active") {
            return this.srmApiService
                .postParticipationBiographyPhoto(activityId, participationId, form)
                .then((response) => {
                    this.srmStore.updateCurrentParticipationBiography(response);
                });
        } else if (data.status === "archived") {
            return this.saveParticipationBiography(activityId, participationId, data)
                .then(() => this.srmApiService.postParticipationBiographyPhoto(activityId, participationId, form))
                .then((response) => {
                    this.srmStore.updateCurrentParticipationBiography(response);
                });
        }

        return Promise.reject();
    }

    // post new photo
    saveCommitteeParticipationBiographyPhoto(
        eventId: number,
        participationId: number,
        form: FormData,
        data: BiographyEntity,
    ) {
        if (data.status === "active") {
            return this.srmApiService
                .addCommitteeParticipationBiographyPhoto(eventId, participationId, form)
                .then((response) => {
                    this.srmStore.updateCommitteeParticipationBiography(response);
                });
        } else if (data.status === "archived") {
            return this.saveCommitteeParticipationBiography(eventId, participationId, data)
                .then(() => this.srmApiService.addCommitteeParticipationBiographyPhoto(eventId, participationId, form))
                .then((response) => {
                    this.srmStore.updateCommitteeParticipationBiography(response);
                });
        }

        return Promise.reject();
    }

    public getParticipantArchivedBiographies(participantId: number) {
        return this.srmApiService
            .getParticipantBiographies(participantId)
            .then((response) => {
                return response.filter((b) => b.status === "archived");
            })
            .catch((error) => {
                if (error instanceof NotFoundError) {
                    return Promise.resolve([] as BiographyEntity[]);
                }

                throw error;
            });
    }

    public getParticipationPublishedBiography(activityId: number, participationId: number) {
        return this.srmApiService.getParticipationBiography(activityId, participationId, {
            "X-Published": "true",
        });
    }

    public getExtendedParticipantArchivedBiographies(bios: BiographyEntity[]) {
        return this.coreService.getEvents().then((events) => {
            return participationActions.mergeParticipantBiographiesWithEvents(bios, events);
        });
    }

    // get location
    private loadParticipationLocation(activityId: number, participationId: number): Promise<void> {
        return this.srmApiService
            .getParticipationLocation(activityId, participationId)
            .then((response) => {
                if (response) {
                    this.srmStore.setCurrentLocation(participationId, response);
                }
            })
            .catch((error) => {
                if (error instanceof NotFoundError) {
                    this.srmStore.setCurrentLocation(participationId, null);
                    return;
                }

                throw error;
            });
    }

    private loadParticipationGift(activityId: number, participationId: number): Promise<void> {
        return this.srmApiService
            .getParticipationGift(activityId, participationId)
            .then((response) => {
                if (response) {
                    this.srmStore.setCurrentGift(participationId, response);
                }
            })
            .catch((error) => {
                if (error instanceof NotFoundError) {
                    this.srmStore.setCurrentGift(participationId, null);
                    return;
                }

                throw error;
            });
    }

    // save gift
    saveParticipationGift(activityId: number, participationId: number, data: GiftEntity): Promise<void> {
        let callback;
        const currentParticipationsGifts = this.srmStore.currentParticipationsGifts.get(participationId);
        if (currentParticipationsGifts === undefined || currentParticipationsGifts == null) {
            callback = this.srmApiService.postParticipationGift.bind(this.srmApiService);
        } else {
            callback = this.srmApiService.putParticipationGift.bind(this.srmApiService);
        }

        return callback(
            activityId,
            participationId,
            participationActions.editAction.removeNotEditableFields(data),
        ).then((response) => {
            if (response) {
                this.srmStore.setCurrentGift(participationId, response);
            }
        });
    }

    // save location
    saveParticipationLocation(activityId: number, participationId: number, data: LocationEntity): Promise<void> {
        let callback;
        const currentParticipationsLocations = this.srmStore.currentParticipationsLocations.get(participationId);
        if (currentParticipationsLocations === undefined || currentParticipationsLocations == null) {
            callback = this.srmApiService.postParticipationLocation.bind(this.srmApiService);
        } else {
            callback = this.srmApiService.putParticipationLocation.bind(this.srmApiService);
        }

        return callback(
            activityId,
            participationId,
            participationActions.editAction.removeNotEditableFields(data),
        ).then((response) => {
            if (response) {
                this.srmStore.setCurrentLocation(participationId, response);
            }
        });
    }

    // get travel
    private loadParticipationTravel(activityId: number, participationId: number): Promise<void> {
        return this.srmApiService
            .getParticipationTravel(activityId, participationId)
            .then((response) => {
                if (response) {
                    this.srmStore.setCurrentTravel(participationId, response);
                }
            })
            .catch((error) => {
                if (error instanceof NotFoundError) {
                    this.srmStore.setCurrentTravel(participationId, null);
                    return;
                }

                throw error;
            });
    }

    // save travel
    saveParticipationTravel(activityId: number, participationId: number, data: TravelEntity): Promise<void> {
        let callback;
        const currentParticipationsTravels = this.srmStore.currentParticipationsTravels.get(participationId);
        if (currentParticipationsTravels === undefined || currentParticipationsTravels == null) {
            callback = this.srmApiService.postParticipationTravel.bind(this.srmApiService);
        } else {
            callback = this.srmApiService.putParticipationTravel.bind(this.srmApiService);
        }

        return callback(
            activityId,
            participationId,
            participationActions.editAction.removeNotEditableFields(data),
        ).then((response) => {
            if (response) {
                this.srmStore.setCurrentTravel(participationId, response);
            }
        });
    }

    // get equipment
    // private loadParticipationEquipment(activityId: number, participationId: number): Promise<void> {
    //     return this.srmApiService.getParticipationEquipment(activityId, participationId)
    //         .then(response => {
    //             this.srmStore.setCurrentEquipment(participationId, response);
    //         })
    //         .catch(error => {
    //             if (error instanceof NotFoundError) {
    //                 this.srmStore.setCurrentEquipment(participationId, null);
    //                 return;
    //             }
    //
    //             throw error;
    //         });
    // }

    // save equipment
    // saveParticipationEquipment(activityId: number, participationId: number, data: EquipmentEntity): Promise<void> {
    //     let callback;
    //     if (this.srmStore.currentParticipationsEquipments === undefined || this.srmStore.currentParticipationsEquipments == null) {
    //         callback = this.srmApiService.postParticipationEquipment.bind(this.srmApiService);
    //     } else {
    //         callback = this.srmApiService.putParticipationEquipment.bind(this.srmApiService);
    //     }
    //
    //     return callback(activityId, participationId, participationActions.editAction.removeNotEditableFields(data))
    //         .then(response => {
    //             this.srmStore.setCurrentEquipment(participationId, response);
    //         });
    // }

    saveActivityPresentation(activityId: number, form: FormData) {
        return this.srmApiService.postActivityPresentation(activityId, form).then((response) => {
            this.srmStore.setCurrentActivity(response);
        });
    }

    loadSchedulePublishedVersion(eventId: number): Promise<boolean> {
        return this.srmApiService
            .getSchedulePublishedVersion(eventId)
            .then((version) => {
                this.srmStore.setScheduleVersions([]);
                this.srmStore.setScheduleVersion(version);
                return true;
            })
            .catch((error) => {
                this.srmStore.setScheduleVersions(null);
                this.srmStore.setScheduleVersion(null);

                if (error instanceof NotFoundError) {
                    return false;
                }

                throw error;
            });
    }

    getSchedulePublishedVersion(eventId: number): Promise<ScheduleEntity> {
        return this.srmApiService.getSchedulePublishedVersion(eventId);
    }

    getScheduleVersion(eventId: number, scheduleId: number) {
        return this.srmApiService.getScheduleVersion(eventId, scheduleId);
    }

    // get published version and set it
    loadSchedulePublishedForEditVersion(eventId: number): Promise<boolean> {
        return this.srmApiService
            .getSchedulePublishedVersion(eventId)
            .then((version) => {
                this.srmStore.setScheduleVersions([]);
                if (version && version.createdFromId) {
                    return this.loadScheduleVersion(version.eventId, version.createdFromId);
                }
                this.srmStore.setScheduleVersion(null);
            })
            .catch((error) => {
                this.srmStore.setScheduleVersions(null);
                this.srmStore.setScheduleVersion(null);

                if (error instanceof NotFoundError) {
                    return false;
                }

                throw error;
            });
    }

    loadScheduleVersions(eventId: number): Promise<boolean> {
        return this.srmApiService
            .getScheduleVersions(eventId)
            .then((versions) => {
                const sortedVersions = versions.sort(commonActions.scheduleActions.comparatorByDate);
                this.srmStore.setScheduleVersions(sortedVersions);

                if (sortedVersions.length > 0) {
                    // find last published versions
                    const publishedSchedule = sortedVersions.find((v) => v.status === "published");
                    if (publishedSchedule) {
                        return this.loadScheduleVersion(publishedSchedule.eventId, publishedSchedule.id);
                    }
                    return this.loadScheduleVersion(sortedVersions[0].eventId, sortedVersions[0].id);
                }
                this.srmStore.setScheduleVersions([]);
                this.srmStore.setScheduleVersion(null);
                return false;
            })
            .catch((error) => {
                this.srmStore.setScheduleVersions(null);
                this.srmStore.setScheduleVersion(null);

                if (error instanceof NotFoundError) {
                    return false;
                }

                throw error;
            });
    }

    loadScheduleVersion(eventId: number, scheduleId: number): Promise<boolean> {
        return this.srmApiService
            .getScheduleVersion(eventId, scheduleId)
            .then((version) => {
                this.srmStore.setScheduleVersion(version);
                return true;
            })
            .catch((error) => {
                if (error instanceof NotFoundError) {
                    return false;
                }

                throw error;
            });
    }

    saveNewScheduleVersion(eventId: number, template: ScheduleTemplate) {
        return this.srmApiService
            .postScheduleVersion(eventId, template)
            .then((version) => this.srmStore.setScheduleVersion(version))
            .then(() => this.srmApiService.getScheduleVersions(eventId))
            .then((versions) => {
                const sortedVersions = versions.sort(commonActions.scheduleActions.comparatorByDate);
                this.srmStore.setScheduleVersions(sortedVersions);
                return true;
            })
            .catch((error) => {
                this.srmStore.setScheduleVersions(null);
                this.srmStore.setScheduleVersion(null);

                throw error;
            });
    }

    publishScheduleVersion(eventId: number, scheduleId: number) {
        return this.srmApiService
            .publishScheduleVersion(eventId, scheduleId)
            .then((versions) => {
                const sortedVersions = versions.sort(commonActions.scheduleActions.comparatorByDate);
                this.srmStore.setScheduleVersions(sortedVersions);

                if (sortedVersions.length > 0) {
                    if (this.srmStore.currentScheduleVersion) {
                        const version = sortedVersions.find((v) => v.id === this.srmStore.currentScheduleVersion.id);
                        if (version) {
                            this.srmStore.setScheduleVersion({
                                ...this.srmStore.currentScheduleVersion,
                                status: version.status,
                                type: version.type,
                            } as ScheduleEntity);
                        }
                    }
                    return true;
                }
                this.srmStore.setScheduleVersions([]);
                return false;
            })
            .catch((error) => {
                this.srmStore.setScheduleVersions(null);

                if (error instanceof NotFoundError) {
                    return false;
                }

                throw error;
            });
    }

    cloneScheduleVersion(eventId: number, scheduleId: number) {
        return this.srmApiService
            .cloneScheduleVersion(eventId, scheduleId)
            .then((version) => this.srmStore.setScheduleVersion(version))
            .then(() => this.srmApiService.getScheduleVersions(eventId))
            .then((versions) => {
                const sortedVersions = versions.sort(commonActions.scheduleActions.comparatorByDate);
                this.srmStore.setScheduleVersions(sortedVersions);
                return true;
            })
            .catch((error) => {
                this.srmStore.setScheduleVersions(null);

                throw error;
            });
    }

    deleteScheduleVersion(eventId: number, scheduleId: number) {
        return this.srmApiService.deleteScheduleVersion(eventId, scheduleId).then(() => {
            this.srmStore.deleteScheduleVersions(scheduleId);
            return true;
        });
    }

    //
    // saveScheduleVersion(eventId: number, scheduleId: number) {
    //
    // }

    addScheduleTrack(eventId: number, scheduleId: number, data: ScheduleTrackEntity) {
        return this.srmApiService
            .postScheduleTrack(
                eventId,
                scheduleId,
                data.dayId,
                scheduleActions.editActions.removeTrackNotEditableFields(data),
            )
            .then((track) => {
                this.srmStore.setScheduleTrack(track);
            });
    }

    editScheduleTrack(eventId: number, scheduleId: number, data: ScheduleTrackEntity) {
        return this.srmApiService
            .putScheduleTrack(
                eventId,
                scheduleId,
                data.dayId,
                data.id,
                scheduleActions.editActions.removeTrackNotEditableFields(data),
            )
            .then((track) => {
                this.srmStore.setScheduleTrack(track);
            });
    }

    deleteScheduleTrack(eventId: number, scheduleId: number, dayId: number, trackId: number) {
        return this.srmApiService
            .deleteScheduleTrack(eventId, scheduleId, dayId, trackId)
            .then(() => this.loadScheduleVersion(eventId, scheduleId));
    }

    editScheduleSlot(eventId: number, data: ScheduleSlotEntity) {
        return this.srmApiService
            .putScheduleSlot(
                eventId,
                data.scheduleId,
                data.dayId,
                data.trackId,
                data.id,
                scheduleActions.editActions.removeSlotNotEditableFields(data),
            )
            .then((slot) => {
                this.srmStore.setScheduleSlot(slot);

                return true;
            });
    }

    addScheduleSlotNew(
        eventId: number,
        scheduleId: number,
        activityId: number,
        dayId: number,
        trackId?: number,
    ): Promise<boolean> {
        let newSlot: ScheduleSlotEntity;
        if (!dayId && !trackId) {
            return Promise.resolve(false);
        }
        // if place not exist
        if (dayId && !trackId) {
            const day = this.srmStore.currentScheduleVersion?.days?.find((d) => d.id === dayId);
            if (!day) {
                return Promise.resolve(false);
            }
            const track = day.tracks[0];
            // TODO add new track if tracks ===0
            if (!track) {
                return Promise.resolve(false);
            }

            let startTime: Date = (track.slots ?? []).sort(scheduleActions.sortSlotsListByDate)[0]?.startTime;
            let endTime: Date;

            if (!startTime) {
                startTime = moment(day.startDay).add(10, "hour").toDate();
                endTime = moment(startTime).add(30, "minutes").toDate();
            } else {
                endTime = startTime;
                startTime = moment(startTime).add(-30, "minutes").toDate();
            }

            newSlot = {
                scheduleId,
                dayId: day.id,
                trackId: track.id,
                trackNumber: track.trackNumber,
                activityId,
                startTime,
                endTime,
            };
        }

        return this.srmApiService
            .postScheduleSlot(
                eventId,
                scheduleId,
                newSlot.dayId,
                newSlot.trackId,
                scheduleActions.editActions.removeSlotNotEditableFields(newSlot),
            )
            .then((slot) => {
                this.srmStore.addScheduleSlot(slot);
                return true;
            });
    }

    addScheduleSlot(eventId: number, scheduleId: number, newSlot: ScheduleSlotEntity): Promise<boolean> {
        if (newSlot.id) {
            return this.srmApiService
                .putScheduleSlot(
                    eventId,
                    scheduleId,
                    newSlot.dayId,
                    newSlot.trackId,
                    newSlot.id,
                    scheduleActions.editActions.removeSlotNotEditableFields(newSlot),
                )
                .then((slot) => {
                    this.srmStore.addScheduleSlot(slot);
                    return true;
                });
        }

        return this.srmApiService
            .postScheduleSlot(
                eventId,
                scheduleId,
                newSlot.dayId,
                newSlot.trackId,
                scheduleActions.editActions.removeSlotNotEditableFields(newSlot),
            )
            .then((slot) => {
                this.srmStore.addScheduleSlot(slot);
                return true;
            });
    }

    deleteScheduleSlot(eventId: number, scheduleId: number, dayId: number, trackId: number, slotId: number) {
        return this.srmApiService.deleteScheduleSlot(eventId, scheduleId, dayId, trackId, slotId).then(() => {
            this.srmStore.deleteScheduleSlot(dayId, trackId, slotId);
        });
    }

    cloneSlot(eventId: number, data: ScheduleSlotEntity) {
        const { dayId, trackId, scheduleId } = data;

        if (!dayId || !trackId || !scheduleId) {
            return Promise.resolve(false);
        }

        let newSlot: ScheduleSlotEntity;

        const day = this.srmStore.currentScheduleVersion?.days?.find((d) => d.id === dayId);
        const track = day?.tracks?.find((t) => t.id === trackId);

        if (!track) {
            return Promise.resolve(false);
        }

        let startTime: Date = (track.slots ?? []).sort(scheduleActions.sortSlotsListByDate)[0]?.startTime;
        let endTime: Date;
        const duration = moment.duration(moment(data.endTime).diff(moment(data.startTime))).asMinutes();

        if (!startTime) {
            startTime = moment(day.startDay).add(10, "hour").toDate();
            endTime = moment(startTime).add(duration, "minutes").toDate();
        } else {
            endTime = startTime;
            startTime = moment(startTime)
                .add(duration * -1, "minutes")
                .toDate();
        }

        newSlot = {
            ...data,
            id: undefined,
            startTime,
            endTime,
        };

        return this.srmApiService
            .postScheduleSlot(
                eventId,
                scheduleId,
                newSlot.dayId,
                newSlot.trackId,
                scheduleActions.editActions.removeSlotNotEditableFields(newSlot),
            )
            .then((slot) => {
                this.srmStore.addScheduleSlot(slot);
                return true;
            });
    }

    shiftTrack(eventId: number, changeTrack: ScheduleTrackEntity, data: ShiftModel) {
        return this.srmApiService
            .shiftScheduleTrack(eventId, changeTrack.scheduleId, changeTrack.dayId, changeTrack.id, data)
            .then(() => {
                return this.loadScheduleVersion(eventId, changeTrack.scheduleId);
            });
    }

    shiftDay(eventId: number, changeDay: ScheduleDayEntity, data: ShiftModel) {
        return this.srmApiService.shiftScheduleDay(eventId, changeDay.scheduleId, changeDay.id, data).then(() => {
            return this.loadScheduleVersion(eventId, changeDay.scheduleId);
        });
    }

    addScheduleDay(eventId: number, scheduleId: number, data: ScheduleNewDayEntity) {
        return this.srmApiService.postScheduleDay(eventId, scheduleId, data).then(() => {
            return this.loadScheduleVersion(eventId, scheduleId);
        });
    }

    deleteScheduleDay(eventId: number, scheduleId: number, id: number) {
        return this.srmApiService.deleteScheduleDay(eventId, scheduleId, id).then(() => {
            return this.loadScheduleVersion(eventId, scheduleId);
        });
    }

    changeScheduleDay(eventId: number, scheduleId: number, id: number, data: ScheduleNewDayEntity) {
        return this.srmApiService.putScheduleDay(eventId, scheduleId, id, data).then(() => {
            return this.loadScheduleVersion(eventId, scheduleId);
        });
    }

    shiftSchedule(eventId: number, scheduleId: number, data: ShiftModel) {
        return this.srmApiService
            .shiftSchedule(eventId, scheduleId, data)
            .then((version) => this.srmStore.setScheduleVersion(version));
    }

    // planning sections
    loadSchedulePlanningSections(eventId: number) {
        return this.srmApiService
            .getSchedulePlanningSections(eventId)
            .then((sections) => this.srmStore.setSchedulePlanningSections(sections));
    }

    editSchedulePlanningSection(eventId: number, data: SchedulePlanningSectionEntity) {
        return this.srmApiService.putSchedulePlanningSection(eventId, data.id, data).then((section) => {
            this.srmStore.setSchedulePlanningSection(section);
            return true;
        });
    }

    addSchedulePlanningSection(eventId: number) {
        return this.srmApiService.postSchedulePlanningSection(eventId).then((section) => {
            this.srmStore.setSchedulePlanningSection(section);
            return true;
        });
    }

    deleteSchedulePlanningSection(eventId: number, id: number) {
        return this.srmApiService.deleteSchedulePlanningSection(eventId, id).then(() => {
            this.srmStore.deleteSchedulePlanningSection(id);
            return true;
        });
    }

    // moderators
    loadScheduleModerators(eventId: number) {
        return this.srmApiService
            .getScheduleModerators(eventId)
            .then((moderators) => this.srmStore.setScheduleModerators(moderators));
    }

    editScheduleModerator(eventId: number, data: ScheduleModeratorEntity) {
        return this.srmApiService.putScheduleModerator(eventId, data.id, data).then((moderator) => {
            this.srmStore.setScheduleModerator(moderator);
            return true;
        });
    }

    addScheduleModerator(eventId: number) {
        return this.srmApiService.postScheduleModerator(eventId, {}).then((moderator) => {
            this.srmStore.setScheduleModerator(moderator);
            return true;
        });
    }

    deleteScheduleModerator(eventId: number, id: number) {
        return this.srmApiService.deleteScheduleModerator(eventId, id).then(() => {
            this.srmStore.deleteScheduleModerator(id);
            return true;
        });
    }

    // Committee
    loadCommittee(eventId: number) {
        return this.srmApiService
            .getCommittee(eventId)
            .then((committee) => {
                if (committee) {
                    this.srmStore.setCommittee(committee);
                    if (rightsActions.canNestedResource(committee, "participation", "query")) {
                        return;
                    }
                    throw new RightsError("There no rights for reading participations");
                }
                throw new Error("Committee not loaded");
            })
            .then(() => this.srmApiService.getCommitteeParticipations(eventId))
            .then((team) => {
                this.srmStore.setCommitteeParticipations(team);
                return true;
            })
            .catch((error) => {
                if (error instanceof NotFoundError) {
                    this.srmStore.setCommittee(null);
                    this.srmStore.setCommitteeParticipations([]);
                    return false;
                }
                this.srmStore.setCommittee(null);
                this.srmStore.setCommitteeParticipations(null);

                throw error;
            });
    }

    addCommitteeParticipation(eventId: number, data: CommitteeParticipationInviteEntity) {
        return this.srmApiService.addCommitteeParticipation(eventId, data).then((response) => {
            this.srmStore.updateCommitteeParticipation(response);
        });
    }

    deleteCommitteeParticipation(eventId: number, id: number) {
        return this.srmApiService.deleteCommitteeParticipation(eventId, id).then(() => {
            this.srmStore.deleteCommitteeParticipation(id);
        });
    }

    saveCommitteeCommunicationInviteType(eventId: number, type: CommunicationInviteType) {
        return this.srmApiService.saveCommitteeCommunicationInviteType(eventId, type).then((committee) => {
            if (committee) {
                this.srmStore.setCommittee(committee);
            }
        });
    }

    sendNotificationToParticipantActivityContentPublished(activityId: number, participationId: number) {
        return this.srmApiService
            .sendNotificationToParticipantActivityContentPublished(participationId)
            .then(() => this.srmApiService.getParticipation(activityId, participationId))
            .then((participation) => {
                this.srmStore.updateActivityListParticipation(activityId, participation);
            });
    }

    saveParticipationConsent(
        activityId: number,
        participationId: number,
        accepted: boolean,
        initData: ParticipationConsent | undefined,
        type: FormType,
    ) {
        const newData: ParticipationConsent = {
            ...(initData || {}),
            forms: [
                ...(initData?.forms ?? []).filter((a) => a.type !== type),
                {
                    type,
                    accepted,
                },
            ],
        };

        return this.srmApiService
            .putParticipationConsent(activityId, participationId, newData)
            .then((consent) => this.srmStore.updateCurrentParticipationConsent(consent));
    }

    getParticipant(participantId: number) {
        return this.srmApiService.getParticipant(participantId);
    }

    sendSpeakerRoomCreateEvent(proposalId: number, forced: boolean) {
        return this.srmApiService
            .sendSpeakerRoomCreatePromise(proposalId, forced)
            .then((aggregate) => this.srmStore.updateActivityListActivity(aggregate));
    }

    getUserInfo() {
        return this.srmApiService.getUserInfo().then((info) => this.authStore.setUserInfo(info));
    }

    getProposalCopyPipelines(activityId?: number) {
        if (!this.authStore.userId) {
            return null;
        }
        if (!activityId) {
            return null;
        }

        const context = `activity-${activityId}`;

        return this.srmApiService.getPipelinesByNameAndTriggeredAndContext(
            "exchange_importer_pipeline",
            this.authStore.userId,
            context,
        );
    }

    getPipeline(pipelineId: string) {
        return this.srmApiService.getPipeline(pipelineId);
    }

    startProposalCopy(activityId: number, eventId: number, forms: Form[]) {
        const data: ExchangeCopyModel = {
            options: {
                consentForms: forms.filter((f) => f.type === "content_publishing" && f.accepted !== undefined),
                importStrategy: "always_copy",
                notificationForms: forms.filter(
                    (f) =>
                        (f.type === "proposal_status_notification" ||
                            f.type === "working_out_notification" ||
                            f.type === "welcome_notification") &&
                        f.accepted !== undefined,
                ),
            },
            source: { objectIds: [activityId], objectType: "activity" },
            target: { eventId },
        };
        return this.srmApiService.postProposalCopy(data);
    }

    restartPipeline(pipelineId: string) {
        return this.srmApiService.postPipelineRestart(pipelineId);
    }

    startPipeline(pipelineId: string) {
        return this.srmApiService.postPipelineStart(pipelineId);
    }

    addToCommunicationChannel(eventId: number, proposalId: number) {
        return this.srmApiService.putCommunicationChannel(eventId, proposalId);
    }
}

export default SrmService;
