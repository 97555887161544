import { getSquidexUrl } from "../../../../../environment";
import { activityActions } from "../../../../data/srm/models/activity/activity.actions";
import { ActivityProposalParticipationsAggregate } from "../../../../data/srm/models/activity/activity.models";
import {
    COMMON_FILTER_SCOPE_KEY_DEFAULT,
    COMMON_FILTER_TYPE_KEY_DEFAULT,
} from "../../../common/Components/Filter/filters.const";
import {
    FILTER_LOGIC_VERSION,
    FilterData,
    FilterPanelData,
    FilterPanelInitData,
    SetFilterDispatchEffector,
    SetFilterDispatchObject,
} from "../../../common/Components/Filter/useFilterData";

export const createSquidexUrlToBiography = (biographyId: string | number): string =>
    `${getSquidexUrl()}app/srm/content/srm-biography/${biographyId}/history`;

export const createSquidexUrlToActivity = (activityId: string | number): string =>
    `${getSquidexUrl()}app/srm/content/srm-activity/${activityId}/history`;

export const filterActivitiesByText = (
    activities: ActivityProposalParticipationsAggregate[],
    text?: string,
): ActivityProposalParticipationsAggregate[] => {
    if (!text) {
        return activities;
    }

    const t = text.toLowerCase();
    return activities.filter((a) => activityActions.filterActivitiesByTextFn(a, t));
};

export const ACTIVITIES_FILTER_ONLY_PRODUCTION_STAGE_KEY = "page.publications.filter.stage";
export const ACTIVITIES_FILTER_ONLY_PRODUCTION_STAGE_KEY_NEGATIVE = "page.publications.filter.stage.negative";
export const ACTIVITIES_FILTER_PROPOSAL_RESERVED_KEY = "page.publications.filter.reserved";

export const ACTIVITIES_FILTER_ONLY_PRODUCTION_STAGE_KEY_DEFAULT: FilterData = {
    key: ACTIVITIES_FILTER_ONLY_PRODUCTION_STAGE_KEY,
    label: ACTIVITIES_FILTER_ONLY_PRODUCTION_STAGE_KEY,
    negativeLabel: ACTIVITIES_FILTER_ONLY_PRODUCTION_STAGE_KEY_NEGATIVE,
    data: true,
    type: "boolean",
    order: 0,
};

export const ACTIVITIES_FILTER_PROPOSAL_RESERVED_KEY_DEFAULT: FilterData = {
    key: ACTIVITIES_FILTER_PROPOSAL_RESERVED_KEY,
    label: ACTIVITIES_FILTER_PROPOSAL_RESERVED_KEY,
    negativeLabel: ACTIVITIES_FILTER_PROPOSAL_RESERVED_KEY,
    data: false,
    type: "boolean",
    order: 1,
};

export const ACTIVITIES_FILTER_ONLY_PRODUCTION_STAGE_KEY_FILTER = (f: FilterData) =>
    f.key === ACTIVITIES_FILTER_ONLY_PRODUCTION_STAGE_KEY;
export const ACTIVITIES_FILTER_PROPOSAL_RESERVED_KEY_FILTER = (f: FilterData) =>
    f.key === ACTIVITIES_FILTER_PROPOSAL_RESERVED_KEY;

export const ActivitiesFiltersDefaults: FilterPanelInitData = {
    filters: [
        ACTIVITIES_FILTER_ONLY_PRODUCTION_STAGE_KEY_DEFAULT,
        ACTIVITIES_FILTER_PROPOSAL_RESERVED_KEY_DEFAULT,
        COMMON_FILTER_TYPE_KEY_DEFAULT,
        COMMON_FILTER_SCOPE_KEY_DEFAULT,
    ],
    version: FILTER_LOGIC_VERSION,
};

export const ActivitiesForRatingsFiltersDefaults: FilterPanelInitData = {
    filters: [
        COMMON_FILTER_TYPE_KEY_DEFAULT,
        COMMON_FILTER_SCOPE_KEY_DEFAULT,
    ],
    version: FILTER_LOGIC_VERSION,
};

export const filterComplementEffect: SetFilterDispatchEffector = (
    filtersData: FilterPanelData,
    newFitersData: SetFilterDispatchObject[],
): SetFilterDispatchObject[] => {
    const filterByReservedStage = filtersData.filters.find(ACTIVITIES_FILTER_PROPOSAL_RESERVED_KEY_FILTER)?.data;
    const filterByProductionStage = filtersData.filters.find(ACTIVITIES_FILTER_ONLY_PRODUCTION_STAGE_KEY_FILTER)?.data;

    const result: SetFilterDispatchObject[] = [];

    for (const newFiterData of newFitersData) {
        if (
            newFiterData.key === ACTIVITIES_FILTER_ONLY_PRODUCTION_STAGE_KEY &&
            (newFiterData.value || newFiterData.value === undefined) &&
            filterByReservedStage
        ) {
            result.push({ key: ACTIVITIES_FILTER_PROPOSAL_RESERVED_KEY, value: false });
        } else if (
            newFiterData.key === ACTIVITIES_FILTER_PROPOSAL_RESERVED_KEY &&
            newFiterData.value &&
            filterByProductionStage
        ) {
            result.push({ key: ACTIVITIES_FILTER_ONLY_PRODUCTION_STAGE_KEY, value: false });
        }
    }

    return result;
};
