import Cookies from "js-cookie";

import { isLocalEnvironment } from "../../../environment";
import { getAccessTokenFixtureFromLocalStorage } from "../../mocks/access-token.fixture";
import { parseJwt } from "../../utils/jwt-parse";
import { countHoursBetweenDatesMoreThen } from "../../utils/time";

const ONLINE_SESSION_ACCESS_TOKEN_KEY = "online.session.access_token";
const NUMBER_HOURS_BEFORE_EXPIRED = 12;

export type AccessTokenValue = {
    sub: string;
    user_name: string;
    displayName: string;
    scope: string[];
    exp: number;
    lang: "ru" | "en";
    authorities: string[];
    jti: string;
    email: string;
    client_id: string;
    lk_user_id: number;
    lk_session_id: number;
    picture?: string;
};

export class AccessToken {
    public readonly value: AccessTokenValue | null;
    public readonly raw?: string;

    public get hasValue(): boolean {
        return this.value != null;
    }

    public get isExpired(): boolean {
        const exp = this.value?.exp;
        const expiredDate = new Date((exp || 0) * 1000);

        const now = new Date();

        return !countHoursBetweenDatesMoreThen(expiredDate, now, NUMBER_HOURS_BEFORE_EXPIRED);
    }

    public static readonly ONLINE_SESSION_ACCESS_TOKEN_KEY = ONLINE_SESSION_ACCESS_TOKEN_KEY;

    constructor(accessTokenString?: string) {
        this.value = AccessToken.createAccessTokenFromString(accessTokenString);
        this.raw = accessTokenString;
    }

    public static receive(): AccessToken {
        const accessTokenString = isLocalEnvironment
            ? getAccessTokenFixtureFromLocalStorage()
            : AccessToken.getAccessTokenStringFromCookie(AccessToken.ONLINE_SESSION_ACCESS_TOKEN_KEY);

        return new AccessToken(accessTokenString);
    }

    public static empty(): AccessToken {
        return new AccessToken();
    }

    private static createAccessTokenFromString(accessTokenString?: string): AccessTokenValue {
        return accessTokenString ? parseJwt<AccessTokenValue>(accessTokenString) : null;
    }

    private static getAccessTokenStringFromCookie = (accessTokenKey: string): string => {
        return Cookies.get(accessTokenKey) ?? "";
    };
}
