import { SaveAnalyticsService } from "@analytics-ring/analytics-ring-collector-client";

export const saveAnalytic = (
    analyticServicePromise: Promise<SaveAnalyticsService | undefined> | undefined,
    action: string,
    lkid?: string,
    utm_source?: string,
    utm_medium?: string,
    utm_term?: string,
    utm_content?: string,
) => {
    const isClientSide = typeof window !== "undefined";

    if (isClientSide && analyticServicePromise) {
        setTimeout(
            () =>
                analyticServicePromise?.then((service) =>
                    service?.save({
                        action,
                        lkid,
                        utm_source,
                        utm_medium,
                        utm_term,
                        utm_content,
                    }),
                ),
            100,
        );
    }
};
