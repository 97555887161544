import { EventAnalyticsAggregate, TagAnalyticsAggregate } from "../../../../data/mdm/models/analytics.models";
import { activityActions } from "../../../../data/srm/models/activity/activity.actions";
import {
    COMMON_FILTER_SCOPE_KEY_DEFAULT,
    COMMON_FILTER_SCOPE_KEY_FILTER,
    COMMON_FILTER_TEXT_KEY_FILTER,
    COMMON_FILTER_TYPE_KEY_DEFAULT,
    COMMON_FILTER_TYPE_KEY_FILTER,
} from "../../../common/Components/Filter/filters.const";
import {
    FILTER_LOGIC_VERSION,
    FilterData,
    FilterPanelData,
    FilterPanelInitData,
} from "../../../common/Components/Filter/useFilterData";

export const EVENT_REPORT_FILTER_FORMAT_KEY = "page.eventReport.filter.format";
export type EVENT_REPORT_FILTER_FORMAT_KEY_TYPE = "offline" | "online";

const EVENT_REPORT_FILTER_FORMAT_KEY_FILTER = (f: FilterData) => f.key === EVENT_REPORT_FILTER_FORMAT_KEY;

export const EVENT_REPORT_FILTER_FORMAT_KEY_DEFAULT: FilterData = {
    key: EVENT_REPORT_FILTER_FORMAT_KEY,
    label: EVENT_REPORT_FILTER_FORMAT_KEY,
    data: [],
    type: "text",
    order: 0,
    noteId: "page.eventReport.filter.format.note",
};

export const EventReportFiltersDefaults: FilterPanelInitData = {
    filters: [COMMON_FILTER_SCOPE_KEY_DEFAULT, COMMON_FILTER_TYPE_KEY_DEFAULT, EVENT_REPORT_FILTER_FORMAT_KEY_DEFAULT],
    version: FILTER_LOGIC_VERSION,
};

const filterActivitiesByText = (view?: EventAnalyticsAggregate, text?: string): EventAnalyticsAggregate => {
    if (!text) {
        return view;
    }

    const t = text.toLowerCase();

    return {
        ...view,
        talksAnalyticsAggregates: view.talksAnalyticsAggregates.filter((a) =>
            activityActions.filterActivitiesByTextFn(a, t),
        ),
        tagsAnalyticsAggregates: view.tagsAnalyticsAggregates
            ?.map((a) => {
                const tagValue: TagAnalyticsAggregate = {
                    ...a,
                    talksStatistics: a.talksStatistics.filter((a) => activityActions.filterActivitiesByTextFn(a, t)),
                };

                if (tagValue.talksStatistics.length > 0) {
                    return tagValue;
                } else {
                    return null;
                }
            })
            .filter((a) => a),
    };
};

export const filterEventStaticsForEventReport = (
    view: EventAnalyticsAggregate | null | undefined,
    filtersData: FilterPanelData,
): EventAnalyticsAggregate | null | undefined => {
    if (!view) {
        return view;
    }

    let filteredView: EventAnalyticsAggregate = { ...view };

    const filterByTypes = filtersData.filters.find(COMMON_FILTER_TYPE_KEY_FILTER)?.data;
    if (filterByTypes?.length > 0) {
        filteredView = {
            ...filteredView,
            talksAnalyticsAggregates: filteredView.talksAnalyticsAggregates?.filter((a) =>
                filterByTypes.includes(a.activity?.typeValue),
            ),
            tagsAnalyticsAggregates: filteredView.tagsAnalyticsAggregates
                ?.map((a) => {
                    const tagValue: TagAnalyticsAggregate = {
                        ...a,
                        talksStatistics: a.talksStatistics.filter((a) => filterByTypes.includes(a.activity?.typeValue)),
                    };

                    if (tagValue.talksStatistics.length > 0) {
                        return tagValue;
                    } else {
                        return null;
                    }
                })
                .filter((a) => a),
        };
    }

    const filterByScopes = filtersData.filters.find(COMMON_FILTER_SCOPE_KEY_FILTER)?.data;
    if (filterByScopes?.length > 0) {
        filteredView = {
            ...filteredView,
            talksAnalyticsAggregates: filteredView.talksAnalyticsAggregates.filter((a) =>
                filterByScopes.includes(a.activity?.scope),
            ),
            tagsAnalyticsAggregates: filteredView.tagsAnalyticsAggregates
                ?.map((a) => {
                    const tagValue: TagAnalyticsAggregate = {
                        ...a,
                        talksStatistics: a.talksStatistics.filter((a) => filterByScopes.includes(a.activity?.scope)),
                    };

                    if (tagValue.talksStatistics.length > 0) {
                        return tagValue;
                    } else {
                        return null;
                    }
                })
                .filter((a) => a),
        };
    }

    const filterByFormat = filtersData.filters.find(EVENT_REPORT_FILTER_FORMAT_KEY_FILTER)
        ?.data as EVENT_REPORT_FILTER_FORMAT_KEY_TYPE[];
    if (filterByFormat?.length === 1) {
        filteredView = {
            ...filteredView,
            talksAnalyticsAggregates: filteredView.talksAnalyticsAggregates.filter((a) =>
                filterByFormat.some((f) => f === "online")
                    ? !a.talksStatistic?.offline_unique_users
                    : a.talksStatistic?.offline_unique_users,
            ),
            tagsAnalyticsAggregates: filteredView.tagsAnalyticsAggregates
                ?.map((a) => {
                    const tagValue: TagAnalyticsAggregate = {
                        ...a,
                        talksStatistics: a.talksStatistics.filter((a) =>
                            filterByFormat.some((f) => f === "online")
                                ? !a.talksStatistic?.offline_unique_users
                                : a.talksStatistic?.offline_unique_users,
                        ),
                    };

                    if (tagValue.talksStatistics.length > 0) {
                        return tagValue;
                    } else {
                        return null;
                    }
                })
                .filter((a) => a),
        };
    }

    const filterByText = filtersData.filters.find(COMMON_FILTER_TEXT_KEY_FILTER)?.data;
    return filterActivitiesByText(filteredView, filterByText);
};
