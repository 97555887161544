// noinspection DuplicatedCode
import { AxiosInstance, AxiosResponse } from "axios";
import { reaction, when } from "mobx";

import { getBaseUrl } from "../../../environment";
import { initAxios } from "../../utils/api-helpers";
import { AuthStore } from "../auth/auth.store";
import {
    committeeEventApiPath,
    committeeEventArchiveApiPath,
    committeeEventsApiPath,
    eventsApiPath,
} from "./core.api.routes";
import {
    CoreApiEventEntity,
    CoreApiEventEntityApiResponse,
    CoreApiEventsEntityApiResponse,
} from "./models/events.models";

export class CoreApiService {
    private api: AxiosInstance;

    constructor(private serverSide: boolean = false, private authStore?: AuthStore, private token?: string) {
        const baseURL = getBaseUrl(serverSide);
        this.api = initAxios(baseURL, authStore, token);

        if (authStore) {
            reaction(
                () => authStore?.accessToken,
                () => {
                    this.api = initAxios(baseURL, authStore);
                },
            );

            when(
                () => authStore?.isLogged,
                () => {
                    this.api = initAxios(baseURL, authStore);
                },
            );

            when(
                () => !authStore?.isLogged,
                () => {
                    this.api = initAxios(baseURL, authStore);
                },
            );
        }
    }

    // GET /api/v1/events
    getEvents(): Promise<CoreApiEventEntity[]> {
        return this.api
            .get(eventsApiPath)
            .then(
                (result: AxiosResponse<CoreApiEventsEntityApiResponse>) =>
                    result.data?.data ?? ([] as CoreApiEventEntity[]),
            );
    }

    // GET /api/v1/committee/events
    getCommitteeEvents(): Promise<CoreApiEventEntity[]> {
        return this.api
            .get(committeeEventsApiPath)
            .then(
                (result: AxiosResponse<CoreApiEventsEntityApiResponse>) =>
                    result.data?.data ?? ([] as CoreApiEventEntity[]),
            );
    }

    // GET /api/v1/committee/events/100200
    getCommitteeEvent(eventId: number) {
        return this.api
            .get(committeeEventApiPath(eventId))
            .then((result: AxiosResponse<CoreApiEventEntityApiResponse>) => result.data?.data as CoreApiEventEntity);
    }

    // POST /api/v1/committee/events/{eventId}/archive
    archiveCommitteeEvent(eventId: number) {
        return this.api
            .post(committeeEventArchiveApiPath(eventId))
            .then((result: AxiosResponse<CoreApiEventEntityApiResponse>) => result.data?.data as CoreApiEventEntity);
    }
}
