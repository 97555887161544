export interface AppState {
    themeBrightness: "dark" | "light";
    lang: "ru" | "en";
    disableTips: boolean;
}

export const defaultAppState: AppState = {
    themeBrightness: "dark",
    lang: "ru",
    disableTips: false,
};

export const themeName = {
    dark: "bootstrap4-dark-blue",
    light: "bootstrap4-light-blue",
};

export const CFP_APPSTATE_KEY = "jugru.org.cfp.appstate";
