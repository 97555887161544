import React, { FC } from "react";

import cn from "classnames";

import styles from "./TopBar.module.scss";

import Tippy from "@tippyjs/react";

import { useI18nLocaleFormat } from "../../../../hooks/useI18nLocaleFormat";


interface HelpBarProps {
    disableTips: boolean;
}

const HelpBar: FC<HelpBarProps> = ({ disableTips }) => {
    const { localeAs } = useI18nLocaleFormat();

    return (
        <li>
            <Tippy content={localeAs("general.call-support")} disabled={disableTips}>
                <a href="https://t.me/JUGConfSupport_bot" target="_blank" rel="noreferrer">
                    <button className={cn("p-link", styles.topBar__button)}>
                        <i className="pi pi-question" />
                    </button>
                </a>
            </Tippy>
        </li>
    );
};

export default HelpBar;
