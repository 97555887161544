import { AuthStore } from "../auth/auth.store";
import { RatingsApiService } from "./ratings.api.service";
import { ActivityRatingModel } from "./ratings.models";

export class RatingsService {
    private readonly ratingsApiService: RatingsApiService;

    constructor(authStore: AuthStore) {
        this.ratingsApiService = new RatingsApiService(authStore);
    }

    async loadEventRatings(eventId: number): Promise<ActivityRatingModel[] | null | undefined> {
        return this.ratingsApiService.getEventRatings(eventId);
    }

    approveRatings = (ids: string[]) => this.ratingsApiService.putApproveMany({ ids });

    refuseRatings = (ids: string[]) => this.ratingsApiService.putRefuseMany({ ids });
}
