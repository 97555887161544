// noinspection DuplicatedCode
import { AxiosInstance, AxiosResponse } from "axios";
import { reaction, when } from "mobx";



import { getBaseUrl } from "../../../environment";
import { initAxios } from "../../utils/api-helpers";
import { AuthStore } from "../auth/auth.store";
import { eventRatingsApiPath, ratingsApproveManyApiPath, ratingsRefuseManyApiPath } from "./ratings.api.routes";
import { ActivityRatingModel, ActivityRatingsModelApiResponse } from "./ratings.models";


export class RatingsApiService {
    private api: AxiosInstance;

    constructor(private authStore?: AuthStore, private token?: string) {
        const baseURL = getBaseUrl(false, false, "api/v2/ratings");

        this.api = initAxios(baseURL, authStore, token);

        if (authStore) {
            reaction(
                () => authStore?.accessToken,
                () => {
                    this.api = initAxios(baseURL, authStore);
                },
            );

            when(
                () => authStore?.isLogged,
                () => {
                    this.api = initAxios(baseURL, authStore);
                },
            );

            when(
                () => !authStore?.isLogged,
                () => {
                    this.api = initAxios(baseURL, authStore);
                },
            );
        }
    }

    getEventRatings = (eventId: number): Promise<ActivityRatingModel[] | undefined> =>
        this.api
            .get(eventRatingsApiPath(eventId))
            .then(
                (result: AxiosResponse<ActivityRatingsModelApiResponse>) =>
                    result.data.ratings as ActivityRatingModel[],
            );

    putApproveMany = (body: { ids: string[] }) => this.api.put(ratingsApproveManyApiPath, body);

    putRefuseMany = (body: { ids: string[] }) => this.api.put(ratingsRefuseManyApiPath, body);
}
