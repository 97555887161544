import { makeAutoObservable } from "mobx";

import { rightsActions } from "../common/rights.actions";
import { activityActions } from "./models/activity/activity.actions";
import {
    ActivityEntity,
    ActivityProposalAggregate,
    ActivityProposalParticipationsAggregate,
} from "./models/activity/activity.models";
import { CommitteeEntity, CommitteeParticipationEntity } from "./models/committee/committee.models";
import { GiftEntity } from "./models/gift/gift.model";
import { LocationEntity } from "./models/location/location.model";
import {
    BiographyEntity,
    ParticipationConsent,
    ParticipationEntity,
} from "./models/participation/participation.models";
import { proposalActions } from "./models/proposal/proposal.actions";
import { ProposalEntity } from "./models/proposal/proposal.models";
import { scheduleActions } from "./models/schedule/schedule.actions";
import {
    ScheduleEntity,
    ScheduleModeratorEntity,
    SchedulePlanningSectionEntity,
    ScheduleSlotEntity,
    ScheduleTrackEntity,
    ScheduleVersionEntity,
} from "./models/schedule/schedule.models";
import { TravelEntity } from "./models/travel/travel.model";
import { setScheduleTimeZone } from '../../components/pages/events/schedule/ScheduleEventEditor/utils';

class SrmStore {
    // undefined = is not loaded, null - is null
    proposals?: ProposalEntity[] | undefined | null = undefined;
    currentProposal?: ProposalEntity | undefined | null = undefined;
    currentParticipations: ParticipationEntity[] = [];
    currentParticipationsLocations: Map<number, LocationEntity | null> = new Map();
    currentParticipationsTravels: Map<number, TravelEntity | null> = new Map();
    // currentParticipationsEquipments: Map<number, EquipmentEntity | null> = new Map();
    currentParticipationsGifts: Map<number, GiftEntity | null> = new Map();

    // committee
    committee?: CommitteeEntity | undefined | null = undefined;
    committeeParticipations?: CommitteeParticipationEntity[] | undefined | null = undefined;

    // activities
    activities?: ActivityProposalParticipationsAggregate[] | undefined | null = undefined;

    // schedules
    scheduleVersions: ScheduleVersionEntity[] | undefined | null = undefined;
    currentScheduleVersion: ScheduleEntity | undefined | null = undefined;
    schedulePlanningSections: SchedulePlanningSectionEntity[] | undefined | null = undefined;
    scheduleModerators: ScheduleModeratorEntity[] | undefined | null = undefined;

    constructor() {
        makeAutoObservable(this);
    }

    get proposalsNotLoaded(): boolean {
        return this.proposals === undefined;
    }

    reset() {
        this.setProposals(undefined);
        this.setCurrentProposal(undefined);
        this.setCurrentParticipations([]);

        this.currentParticipationsGifts = new Map();
        this.currentParticipationsLocations = new Map();
        this.currentParticipationsTravels = new Map();
        // this.currentParticipationsEquipments = new Map();
    }

    setProposals(response: ProposalEntity[]) {
        this.proposals = response.filter(rightsActions.canRead);
    }

    setCurrentProposal(proposal: ProposalEntity | null) {
        if (rightsActions.canRead(proposal)) {
            this.currentProposal = proposal;
        } else {
            this.currentProposal = null;
        }
    }

    setCurrentActivity(activity: ActivityEntity) {
        if (this.currentProposal) {
            if (rightsActions.canRead(activity)) {
                this.currentProposal.activity = activity;
            } else {
                this.currentProposal.activity = undefined;
            }
        }
    }

    setCurrentParticipations(participations: ParticipationEntity[]) {
        this.currentParticipations = participations.filter(rightsActions.canRead);
    }

    updateCurrentParticipations(participation: ParticipationEntity) {
        const otherParticipations = this.currentParticipations.filter((p) => p.id !== participation.id);
        this.currentParticipations = [...otherParticipations, participation];
    }

    deleteParticipation(participationId: number) {
        this.currentParticipations = this.currentParticipations.filter((p) => p.id !== participationId);
    }

    updateCurrentParticipationBiography(biography: BiographyEntity) {
        const editParticipation = this.currentParticipations.find(
            (p) => p.biography?.participantId === biography.participantId,
        );

        if (editParticipation) {
            const otherParticipations = this.currentParticipations.filter(
                (p) => p.biography?.participantId !== biography.participantId,
            );

            this.currentParticipations = [...otherParticipations, { ...editParticipation, biography }];
        }
    }

    updateCurrentParticipationConsent(consent: ParticipationConsent) {
        const editParticipation = this.currentParticipations.find((p) => p.id === consent.participationId);

        if (editParticipation) {
            const otherParticipations = this.currentParticipations.filter((p) => p.id !== consent.participationId);

            this.currentParticipations = [
                ...otherParticipations,
                {
                    ...editParticipation,
                    consent,
                },
            ];
        }
    }

    setCurrentTravel(participantId: number, travel?: TravelEntity) {
        this.currentParticipationsTravels.set(participantId, travel);
    }

    setCurrentLocation(participantId: number, location?: LocationEntity) {
        this.currentParticipationsLocations.set(participantId, location);
    }

    setCurrentGift(participantId: number, gift?: GiftEntity) {
        this.currentParticipationsGifts.set(participantId, gift);
    }

    // setCurrentEquipment(participantId: number, equipment?: EquipmentEntity) {
    //     this.currentParticipationsEquipments.set(participantId, equipment);
    // }

    get notClosedProposals(): ProposalEntity[] | undefined {
        return this.proposals?.filter(proposalActions.isNonClosedProposal);
    }

    get closedProposals(): ProposalEntity[] | undefined {
        return this.proposals?.filter(proposalActions.isClosedProposal);
    }

    setScheduleVersions(scheduleVersions: ScheduleVersionEntity[] | undefined | null) {
        this.scheduleVersions = scheduleVersions;
    }

    deleteScheduleVersions(scheduleId: number) {
        if (this.scheduleVersions) {
            this.scheduleVersions = this.scheduleVersions.filter((v) => v.id !== scheduleId);
        }
    }

    setScheduleVersion(scheduleVersion: ScheduleEntity) {
        if (scheduleVersion == null) {
            this.currentScheduleVersion = null;
        } else {
            this.currentScheduleVersion = {
                ...scheduleVersion,
                changeCounter: (this.currentScheduleVersion?.changeCounter ?? 0) + 1,
            };

            if (scheduleVersion?.timezone) {
                setScheduleTimeZone(scheduleVersion.timezone);
            }
        }
    }

    setScheduleVersionToDraft() {
        if (this.currentScheduleVersion) {
            this.setScheduleVersion({
                ...this.currentScheduleVersion,
                status: "draft",
            });
        }
    }

    setActivities(activities?: ActivityProposalParticipationsAggregate[]) {
        this.activities = activities?.sort(activityActions.sortActivitiesAggregatesByProposal);
    }

    setActivity(activity: ActivityEntity) {
        if (!this.activities) {
            return;
        }

        const oldEntity = this.activities.find((a) => a.activity?.id === activity.id);

        if (oldEntity) {
            const newEntity = {
                ...oldEntity,
                activity,
            };

            this.activities = [...this.activities.filter((a) => a.activity?.id !== activity.id), newEntity].sort(
                activityActions.sortActivitiesAggregatesByProposal,
            );
        }
    }

    setScheduleTrack(track: ScheduleTrackEntity) {
        if (this.currentScheduleVersion?.days) {
            const day = this.currentScheduleVersion.days.find((d) => d.id === track.dayId);
            if (!day) {
                return;
            }

            day.tracks = [...(day.tracks ?? []).filter((t) => t.id !== track.id), track].sort(
                scheduleActions.sortTracksListByNumber,
            );

            const newCurrentScheduleVersion: ScheduleEntity = {
                ...this.currentScheduleVersion,
                days: [...this.currentScheduleVersion.days.filter((d) => d.id !== day.id), day].sort(
                    scheduleActions.sortDayListByNumber,
                ),
                status: "draft",
            };

            this.setScheduleVersion(newCurrentScheduleVersion);
        }
    }

    setScheduleSlot(newSlot: ScheduleSlotEntity) {
        if (this.currentScheduleVersion?.days) {
            // find slot
            const slots = this.currentScheduleVersion.days.flatMap((d) => d.tracks?.flatMap((t) => t.slots ?? []));
            const existSlot = slots.find((s) => s.id === newSlot.id);

            if (!existSlot) {
                return;
            }

            const day = this.currentScheduleVersion.days.find((d) => d.id === newSlot.dayId);
            const newTrack = day?.tracks?.find((t) => t.id === newSlot.trackId);
            const oldTrack = day?.tracks?.find((t) => t.id === existSlot.trackId);

            if (!newTrack || !oldTrack) {
                return;
            }

            if (existSlot.trackId === newSlot.trackId) {
                newTrack.slots = [...(newTrack.slots ?? []).filter((s) => s.id !== newSlot.id), newSlot].sort(
                    scheduleActions.sortSlotsListByDate,
                );
            } else {
                newTrack.slots = [...(newTrack.slots ?? []), newSlot].sort(scheduleActions.sortSlotsListByDate);

                oldTrack.slots = (oldTrack.slots ?? [])
                    .filter((s) => s.id !== existSlot.id)
                    .sort(scheduleActions.sortSlotsListByDate);
            }

            const getFinalTracks = () => {
                if (oldTrack.id !== newTrack.id) {
                    return [newTrack, oldTrack];
                } else {
                    return [newTrack];
                }
            };

            const newCurrentScheduleVersion: ScheduleEntity = {
                ...this.currentScheduleVersion,
                days: [
                    ...this.currentScheduleVersion.days.filter((d) => d.id !== day.id),
                    {
                        ...day,
                        tracks: [
                            ...(day.tracks ?? []).filter((t) => t.id !== newTrack.id && t.id !== oldTrack.id),
                            ...getFinalTracks(),
                        ],
                    },
                ].sort(scheduleActions.sortDayListByNumber),
                status: "draft",
            };

            this.setScheduleVersion(newCurrentScheduleVersion);
        }
    }

    addScheduleSlot(slot: ScheduleSlotEntity) {
        if (this.currentScheduleVersion?.days) {
            const day = this.currentScheduleVersion.days.find((d) => d.id === slot.dayId);
            const track = day?.tracks?.find((t) => t.id === slot.trackId);

            if (!track) {
                return;
            }

            track.slots = [...(track.slots ?? []), slot].sort(scheduleActions.sortSlotsListByDate);

            const newCurrentScheduleVersion: ScheduleEntity = {
                ...this.currentScheduleVersion,
                days: [...this.currentScheduleVersion.days.filter((d) => d.id !== day.id), day].sort(
                    scheduleActions.sortDayListByNumber,
                ),
                status: "draft",
            };

            this.setScheduleVersion(newCurrentScheduleVersion);
        }
    }

    deleteScheduleSlot(dayId: number, trackId: number, slotId: number) {
        if (this.currentScheduleVersion && this.currentScheduleVersion.days) {
            const day = this.currentScheduleVersion.days.find((d) => d.id === dayId);

            if (!day?.tracks) {
                return;
            }

            const track = day.tracks?.find((t) => t.id === trackId);

            if (!track) {
                return;
            }

            track.slots = [...(track.slots ?? []).filter((s) => s.id !== slotId)].sort(
                scheduleActions.sortSlotsListByDate,
            );

            const newCurrentScheduleVersion: ScheduleEntity = {
                ...this.currentScheduleVersion,
                days: [
                    ...this.currentScheduleVersion.days.filter((d) => d.id !== day.id),
                    {
                        ...day,
                        tracks: [...day.tracks.filter((t) => t.id !== track.id), track],
                    },
                ].sort(scheduleActions.sortDayListByNumber),
                status: "draft",
            };

            this.setScheduleVersion(newCurrentScheduleVersion);
        }
    }

    setScheduleModerators(moderators: ScheduleModeratorEntity[]) {
        this.scheduleModerators = moderators;
    }

    setScheduleModerator(moderator: ScheduleModeratorEntity) {
        this.scheduleModerators = [...(this.scheduleModerators ?? []).filter((s) => s.id !== moderator.id), moderator];
    }

    deleteScheduleModerator(id: number) {
        this.scheduleModerators = (this.scheduleModerators ?? []).filter((s) => s.id !== id);
    }

    setSchedulePlanningSections(sections: SchedulePlanningSectionEntity[]) {
        this.schedulePlanningSections = sections;
    }

    setSchedulePlanningSection(section: SchedulePlanningSectionEntity) {
        this.schedulePlanningSections = [
            ...(this.schedulePlanningSections ?? []).filter((s) => s.id !== section.id),
            section,
        ];
    }

    deleteSchedulePlanningSection(id: number) {
        this.schedulePlanningSections = (this.schedulePlanningSections ?? []).filter((s) => s.id !== id);
    }

    // Committee
    setCommittee(committee: CommitteeEntity) {
        this.committee = committee;
    }

    setCommitteeParticipations(participations: CommitteeParticipationEntity[]) {
        this.committeeParticipations = participations;
    }

    updateCommitteeParticipation(participation: CommitteeParticipationEntity) {
        this.committeeParticipations = [
            ...(this.committeeParticipations ?? []).filter((p) => p.id !== participation.id),
            participation,
        ];
    }

    deleteCommitteeParticipation(participationId: number) {
        this.committeeParticipations = [
            ...(this.committeeParticipations ?? []).filter((p) => p.id !== participationId),
        ];
    }

    updateCommitteeParticipationBiography(biography: BiographyEntity) {
        const editParticipation = this.committeeParticipations.find(
            (p) => p.biography?.participantId === biography.participantId,
        );

        if (editParticipation) {
            const otherParticipations = this.committeeParticipations.filter(
                (p) => p.biography?.participantId !== biography.participantId,
            );

            editParticipation.biography = biography;
            this.committeeParticipations = [...otherParticipations, editParticipation];
        }
    }

    updateActivityListParticipation(activityId: number, participation: ParticipationEntity) {
        if (!this.activities || this.activities?.length === 0) {
            return;
        }

        const targetAggregate = this.activities.find((agg) => agg.activity?.id === activityId);

        const newAggregate = {
            ...targetAggregate,
            participations: [...targetAggregate.participations.filter((p) => p.id !== participation.id), participation],
        };

        this.activities = [...this.activities.filter((agg) => agg.activity?.id !== activityId), newAggregate].sort(
            activityActions.sortActivitiesAggregatesByProposal,
        );
    }

    updateActivityListActivity(aggregate: ActivityProposalAggregate) {
        if (!this.activities || this.activities?.length === 0) {
            return false;
        }
        const targetAggregate = this.activities.find((agg) => agg.activity?.id === aggregate.activity?.id);
        const newAggregate: ActivityProposalParticipationsAggregate = {
            ...targetAggregate,
            activity: aggregate.activity,
        };
        this.activities = [
            ...this.activities.filter((agg) => agg.activity?.id !== targetAggregate.activity?.id),
            newAggregate,
        ].sort(activityActions.sortActivitiesAggregatesByProposal);

        return true;
    }
}

export default SrmStore;
