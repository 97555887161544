import { useRouter } from "next/router";

import en from "../../lang/en.json";
import ru from "../../lang/ru.json";
import { isInstanceOfLocalizeString } from "../data/common/common.actions";

export type LocaleMessagesType = keyof typeof ru;

export type Locale = "en" | "ru";

export interface I18nLocaleFormatData {
    localeAs: (id: LocaleMessagesType) => string;
    localeAsWithLang: (id: LocaleMessagesType, lang: Locale) => string;
    localeFrom: (object: { ru: string; en: string }) => string;
    localeFromArray: (object: { ru: string[]; en: string[] }) => string;
    localeFromWithLang: (object: { ru: string; en: string }, lang: Locale) => string;
    localeDate: (date: Date, options?: LocaleDateOptions) => string;
    isLocaleDataEmpty: (object: object, lang: Locale, excludeFields?: string[], checkFields?: string[]) => boolean;
}

export interface LocaleDateOptions {
    localeMatcher?: "best fit" | "lookup";
    weekday?: "long" | "short" | "narrow";
    era?: "long" | "short" | "narrow";
    year?: "numeric" | "2-digit";
    month?: "numeric" | "2-digit" | "long" | "short" | "narrow";
    day?: "numeric" | "2-digit";
    hour?: "numeric" | "2-digit";
    minute?: "numeric" | "2-digit";
    second?: "numeric" | "2-digit";
    timeZoneName?: "long" | "short";
    formatMatcher?: "best fit" | "basic";
    hour12?: boolean;
    timeZone?: string;
}

export const useI18nLocaleFormat = (): I18nLocaleFormatData => {
    const locale = (useRouter() ?? { locale: "ru" }).locale;

    const isLocaleDataEmpty = (object: object, lang: Locale, excludeFields?: string[], checkFields?: string[]) => {
        for (const [key, value] of Object.entries(object)) {
            if (key === "id") {
                continue;
            }
            if (checkFields && !checkFields.includes(key)) {
                continue;
            }
            if (excludeFields && excludeFields.includes(key)) {
                continue;
            }
            if (isInstanceOfLocalizeString(value)) {
                const v = localeFromWithLang(value, lang);
                if (!v && v?.replaceAll(" ", "") === "") {
                    return true;
                }
            }
        }

        return false;
    };

    const localeAs = (id: LocaleMessagesType): string => {
        const localesObject = getLocaleObjectByLocale(locale);

        if (!localesObject) {
            return id;
        }

        return localesObject[id] ?? id;
    };

    const localeAsWithLang = (id: LocaleMessagesType, lang: Locale): string => {
        const localesObject = getLocaleObjectByLocale(lang);

        if (!localesObject) {
            return id;
        }

        return localesObject[id] ?? id;
    };

    const localeFrom = (object: { ru: string; en: string }): string => {
        if (!object || !(object as any).hasOwnProperty(locale)) {
            return "";
        }

        return object[locale];
    };

    const localeFromArray = (object: { ru: string[]; en: string[] }): string => {
        if (!object || !(object as any).hasOwnProperty(locale)) {
            return "";
        }

        return object[locale][0] ?? "";
    };

    const localeFromWithLang = (object: { ru: string; en: string }, lang: Locale): string => {
        if (!object || !(object as any).hasOwnProperty(lang)) {
            return undefined;
        }

        return object[lang];
    };

    const localeDate = (date: Date, options?: LocaleDateOptions): string => {
        const correctDate = new Date(date);

        try {
            return new Intl.DateTimeFormat(locale, options).format(correctDate);
        } catch {
            return "";
        }
    };

    return {
        localeAs,
        localeAsWithLang,
        localeFrom,
        localeFromArray,
        localeFromWithLang,
        localeDate,
        isLocaleDataEmpty,
    };
};

const getLocaleObjectByLocale = (locale: string) => {
    switch (locale) {
        case "ru":
            return ru;
        case "en":
            return en;
        default:
            return;
    }
};
