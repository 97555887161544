import { LocaleMessagesType } from "../../../../hooks/useI18nLocaleFormat";
import { removeUndefined } from "../../../../utils/common";
import { EntityTypeValue, PossibleValueForSelect } from "../../../common/common.models";
import { ActivityScope, ActivityTypeValue, TechnicalTag } from "./activity.models";

export const ActivityEntityTypeValuePrefix = "domain.proposal.activity.typeValue";
export const ActivityEntityScopeValuePrefix = "domain.proposal.activity.scope";

export const ActivityEntityLocalizedIds = {
    content: {
        status: "domain.proposal.activity.content.status",
        data: {
            shortDescription: {
                title: "domain.proposal.activity.content.data.shortDescription",
                note: "domain.proposal.activity.content.data.shortDescription.hint",
                editNote: "domain.proposal.activity.content.data.shortDescription.edit.hint",
            },
            fullDescription: {
                title: "domain.proposal.activity.content.data.fullDescription",
                note: "domain.proposal.activity.content.data.fullDescription.hint",
                editNote: "domain.proposal.activity.content.data.fullDescription.edit.hint",
            },
            presentation: "domain.proposal.activity.content.data.presentation",
            complexity: "domain.proposal.activity.content.data.complexity",
            language: "domain.proposal.activity.content.data.language",
            tags: "domain.proposal.activity.content.data.tags",
            technicalTags: "domain.proposal.activity.content.data.technicalTags",
            topics: "domain.proposal.activity.content.data.topics",
            platforms: "domain.proposal.activity.content.data.platforms",
            links: {
                discussion: "domain.proposal.activity.content.data.links.discussion",
            },
            internalSlots: {
                broadcast: "domain.proposal.activity.content.data.internalSlots.broadcast",
                discussion: "domain.proposal.activity.content.data.internalSlots.discussion",
                discussionExternalLink: "domain.proposal.activity.content.data.internalSlots.discussionExternalLink",
            },
        },
    },
    plan: {
        title: "domain.proposal.activity.plan",
        note: "domain.proposal.activity.plan.hint",
        editNote: "domain.proposal.activity.plan.hint",
    },
    title: "domain.proposal.activity.title",
    typeValue: {
        title: ActivityEntityTypeValuePrefix,
        note: "ActivityTypeContentEditBlock.infoMessage",
        editNote: "ActivityTypeContentEditBlock.infoMessage",
    },
    broadcastOptions: {
        type: "domain.proposal.activity.broadcastOptions.type",
        importance: "domain.proposal.activity.broadcastOptions.importance",
        notes: "domain.proposal.activity.broadcastOptions.notes",
    },
};

export const ActivityEntityBroadcastTypeValues: EntityTypeValue<string>[] = [
    {
        key: "live",
        displayId: "domain.proposal.activity.broadcastOptions.type.LIVE",
    },
    {
        key: "record",
        displayId: "domain.proposal.activity.broadcastOptions.type.RECORD",
    },
];

export const DisabledActivityEntityTypeValue: ActivityTypeValue[] = ["ROUND_TABLE"];

export const ActivityEntityTypeValueValuesSanitize = (includes: string[], scope: ActivityScope) =>
    ActivityEntityTypeValueValues(scope).filter(
        (v) => includes.includes(v.key) || !DisabledActivityEntityTypeValue.includes(v.key as ActivityTypeValue),
    );

export const ActivityEntityTypeValueValues = (scope?: ActivityScope): EntityTypeValue<string>[] => {
    return removeUndefined([
        {
            key: "TALK",
            displayId: `${ActivityEntityTypeValuePrefix}.TALK`,
        },
        scope === "MEETUP"
            ? undefined
            : {
                  key: "ROUND_TABLE",
                  displayId: `${ActivityEntityTypeValuePrefix}.ROUND_TABLE`,
                  disabled: true,
              },
        scope === "MEETUP"
            ? undefined
            : {
                  key: "WORKSHOP",
                  displayId: `${ActivityEntityTypeValuePrefix}.WORKSHOP`,
              },
        scope === "MEETUP"
            ? undefined
            : {
                  key: "BATTLE",
                  displayId: `${ActivityEntityTypeValuePrefix}.BATTLE`,
              },
        scope === "MEETUP"
            ? undefined
            : {
                  key: "BOF_SESSION",
                  displayId: `${ActivityEntityTypeValuePrefix}.BOF_SESSION`,
              },
        scope === "MEETUP"
            ? undefined
            : {
                  key: "MASTERCLASS",
                  displayId: `${ActivityEntityTypeValuePrefix}.MASTERCLASS`,
              },
        {
            key: "CONVERSATION",
            displayId: `${ActivityEntityTypeValuePrefix}.CONVERSATION`,
        },
        {
            key: "INTERVIEW",
            displayId: `${ActivityEntityTypeValuePrefix}.INTERVIEW`,
        },
        scope === "MEETUP"
            ? undefined
            : {
                  key: "ANALYSIS",
                  displayId: `${ActivityEntityTypeValuePrefix}.ANALYSIS`,
              },
        scope === "MEETUP"
            ? undefined
            : {
                  key: "GAME",
                  displayId: `${ActivityEntityTypeValuePrefix}.GAME`,
              },
        scope === "MEETUP"
            ? undefined
            : {
                  key: "CV_INTERVIEW",
                  displayId: `${ActivityEntityTypeValuePrefix}.CV_INTERVIEW`,
              },
        scope === "MEETUP"
            ? undefined
            : {
                  key: "UNDECIDED",
                  displayId: `${ActivityEntityTypeValuePrefix}.UNDECIDED`,
              },
        scope === "MEETUP"
            ? undefined
            : {
                  key: "CUSTOM",
                  displayId: `${ActivityEntityTypeValuePrefix}.CUSTOM`,
              },
        {
            key: "MEETUP",
            displayId: `${ActivityEntityTypeValuePrefix}.MEETUP`,
        },
        {
            key: "ASK_EXPERT",
            displayId: `${ActivityEntityTypeValuePrefix}.ASK_EXPERT`,
        },
    ]);
};

export const InternalSlotsBroadcastValuesForSelect = (
    localeAs: (id: LocaleMessagesType) => string,
): PossibleValueForSelect[] =>
    InternalSlotsBroadcastValues.map((value) => ({
        name: localeAs(value.displayId as any),
        code: value.key,
    }));

export const InternalSlotsBroadcastValues: EntityTypeValue<string>[] = [
    {
        key: "disabled",
        displayId: "domain.proposal.activity.content.data.internalSlots.broadcast.disabled",
    },
    {
        key: "standard",
        displayId: "domain.proposal.activity.content.data.internalSlots.broadcast.standard",
    },
];

export const InternalSlotsDiscussionValuesForSelect = (
    localeAs: (id: LocaleMessagesType) => string,
): PossibleValueForSelect[] =>
    InternalSlotsDiscussionValues.map((value) => ({
        name: localeAs(value.displayId as any),
        code: value.key,
    }));

export const InternalSlotsDiscussionValues: EntityTypeValue<string>[] = [
    {
        key: "disabled",
        displayId: "domain.proposal.activity.content.data.internalSlots.discussion.disabled",
    },
    {
        key: "speaker_room",
        displayId: "domain.proposal.activity.content.data.internalSlots.discussion.speaker_room",
    },
    {
        key: "external",
        displayId: "domain.proposal.activity.content.data.internalSlots.discussion.external",
    },
];

export const ComplexityValues: EntityTypeValue<number>[] = [
    {
        key: 1,
        displayId: "domain.proposal.activity.content.data.complexity.temp.fire",
    },
    {
        key: 2,
        displayId: "domain.proposal.activity.content.data.complexity.temp.introduction",
    },
    {
        key: 3,
        displayId: "domain.proposal.activity.content.data.complexity.temp.engineer",
    },
    {
        key: 4,
        displayId: "domain.proposal.activity.content.data.complexity.temp.hardcore",
    },
    {
        key: 5,
        displayId: "domain.proposal.activity.content.data.complexity.temp.academic",
    },
    {
        key: 6,
        displayId: "domain.proposal.activity.content.data.complexity.temp.partners",
    },
];

export const ComplexityPossibleCodes: number[] = [1, 2, 3, 4, 5];
export const ComplexityPossibleValuesForSelect = (
    localeAs: (id: LocaleMessagesType) => string,
): PossibleValueForSelect[] =>
    ComplexityPossibleCodes.map((code) => ({
        name: localeAs(ComplexityValues.find((v) => v.key === code).displayId as any),
        code: code.toString(),
    }));

export const ComplexityDisplayCodes: number[] = [1, 2, 3, 4, 5, 6];
export const ComplexityDisplayValuesForSelect = (
    localeAs: (id: LocaleMessagesType) => string,
): PossibleValueForSelect[] =>
    ComplexityDisplayCodes.map((code) => ({
        name: localeAs(ComplexityValues.find((v) => v.key === code).displayId as any),
        code: code.toString(),
    }));

export const LanguagePossibleValuesForSelect: PossibleValueForSelect[] = [
    { name: "RU", code: "RU" },
    { name: "EN", code: "EN" },
];

export const PlatformsPossibleCodes: string[] = ["android", "flutter", "kotlin", "cross", "ios"];

export const PlatformsPossibleValuesForSelect = (values?: string[]): PossibleValueForSelect[] =>
    (values ?? PlatformsPossibleCodes).map((name) => ({
        name,
        code: name,
    }));

export const TechnicalTagsPossibleCodes: TechnicalTag[] = ["no_recording", "online_only", "offline_only"];

export const TechnicalTagsPossibleValuesForSelect = (values?: string[]): PossibleValueForSelect[] =>
    (values ?? TechnicalTagsPossibleCodes).map((name) => ({
        name,
        code: name,
    }));
