import React, { FC } from "react";

import cn from "classnames";

import styles from "./Footer.module.scss";

import { release } from "../../../../../environment";
import { useI18nLocaleFormat } from "../../../../hooks/useI18nLocaleFormat";
import { CommonComponent } from "../../Components/common";

interface FooterProps extends CommonComponent {}

const Footer: FC<FooterProps> = ({ className }) => {
    const { localeAs } = useI18nLocaleFormat();

    return (
        <footer className={cn(className, styles.footer)}>
            <ul className={cn(styles.footer_list)} aria-label={localeAs("footer.label")}>
                <div className={cn(styles.footer_item_abs)}>{release}</div>
                <li className={cn(styles.footer_item)}>
                    <a
                        className={cn(styles.footer_link, styles.footer_link_jug)}
                        href="https://jugru.org/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        {/* eslint-disable-next-line @next/next/no-img-element */}
                        <img
                            className={cn(styles.footer_image)}
                            src="/img/jugru_group.svg"
                            width="170"
                            alt={localeAs("footer.website")}
                        />
                    </a>
                </li>

                <li className={cn(styles.footer_item)}>
                    <a
                        className={cn(styles.footer_link, styles.footer_link_vk)}
                        href="https://vk.com/jugrugroup"
                        target="_blank"
                        rel="noreferrer noopener"
                        aria-label={localeAs("footer.vk")}
                    >
                        <svg
                            className={cn(styles.footer_icon)}
                            width="32"
                            height="32"
                            aria-hidden="true"
                            focusable="false"
                        >
                            <use href={`/img/home/social.svg#vk`} />
                        </svg>
                    </a>
                </li>

                {/*<li className={cn(styles.footer_item)}>*/}
                {/*    <a*/}
                {/*        className={cn(styles.footer_link, styles.footer_link_facebook)}*/}
                {/*        href="https://www.facebook.com/jugrugroup"*/}
                {/*        target="_blank"*/}
                {/*        rel="noreferrer noopener"*/}
                {/*        aria-label={localeAs("footer.facebook")}*/}
                {/*    >*/}
                {/*        <svg*/}
                {/*            className={cn(styles.footer_icon)}*/}
                {/*            width="32"*/}
                {/*            height="32"*/}
                {/*            aria-hidden="true"*/}
                {/*            focusable="false"*/}
                {/*        >*/}
                {/*            <use href={`/img/home/social.svg#facebook`}/>*/}
                {/*        </svg>*/}
                {/*    </a>*/}
                {/*</li>*/}

                <li className={cn(styles.footer_item)}>
                    <a
                        className={cn(styles.footer_link, styles.footer_link_twitter)}
                        href="https://twitter.com/jugrugroup"
                        target="_blank"
                        rel="noreferrer noopener"
                        aria-label={localeAs("footer.twitter")}
                    >
                        <svg
                            className={cn(styles.footer_icon)}
                            width="32"
                            height="32"
                            aria-hidden="true"
                            focusable="false"
                        >
                            <use href={`/img/home/social.svg#twitter`} />
                        </svg>
                    </a>
                </li>

                <li className={cn(styles.footer_item)}>
                    <a
                        className={cn(styles.footer_link, styles.footer_link_telegram)}
                        href="https://t.me/jugrugroup"
                        target="_blank"
                        rel="noreferrer noopener"
                        aria-label={localeAs("footer.telegram")}
                    >
                        <svg
                            className={cn(styles.footer_icon)}
                            width="32"
                            height="32"
                            aria-hidden="true"
                            focusable="false"
                        >
                            <use href={`/img/home/social.svg#telegram`} />
                        </svg>
                    </a>
                </li>

                <li className={cn(styles.footer_item)}>
                    <a
                        className={cn(styles.footer_link, styles.footer_link_habr)}
                        href="https://habr.com/ru/company/jugru/blog/"
                        target="_blank"
                        rel="noreferrer noopener"
                        aria-label={localeAs("footer.habr")}
                    >
                        <svg
                            className={cn(styles.footer_icon)}
                            width="72"
                            height="24"
                            aria-hidden="true"
                            focusable="false"
                        >
                            <use href={`/img/home/social.svg#habr`} />
                        </svg>
                    </a>
                </li>
            </ul>
        </footer>
    );
};

export default Footer;
