import { Entity } from "./common.models";
import { ActionModel, ActionResource, ActionType } from "./rights.models";

const hasActions = (o?: object): string[] => {
    if (!o) {
        return [];
    }
    if ("actions" in o) {
        // @ts-ignore
        return o.actions as string[];
    }
    return [];
};

export const extractActionsFromAPI = (o?: any): ActionModel[] => {
    return hasActions(o).map((a) => {
        const parsed = (a as string).split(":");

        if (!parsed[0] || !parsed[1]) {
            return null;
        }

        const baseAction: ActionModel = {
            resource: parsed[0],
            action: parsed[1] as ActionType,
        };

        if (parsed[2]) {
            return {
                ...baseAction,
                nestedResource: parsed[2],
            };
        }
        return baseAction;
    });
};

const can = (o: Entity<any>, action: ActionType) => {
    if (!o) {
        return false;
    }
    return extractActionsFromAPI(o).find((a: ActionModel) => a.action === action && !a.nestedResource) !== undefined;
};

const canNestedResource = (o: Entity<any>, nestedResource: ActionResource, action: ActionType) => {
    if (!o) {
        return false;
    }
    return (
        extractActionsFromAPI(o).find(
            (a: ActionModel) => a.action === action && a.nestedResource === nestedResource,
        ) !== undefined
    );
};

export const rightsActions = {
    can,
    canRead: (o: Entity<any>) => can(o, "read"),
    canEdit: (o: Entity<any>) => can(o, "edit"),
    canDelete: (o: Entity<any>) => can(o, "delete"),
    canNestedResource,
    canCreateNestedResource: (o: Entity<any>, nestedResource: ActionResource) =>
        canNestedResource(o, nestedResource, "create"),
    canReadNestedResource: (o: object, nestedResource: ActionResource) => canNestedResource(o, nestedResource, "read"),
    canEditNestedResource: (o: object, nestedResource: ActionResource) => canNestedResource(o, nestedResource, "edit"),
    canDeleteNestedResource: (o: object, nestedResource: ActionResource) =>
        canNestedResource(o, nestedResource, "delete"),
};
