import { getCfpUrl, getEnvironment, getLKUrl } from "../../environment";
import { Locale } from "../hooks/useI18nLocaleFormat";

export const SCHEDULE_WITH_ID_ROUTE = `/schedule/[id]`;
export const PUBLICATIONS_WITH_ID_ROUTE = `/publications/[id]`;
export const ACCEPT_PUBLICATION_CONSENT_QUERY = "acceptPublication";
export const INVITE_TO_CHANNEL_QUERY = "inviteToChannel";

const events = {
    route: "/events",
    routeToEvent: (eventId: any) => `/events/${eventId}`,
};

const APPLICATION_ROUTES = {
    main: {
        route: "/",
        createRoute: (path?: string) => `${path === undefined ? "" : path}`,
        params: {},
        getHomeLink: (locale: Locale) => {
            return {
                local: getLocaleUrl("http://localhost:3000/", locale, true),
                development: getLocaleUrl("https://speakers-dev.jugru.org/", locale, true),
                testing: getLocaleUrl("https://speakers-test.jugru.org/", locale, true),
                production: getLocaleUrl("https://speakers.jugru.org/", locale, true),
            }[getEnvironment()];
        },
        forbidden: "/403",
    },
    proposals: {
        route: "/proposals",
    },
    proposal: {
        route: "/proposal",
        proposalRouteMain: (id?: number) =>
            `/proposal/${id ?? 0}#${ProposalHashesRoutes.ACTIVITY.tabName.toLowerCase()}`,
        proposalRouteTabName: (id: number, tabName: TabHashesRoute) =>
            `/proposal/${id}#${tabName.tabName.toLowerCase()}`,
        proposalRouteTabNameWithQuery: (id: number, tabName: TabHashesRoute, query: string) =>
            `/proposal/${id}?${query}#${tabName.tabName.toLowerCase()}`,
        proposalRouteTabIndex: (id: number, tabIndex: number) => {
            const value = Object.values(ProposalHashesRoutes).find((v) => v.tabIndex === tabIndex);
            return `/proposal/${id}#${(value?.tabName ?? "").toLowerCase()}`;
        },
        params: {},
    },
    lkProfile: {
        route: `${getLKUrl()}user/profile/base`,
        edit: `${getLKUrl()}user/profile/editing/base`,
    },
    events,
    schedule: {
        routeToPage: (eventId: number) => `${events.routeToEvent(eventId)}/schedule`,
    },
    schedule_live: {
        routeToPage: (eventId: number) => `${events.routeToEvent(eventId)}/schedule-live`,
    },
    schedule_support: {
        routeToPage: (eventId: number) => `${events.routeToEvent(eventId)}/schedule-support`,
    },
    team: {
        routeToPage: (eventId: number) => `${events.routeToEvent(eventId)}/team`,
    },
    publications: {
        routeToPage: (eventId: number) => `${events.routeToEvent(eventId)}/publications`,
    },
    activities: {
        routeToPage: (eventId: number) => `${events.routeToEvent(eventId)}/activities`,
    },
    eventReport: {
        routeToPage: (eventId: number) => `${events.routeToEvent(eventId)}/report`,
    },
    ratings: {
        routeToPage: (eventId: number) => `${events.routeToEvent(eventId)}/ratings`,
    },
};

export const getLocaleUrl = (url: string, locale: Locale, postfix = false) => {
    if (locale === "ru") {
        return url;
    }
    if (postfix) {
        return `${url}/${locale}`;
    } else {
        return `/${locale}${url}`;
    }
};

export const EXTERNAL_ROUTES = {
    cfp: {
        route: (locale: Locale) => getCfpUrl(locale),
        // createRoute: (event: CoreApiEventEntity) => `${getCfpUrl()}${event.project}-${event.version}`
    },
};

export type TabHashesRoute = {
    tabName: string;
    tabIndex: number;
};

export type ProposalHashesRoutesType = {
    ACTIVITY: TabHashesRoute;
    OTHER: TabHashesRoute;
    SPEAKERS: TabHashesRoute;
    MEMO: TabHashesRoute;
    ANALYTICS: TabHashesRoute;
    CONTROL: TabHashesRoute;
    // CONTACTS: TabHashesRoute,
};

export const ProposalHashesRoutes: ProposalHashesRoutesType = {
    ACTIVITY: { tabName: "activity", tabIndex: 0 },
    OTHER: { tabName: "other", tabIndex: 1 },
    SPEAKERS: { tabName: "speakers", tabIndex: 2 },
    MEMO: { tabName: "memo", tabIndex: 3 },
    ANALYTICS: { tabName: "report", tabIndex: 4 },
    CONTROL: { tabName: "control", tabIndex: 5 },
    // CONTACTS: { tabName: "contacts", tabIndex: 5 },
};

export default APPLICATION_ROUTES;
