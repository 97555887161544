import { Dispatch, SetStateAction, useState } from "react";

import { getStateFromLocalStorage, setStateToLocalStorage } from "../utils/localStorage";

const useLocalStorageDispatch =
    <T extends object>(dispatch: Dispatch<SetStateAction<T>>, key: string) =>
    (value: T) => {
        setStateToLocalStorage<T>(value, key);
        dispatch(value);
    };

const useLocalStorageState = <T extends object>(
    key: string,
    initData?: T | undefined,
): [T | undefined, Dispatch<SetStateAction<T>>] => {
    const readDataFromStorage = (): T => {
        const data = getStateFromLocalStorage<T>(key);

        // @ts-ignore
        if (!data || (initData?.version && data.version !== initData.version)) {
            return initData ?? undefined;
        } else {
            return data;
        }
    };

    const [state, setState] = useState<T | undefined>(readDataFromStorage());

    const dispatcher = useLocalStorageDispatch(setState, key);

    return [state, dispatcher];
};

export { useLocalStorageState };
